export default function Statistics(props) {
    return (
        <div className="row row--grid">
            <div class="col-md-12">
            <strong>Showing stats in {props.currency}</strong>
            </div>
            <div className="col-12 col-md-6 col-xl-6">
                <div className="card text-center d-block align-items-center">
                <h3 className="card__title">Total transactions</h3>
                <div className="text-center">
                    <h2 className="text-center">{props.NumberOfPurchase} times</h2>
                </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-xl-6">
                <div className="card text-center d-block align-items-center">
                <h3 className="card__title">Total Likes</h3>
                <div className="text-center">
                    <h2 className="text-center">{props.Numberoflikes} Likes</h2>
                </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <div className="card text-center d-block align-items-center">
                <h3 className="card__title">Royalties Income</h3>
                <div className="text-center">
                    <h2 className="text-center">{props.RoyaltiesIncome ? props.convertETH(props.RoyaltiesIncome,'wei','ether') : "0"} {props.currency}</h2>
                    <small>$ {props.convertUSD((props.RoyaltiesIncome ? props.convertETH(props.RoyaltiesIncome,'wei','ether') : 0),props.currency)}</small>
                </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <div className="card text-center d-block align-items-center">
                <h3 className="card__title">Biggest Purchase</h3>
                <div className="text-center">
                    <h2 className="text-center">{props.BiggestPurchase} {props.currency}</h2>
                    <small>$ {props.convertUSD(props.BiggestPurchase,props.currency)}</small>
                </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <div className="card text-center d-block align-items-center">
                <h3 className="card__title">Total Purchase</h3>
                <div className="text-center">
                    <h2 className="text-center">{props.TotalPurchase} {props.currency}</h2>
                    <small>$ {props.convertUSD(props.TotalPurchase,props.currency)}</small>
                </div>
                </div>
            </div>  
            
        </div>
    );
}