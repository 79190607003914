import React from 'react'; 
import FontAwesome from 'react-fontawesome'

export default function MintForm(props) {
    return (
        <div className="row row--grid">
            {/* author */}
            {props.myaccount && props.myaccount.name ? (
                <div className="col-12 col-xl-3">
                    <div className="author author--page">
                        
                        <div className="author__meta">


                            <a href="/profile" className="author__avatar author__avatar--verified">
                                <img src={props.myaccount.picture} alt="" width="160" height="160" />
                            </a>
                            <h1 className="author__name"><a href="/profile">{props.myaccount.name}</a></h1>
                            <h2 className="author__nickname"><a href="/profile">@{props.myaccount.name.replace(/\s+/g, '').toLowerCase()}</a></h2>
                            <p className="author__text">{props.myaccount.description}</p>
                            
                        </div>
                        
                    </div>
                </div>
            ) : null}
            
            {/* end author */}
            
            <div className={"col-12 " + (props.myaccount && props.myaccount.name ? "col-xl-9" : "col-xl-12 text-center")}>
                {/* title */}
                <div className={"main__title main__title--create" + (props.myaccount && props.myaccount.name ? "" : " text-center" )}>
                    {/*props.myaccount && props.myaccount.name ? 
                        <React.Fragment>
                            <h2>Create collectible item</h2>
                            <p className="mt-0">Please upload one at a time for optimization and to avoid some issues.</p>
                        </React.Fragment>
                    : */
                    <div className="w-100">
                        <h2 className="mt-5 d-block">Sign up and get started</h2>
                        <p>Oops! Looks like you haven't completed your profile yet. Please complete your details first to get started.</p>
                    </div>
                    }
                    
                </div>
                {/* end title */}

                {/* create form */}
                
                {props.myaccount && props.myaccount.name ?
                        <form action="#" className={props.loading ? "sign__form sign__form--create disable" : "sign__form sign__form--create"} id="mint-nft" >
                            <div className="row">
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <h4 className="sign__title">What category are you trying to make?</h4>
                                    </div>
                                    <div className="col-md-4">
                                        <ul className="filter__checkboxes">
                                            <li>
                                            <input id="cat1" type="checkbox" name="category_nft" value="3D Arts" onClick={props.handleCategory} />
                                            <label htmlFor="cat1">3D Arts</label>
                                            </li>
                                            <li>
                                            <input id="cat2" type="checkbox" name="category_nft" value="Cards" onClick={props.handleCategory} />
                                            <label htmlFor="cat2">Cards</label>
                                            </li>
                                            <li>
                                            <input id="cat3" type="checkbox" name="category_nft" value="Virtual Worlds" onClick={props.handleCategory} />
                                            <label htmlFor="cat3">Virtual Worlds</label>
                                            </li>
                                            <li>
                                            <input id="cat10" type="checkbox" name="category_nft" value="Audio" onClick={props.handleCategory} />
                                            <label htmlFor="cat10">Audio</label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <ul className="filter__checkboxes">
                                            <li>
                                            <input id="cat4" type="checkbox" name="category_nft" value="Collectibles" onClick={props.handleCategory} />
                                            <label htmlFor="cat4">Collectibles</label>
                                            </li>
                                            <li>
                                            <input id="cat5" type="checkbox" name="category_nft" value="Digital Art" onClick={props.handleCategory} />
                                            <label htmlFor="cat5">Digital Art</label>
                                            </li>
                                            <li>
                                            <input id="cat6" type="checkbox" name="category_nft" value="Sports" onClick={props.handleCategory} />
                                            <label htmlFor="cat6">Sports</label>
                                            </li>
                                            <li>
                                            <input id="cat12" type="checkbox" name="category_nft" value="Video" onClick={props.handleCategory} />
                                            <label htmlFor="cat12">Video</label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <ul className="filter__checkboxes">
                                            <li>
                                            <input id="cat7" type="checkbox" name="category_nft" value="Lands" onClick={props.handleCategory} />
                                            <label htmlFor="cat7">Lands</label>
                                            </li>
                                            <li>
                                            <input id="cat8" type="checkbox" name="category_nft" value="NSFW" onClick={props.handleCategory} />
                                            <label htmlFor="cat8">NSFW</label>
                                            </li>
                                            <li>
                                            <input id="cat9" type="checkbox" name="category_nft" value="Original Art" onClick={props.handleCategory} />
                                            <label htmlFor="cat9">Original Art</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <h4 className="sign__title">Upload file</h4>
                                </div>
                                <div className="col-12">
                                    <div className="sign__file">
                                        <label id="file1" htmlFor="sign__file-upload">(eg. Image, Audio, Video)</label>
                                        <input 
                                        data-name="#file1" 
                                        id="sign__file-upload" 
                                        name="file" 
                                        className="sign__file-upload" 
                                        type="file" 
                                        accept="video/mp4,video/x-m4v,video/*,.png,.jpg,.jpeg" 
                                        onChange={props.updateNFTFile}
                                        />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <h4 className="sign__title">Item details</h4>
                                </div>

                                <div className="col-12">
                                    <div className="sign__group">
                                        <label className="sign__label" htmlFor="itemname">Item name</label>
                                        <input 
                                        id="itemname" 
                                        type="text" 
                                        name="itemname" 
                                        className="sign__input" 
                                        placeholder="e. g. 'Crypto Heart'" 
                                        onChange={props.updatename}
                                        />
                                    </div>
                                </div>

                                {/*<div className="col-12">
                                    <div className="sign__group">
                                        <label className="sign__label" htmlFor="itemname">Unlockable file url (Original copy/HD Version)</label>
                                        <input 
                                        id="itemname" 
                                        type="text" 
                                        name="itemname" 
                                        className="sign__input" 
                                        placeholder="https://youritemurlpath.com" 
                                        onChange={props.updateunlockablefile}
                                        />
                                    </div>
                                </div>*/}

                                <div className="col-12">
                                    <div className="sign__group">
                                        <label className="sign__label" htmlFor="description">Description</label>
                                        <textarea 
                                        id="description" 
                                        name="description" 
                                        className="sign__textarea" 
                                        placeholder="e. g. 'After purchasing you will able to recived...'"
                                        onChange={props.updatedescription}
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="sign__group">
                                        <label className="sign__label" htmlFor="royalties">Royalties</label>
                                        <select id="royalties" name="royalties" className="sign__select" onChange={props.updateRoyalties}>
                                            <option value="0">No Royalties</option>
                                            <option value="5">5%</option>
                                            <option value="10">10%</option>
                                            <option value="20">20%</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">

                                    <div className="sign__group">
                                        <label className="sign__label" htmlFor="price">Price</label>
                                        <div class="input-group">
                                            <input 
                                                id="price" 
                                                type="text" 
                                                name="price" 
                                                className="sign__input" 
                                                placeholder="e. g. Price" 
                                                onChange={(e) => {props.setprice(e.target.value)}} />
                                            <span class="input-group-text">{props.currency}</span>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className="col-12">
                                    <h4 className="sign__title">Type of listing</h4>
                                </div>

                                <div className="col-12 mb-3">
                                    <div className="d-flex align-items-center">
                                        {/*<button 
                                        type="button"
                                        className={"btn btn-default type-of-listing-btn " + (TypeofListingVal == "auction" ? "active" : "")} 
                                        onClick={() => TypeOfListings('auction')}
                                        >
                                            Open Auction
                                        </button>*/}
                                        <button 
                                        type="button"
                                        className={"btn btn-default type-of-listing-btn " + (props.TypeofListingVal == "fixed" ? "active" : "")} 
                                        onClick={() => props.TypeOfListings('fixed')}
                                        >
                                            Fixed Price
                                            </button>
                                        <button 
                                        type="button"
                                        className={"btn btn-default type-of-listing-btn " + (props.TypeofListingVal == "reserved" ? "active" : "")} 
                                        onClick={() => props.TypeOfListings('reserved')}
                                        >
                                            Reserved
                                        </button>
                                    </div>

                                    <div className={"mt-4 " + (props.extraAuction ? 'd-block' : "d-none")}>
                                        <div className="col-12 col-md-6 row">
                                        <div className="sign__group">
                                                <label className="sign__label" htmlFor="royalties">Auction Time</label>
                                                <select id="royalties" name="royalties" className="sign__select" onChange={props.updateAuctionTime}>
                                                    <option value="1">1 Hour</option>
                                                    <option value="2">2 Hours</option>
                                                    <option value="3">3 Hours</option>
                                                    <option value="4">4 Hours</option>
                                                    <option value="5" defaultValue>5 Hours</option>
                                                    <option value="6">6 Hours</option>
                                                    <option value="7">7 Hours</option>
                                                    <option value="8">8 Hours</option>
                                                    <option value="9">9 Hours</option>
                                                    <option value="10">10 Hours</option>
                                                    <option value="11">11 Hours</option>
                                                    <option value="12">12 Hours</option>
                                                    <option value="13">13 Hours</option>
                                                    <option value="14">14 Hours</option>
                                                    <option value="15">15 Hours</option>
                                                    <option value="16">16 Hours</option>
                                                    <option value="17">17 Hours</option>
                                                    <option value="18">18 Hours</option>
                                                    <option value="19">19 Hours</option>
                                                    <option value="20">20 Hours</option>
                                                    <option value="21">21 Hours</option>
                                                    <option value="22">22 Hours</option>
                                                    <option value="23">23 Hours</option>
                                                    <option value="24">24 Hours</option>
                                                </select>
                                            </div>
                                        </div>
                                        <small className="text-danger">Auction time is set 24 hours by default. You'll have 3 transactions for <strong>mint, approve and auction</strong>.</small>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="sign__group sign__group--row">
                                    <p><small><strong>Note*:</strong> You need to make sure that you own this NFT. If we found this item somewhere in the internet and violates our terms of service, You may be banned from our platform permanently and will be reported to all other listings site.</small></p>
                                    
                                    </div>
                                </div>
                                {props.myaccount && props.myaccount.confirmed === false ? (
                                    <div className="col-12 col-xl-4">
                                        <button type="button" className="sign__btn bg-danger disable">
                                            Please confirm your email
                                        </button>
                                    </div>
                                ) : (
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-6">

                                                <button type="button" className="sign__btn" onClick={props.submit}>
                                                    {props.loading ? (
                                                        <div>
                                                            <FontAwesome spin name="circle-o-notch" /> Confirming...
                                                        </div>
                                                    ) : (
                                                        <div>Mint Item</div>
                                                    )}</button>
                                            </div>
                                            <div className="col-6">
                                                <button type="button" className="sign__btn bg-dark" onClick={props.submitFREE}>
                                                    {props.loading ? (
                                                        <div>
                                                            <FontAwesome spin name="circle-o-notch" /> Confirming...
                                                        </div>
                                                    ) : (
                                                        <div>Mint (Gas Free)</div>
                                                    )}</button>  
                                            </div>
                                        </div>
                                    </div>
                                    
                                )}
                                
                            </div>
                                                

                        </form>
                :
                    <a href="/signup" className="asset__btn asset__btn--clr">Complete sign up</a>
                }
                {/* end create form */}
            </div>
        </div>
    );
}