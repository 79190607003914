import swal from 'sweetalert';
import axios from 'axios';
import { alertPopup } from '../../../../utils'

export const toggleforsale = async (props, tokenId) => {

    await axios.get(props.APIUrl+"/nft/" + tokenId).then(async (response) => {
      
      swal({
        title: response.data.forsale ? "Remove from sale?" : "Back to sale?",
        text: "You can change this anytime",
        icon: "warning",
        buttons: {
          cancel: true,
          confirm: "Proceed"
        },
        dangerMode:true
      })
      .then(async (willDelete) => {
        if (willDelete) {
          await props.contract.methods.toggleForSale(tokenId).estimateGas({ from: props.account }, function(error, gasAmount){
            var GasfeewithAllowance = gasAmount;
            props.contract.methods.toggleForSale(tokenId).send({ from: props.account, gas: GasfeewithAllowance, gasPrice: props.estimateGasPrice, maxPriorityFeePerGas: null, maxFeePerGas: null })
            .on('transactionHash', async function(hash){

              // Finally after transaction, set NFT DB data to modified = true
              axios.post(props.APIUrl+"/nftupdate/" + tokenId +"/"+ props.currency, { modified:true, transactionhash: hash }).then((res) => {
                console.log("nft is in queue")
                axios.get(props.APIUrl+"/nfts/").then((response) => {
                  if(response.data) {
                    props.setThisNFT(response.data)
                  }
                });
              });

              // Change price activity
              const togglesale_activity = {
                type: props.arts.find(key => key.tokenid == props.id).forsale ? "removesale" : "forsale",
                from: props.account,
                amount: props.arts.find(key => key.tokenid == props.id).price,
                tokenid: tokenId,
                currency: props.currency,
                time: new Date().getTime(),
                transactionhash: hash,
                active:false
              }
              axios.post(props.APIUrl+"/activity/add", togglesale_activity).then((res) => {
                  console.log("activity added");
              });

              const emailContent = {
                  email: props.myaccount.email,
                  subject: "Updated status confirmed",
                  message: "You already have updated your NFT sale status"
              }
    
              // Send email notification to user
              axios.post(props.APIUrl+"/email", emailContent).then((res) => {
                console.log("email sent")
              });

              alertPopup('gottransaction');

            }).on('confirmation', async function(confirmationNumber, receipt){

              if(confirmationNumber == 0) {

                var saleOrNot = props.arts.find(key => key.tokenid == props.id).forsale ? "Removed from sale" : "Back to sale";

                swal({
                  title: saleOrNot+ " transaction confirmed!",
                  text: "Thank you for waiting! This window will close in 5 seconds or you can reload this page.",
                  icon: "success",
                  buttons: false,
                  closeOnClickOutside: false,
                  timer: 5000,
                }).then((value) => {
                    swal.close();
                    window.location.reload();
                }).catch(err => {
                    swal("Oops!", "Seems there's an issue. Please contact administrator", "error");
                });

                // Sync after confirmation
                //props.processTransactionConfirmation(receipt.transactionHash, tokenId, "toggleforsale");

              }
            }).on('error', function(error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                                
              alertPopup('error', error.code);
              props.setloading(false);

            });
          });

        }
      });
    });
  }