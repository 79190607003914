export function emailFormatting(user, message, image, content) {
    return (
        `<!DOCTYPE html>
            <html>
            <head>
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;800&family=Poppins:wght@400;600;700&display=swap');
                </style>
            
                <title>Dijaux - NFT Marketplace</title>
            </head>
            <body style="display: flex; justify-content: center; font-size: 14px; font-family: 'Inter', sans-serif; color: #3E3F43;">
            
                <div style="width: 600px; padding: 48px; background: #0cbaba;">
                    <div style="background: #fff; padding: 40px">

                        <div>
                            <p style="line-height: 21px; margin-top: 0; margin-bottom: 32px;">Hi `+user+`</p>
            
                            <h3 style="font-weight: 700; font-size: 24px; line-height: 32.4px; margin-bottom: 24px; margin-top: 0;">`+message+`</h3>

                            <div>
                                <img src="`+image+`" style="width:100%;">
                            </div>

                            `+content+`

                            <p style="line-height: 22.4px; margin-bottom: 24px; margin-top: 0;">Thank you for using our platform! We will make more features and improve our user experience.</p>
            
                            <div style="position: relative; width: 255px; height: 48px; margin-bottom: 24px;">
                                <a href="https://dijauxnfts.com/" target="_blank" style="background: #00BA88; padding: 15px 20px; text-transform: uppercase; font-weight: 600; border: 0; color: #fff; text-decoration: none; position: absolute; top: 0; left: 0; bottom: 0; right: 0; font-family: 'Poppins', sans-serif; letter-spacing: 0.05em;white-space: nowrap;">Back to Site</a>
                            </div>
            
                            <div>
                                <b>Support Team</b>
                            </div>
                        </div>
                    </div>
            
                    <div style="padding: 28px 0; color:#C5DBFC; text-align: center; font-size: 12px; line-height: 15px; font-weight: 400;">
                        <div style="display: flex; justify-content: space-around; width: 104px; margin: 0 auto; margin-bottom: 16px;">
                            <a href="https://www.facebook.com/Dijaux.nfts/" target="_blank"><img src="https://unloop.com/wp-content/uploads/2021/10/Facebook_white.png"></a>
                            <a href="https://www.instagram.com/dijaux_nft/" target="_blank"><img src="https://unloop.com/wp-content/uploads/2021/10/LinkedIN_white.png"></a>
                            <a href="https://twitter.com/Dijaux_nft" target="_blank"><img src="https://unloop.com/wp-content/uploads/2021/10/Youtube_white.png"></a>
                        </div>
            
                        <p style="margin-bottom: 16px; margin-top: 0; line-height: 18px;">Copyright © 2021 Dijaux, All rights reserved.</p>
            
                        <div>
                            <a href="https://dijaux.com/privacy-policy/" target="_blank" style="color: #C5DBFC; text-decoration: none; margin:0 15px;">Privacy Policy</a>
                            <a href="https://dijaux.com/faqs/" target="_blank" style="color: #C5DBFC; text-decoration: none; padding-left: 15px; border-left: 1px solid #fff;">FAQ</a>
                        </div>
                    </div>
                </div>
            
            </body>
            </html>`
    );
}