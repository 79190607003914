import React, {useState, useEffect } from 'react'
import Countdown from 'react-countdown'
import {Tabs, Tab} from 'react-bootstrap-tabs';
import axios from 'axios'
import { sort } from 'fast-sort';
import swal from 'sweetalert';
import AWS from "aws-sdk";
import { Follows } from '../../actions'
import convert from 'image-file-resize';
import convertETH from 'ether-converter';

// Components
import ProfileInfo from './profileinfo'
import Statistics from './statistics'
import ActivityProfile from './activityprofile'
import Settings from './settings'
import Followers from './followers'
import ShowItems from './showitems'

const s3 = new AWS.S3({
    endpoint: 'nyc3.digitaloceanspaces.com',
    accessKeyId: 'YGH6JB5BESBUDFMJP63D',
    secretAccessKey: '15BQpZMLAsNwS4ala5Bva1SvtX63fU0sXVBBrUqIQt0'
});

export function Profile(props) {

  var url_string = window.location;
  var url = new URL(url_string);
  var address = (url.searchParams.get("address") == null) ? props.account.toLowerCase() : url.searchParams.get("address").toLowerCase();
  var settings_url = (url.searchParams.get("setting") == null) ? null : true;
  var activity_url = (url.searchParams.get("activity") == null) ? null : true;
  var confirm_email = (url.searchParams.get("confirm") == null) ? null : true;

  const [ DijauxName, setDijauxName ] = useState('');
  const [ Email, setEmail ] = useState('');
  const [ DijauxUserDescription, setDijauxUserDescription ] = useState('');
  const [ ProfilePic, setProfilePic ] = useState('');
  const [ Cover, setCover ] = useState('');
  const [ SocialFacebook, setSocialFacebook ] = useState('');
  const [ SocialTwitter, setSocialTwitter ] = useState('');
  const [ SocialInstagram, setSocialInstagram ] = useState('');
  const [ Loading, setLoading ] = useState(false);
  const [ filter, setfilter] = useState('');
  const [allLikes, setLikes] = useState([]);
  const [allFollows, setFollows] = useState([]);
  const [allActivities, setActivities] = useState([]);
  const [allArts, setArts] = useState([]);

  // user stats
  const [NumberOfPurchase, setNumberOfPurchase] = useState(0);
  const [BiggestPurchase, setBiggestPurchase] = useState(0);
  const [TotalPurchase, setTotalPurchase] = useState(0);
  const [Numberoflikes, setNumberoflikes] = useState(0);
  const [RoyaltiesIncome, setRoyaltiesIncome] = useState(0);

  useEffect( async () => {

    if(confirm_email && address == props.account.toLowerCase()) {
      var confirmData = {
        confirmed: true
      }

      axios.post(props.APIUrl+"/update/" + props.account.toLowerCase(), confirmData).then(response => {
        swal({
            title: "Account Confirmed!",
            text: "You already completed your registration. you can now able to make mint and do transactions",
            icon: "success",
            buttons: false,
        }).then((value) => {
          window.location = "/mint";
        }).catch(err => {
            swal("Oops!", "Seems there's an issue on your registration. Please try again or contact administrator", "error");
        });
      });
    }

    if(DijauxName == '' && address) {
      axios
      .get(props.APIUrl+"/user/" + address)
      .then((response) => {
        if(response.data) {
        setDijauxName(response.data.name)
        setEmail(response.data.email)
        setDijauxUserDescription(response.data.description)
        setSocialFacebook(response.data.facebook == null ? "" : response.data.facebook)
        setSocialTwitter(response.data.twitter == null ? "" : response.data.twitter)
        setSocialInstagram(response.data.instagram == null ? "" : response.data.instagram)
        setProfilePic(response.data.picture)
        setCover(response.data.cover)
        }
      });
    }

    setLikes(props.likes)
    setFollows(props.follows)
    
    // Initialize user stats
    setNumberOfPurchase(props.activities.filter(activity => activity.from.toLowerCase() == address.toLowerCase() && (activity.type == "bought" || activity.type == "owned") && activity.currency == props.currency).length);
    
    setBiggestPurchase(convertETH(props.activities.filter(activity => activity.from.toLowerCase() == address.toLowerCase() && (activity.type == "bought" || activity.type == "owned") && activity.currency == props.currency).reduce((a, b) => { return Math.max(a, b.amount) }, 0),'wei','ether'));

    setTotalPurchase(convertETH(props.activities.filter(activity => activity.from.toLowerCase() == address.toLowerCase() && (activity.type == "bought" || activity.type == "owned") && activity.currency == props.currency).reduce((a, b) => { return a+ parseInt(b.amount) }, 0),'wei','ether'));
    
    setRoyaltiesIncome(props.activities.filter(activity => activity.from.toLowerCase() != address.toLowerCase() && (activity.type == "bought" || activity.type == "owned") && activity.currency == props.currency).reduce((a, b) => {
      return a + parseInt(props.arts.filter(art => art.creator.toLowerCase() == address.toLowerCase()).map((v,i) => { 
        return b.amount * (v.royalties / 100) 
      }));
    }, 0));

    setNumberoflikes(
      props.arts.filter(arts => arts.owner.toLowerCase() == address.toLowerCase() && arts.currency == props.currency).reduce((a, b) => {
        return a + parseInt(props.likes.filter(likes => likes.tokenid == b.tokenid).length)
      }, 0)
    );
    setActivities(sort(props.activities).desc(u => u.time))
    setArts(props.arts.filter(cards => cards.owner.toLowerCase() == address.toLowerCase() && cards.forsale));

  }, [props.arts, props.activities, props.follows, props.likes]);


  const activity_type = (type) => {
      var text = "";
      if(type == "minted") {
        text = "Minted";
      }else if(type == "listed") {
        text = "Listed in auction";
      }else if(type == "bid") {
        text = "Placed bid";
      }else if(type == "bought") {
        text = "Bought";
      }else if(type == "owned") {
        text = "Owned in auction";
      }
      return text;
  }

  const applyFilter = async (e) => {
    const filterVal = Array.from(document.querySelectorAll("input[type=checkbox][name=activities]:checked")).map(e => e.value)
    setfilter(filterVal.length === 0 ? '' : filterVal );
  }
  
  const copyCodeAuthor = async () => {
    var copyText = document.getElementById('author-code');
    copyText.select(); /* Select the text field */
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy"); /* Copy the text inside the text field */
    document.getElementById("copy__author__code").classList.add("active");
    setTimeout(function() {
      document.getElementById("copy__author__code").classList.remove("active");
    }, 1200);
  }

  const updateSocialFacebook = event => {
      setSocialFacebook(event.target.value);
  }

  const updateSocialTwitter = event => {
      setSocialTwitter(event.target.value);
  }

  const updateSocialInstagram = event => {
      setSocialInstagram(event.target.value);
  }

  const updateFieldSignupName = event => {
    setDijauxName(event.target.value);
  }
  
  const updateEmailAddress = event => {
    setEmail(event.target.value);
  }

  const updateFieldSignupDescription = event => {
    setDijauxUserDescription(event.target.value);
  }

  const changeHandlerProfilePic = (event) => {
      const accepted = ["jpg","jpeg","png"];

      if(accepted.includes(event.target.files[0].name.split('.').pop()) && ((event.target.files[0].size / 1024 / 1024) < 20 )) {
        let img = new Image()
        img.src = window.URL.createObjectURL(event.target.files[0])
        img.onload = () => {
          var percentage = (160 / img.width);
          var heightCalc = Math.ceil(img.height * percentage);
          convert({ 
            file: event.target.files[0],  
            width: 160, 
            height: heightCalc,
            type: event.target.files[0].name.split('.').pop()
            }).then(resp => {
                setProfilePic(resp);
            }).catch(error => {
                  console.log("error");
            })
        }
        document.getElementById('profilePicture').innerHTML = event.target.files[0].name;
      }else {
        swal({
            title: "Wrong format",
            text: "Please only upload accepted image types: jpg, jpeg, png and below 20MB for optimization",
            icon: "warning",
        });
      }
      
  }

  const changeHandlerCover = (event) => {
      const accepted = ["jpg","jpeg","png"];
      if(accepted.includes(event.target.files[0].name.split('.').pop()) && ((event.target.files[0].size / 1024 / 1024) < 20 )) {
        let img = new Image()
        img.src = window.URL.createObjectURL(event.target.files[0])
        img.onload = () => {
            var percentage = (1500 / img.width);
            var heightCalc = Math.ceil(img.height * percentage);
            convert({ 
                file: event.target.files[0],  
                width: 1500, 
                height: heightCalc,
                type: event.target.files[0].name.split('.').pop()
                }).then(resp => {
                    setCover(resp);
                }).catch(error => {
                      console.log("error");
                })
        }
        document.getElementById('coverPhoto').innerHTML = event.target.files[0].name;
      }else {
        swal({
            title: "Wrong format",
            text: "Please only upload accepted image types: jpg, jpeg, png and below 20MB for optimization",
            icon: "warning",
        });
      }
  }

  const [filterToggle, setfilterToggle] = useState(false);

  function toggleFilter() {
    setfilterToggle(filterToggle === true ? false : true);
  }

  const EditProfileSubmimt = async () => {
    setLoading(true);

    swal({
        title: "Uploading your data",
        text: "Validating information . Please wait a moment...",
        buttons: false,
        closeOnClickOutside: false,
    });
    

    var dataJSON = "";
    
    if(typeof ProfilePic.name == 'string' && typeof Cover == 'string') { // If the profile pic variable is a file
        // Add a file to a Space
        var profilepicuser = {
          Body: ProfilePic,
          Bucket: "dijauxnft",
          Key: "users/profile/"+ProfilePic.name.replace(/[^a-zA-Z0-9. ]/g, '').replace(/\s/g,'_').toLowerCase(),
          ACL: 'public-read'
        };

        s3.putObject(profilepicuser, function(err, data) {
          if (err) { 
              console.log(err, err.stack);
          }else {
              const ProfilePhoto = "https://dijauxnft.nyc3.digitaloceanspaces.com/users/profile/"+ProfilePic.name.replace(/[^a-zA-Z0-9. ]/g, '').replace(/\s/g,'_').toLowerCase();
              dataJSON = {
                name: DijauxName,
                email: Email,
                description: DijauxUserDescription,
                facebook: SocialFacebook,
                twitter: SocialTwitter,
                instagram: SocialInstagram,
                picture: ProfilePhoto,
                cover: Cover
              }

              axios.post(props.APIUrl+"/update/" + address, dataJSON).then(response => {
                setLoading(false); 
                swal({
                    title: "Updated Successfully",
                    text: "You just updated your profile successfully",
                    icon: "success",
                    buttons: false,
                    closeOnClickOutside: false,
                    timer: 1000,
                }).then((value) => {
                    swal.close();
                    window.location.reload();
                }).catch(err => {
                    swal("Oops!", "Seems there's an issue on your registration. Please try again or contact administrator", "error");
                });
              });
          }
        });

        
    }else if(typeof Cover.name == 'string' && typeof ProfilePic == 'string') { // If the Cover pic variable is a file
      
          // Add a file to a Space
          var coveruser = {
            Body: Cover,
            Bucket: "dijauxnft",
            Key: "users/cover/"+Cover.name.replace(/[^a-zA-Z0-9. ]/g, '').replace(/\s/g,'_').toLowerCase(),
            ACL: 'public-read'
        };

          s3.putObject(coveruser, function(err, data) {
            if (err) { 
                console.log(err, err.stack);
            }else {
                const CoverPicture = "https://dijauxnft.nyc3.digitaloceanspaces.com/users/cover/"+Cover.name.replace(/[^a-zA-Z0-9. ]/g, '').replace(/\s/g,'_').toLowerCase();

                dataJSON = {
                  name: DijauxName,
                  email: Email,
                  description: DijauxUserDescription,
                  facebook: SocialFacebook,
                  twitter: SocialTwitter,
                  instagram: SocialInstagram,
                  picture: ProfilePic,
                  cover: CoverPicture
                }

                axios.post(props.APIUrl+"/update/" + address, dataJSON).then(response => {
                  setLoading(false); 
                  swal({
                      title: "Updated Successfully",
                      text: "You just updated your profile successfully",
                      icon: "success",
                      buttons: false,
                      closeOnClickOutside: false,
                      timer: 1000,
                  }).then((value) => {
                      swal.close();
                      window.location.reload();
                  }).catch(err => {
                      swal("Oops!", "Seems there's an issue on your registration. Please try again or contact administrator", "error");
                  });
                });
            }
          });

        
    }else if(typeof Cover.name == 'string' && typeof ProfilePic.name == 'string') { // If the both pic variable is a file

      var profilepicuser = {
          Body: ProfilePic,
          Bucket: "dijauxnft",
          Key: "users/profile/"+ProfilePic.name.replace(/[^a-zA-Z0-9. ]/g, '').replace(/\s/g,'_').toLowerCase(),
          ACL: 'public-read'
      };
      
      s3.putObject(profilepicuser, function(err, data) {
          if (err) { 
              console.log(err, err.stack);
          }else {
              const ProfilePhoto = "https://dijauxnft.nyc3.digitaloceanspaces.com/users/profile/"+ProfilePic.name.replace(/[^a-zA-Z0-9. ]/g, '').replace(/\s/g,'_').toLowerCase();

              var coveruser = {
                  Body: Cover,
                  Bucket: "dijauxnft",
                  Key: "users/cover/"+Cover.name.replace(/[^a-zA-Z0-9. ]/g, '').replace(/\s/g,'_').toLowerCase(),
                  ACL: 'public-read'
              };
              
              s3.putObject(coveruser, function(err, data) {
                  if (err) { 
                      console.log(err, err.stack);
                  }else {
                      const CoverPicture = "https://dijauxnft.nyc3.digitaloceanspaces.com/users/cover/"+Cover.name.replace(/[^a-zA-Z0-9. ]/g, '').replace(/\s/g,'_').toLowerCase();

                      dataJSON = {
                        name: DijauxName,
                        email: Email,
                        description: DijauxUserDescription,
                        picture: ProfilePhoto,
                        cover: CoverPicture,
                        facebook: SocialFacebook,
                        twitter: SocialTwitter,
                        instagram: SocialInstagram,
                      }

                      axios.post(props.APIUrl+"/update/" + address, dataJSON).then(response => {
                        setLoading(false); 
                        swal({
                            title: "Updated Successfully",
                            text: "You just updated your profile successfully",
                            icon: "success",
                            buttons: false,
                            closeOnClickOutside: false,
                            timer: 1000,
                        }).then((value) => {
                            swal.close();
                            window.location.reload();
                        }).catch(err => {
                            swal("Oops!", "Seems there's an issue on your registration. Please try again or contact administrator", "error");
                        });
                      });
                  }     
              });
          }     
      });
    }else { // none of the pictures are changed
      dataJSON = {
        name: DijauxName,
        email: Email,
        description: DijauxUserDescription,
        picture: ProfilePic,
        cover: Cover,
        facebook: SocialFacebook,
        twitter: SocialTwitter,
        instagram: SocialInstagram,
      }

      axios.post(props.APIUrl+"/update/" + address, dataJSON).then(response => {
        setLoading(false); 
        swal({
            title: "Updated Successfully",
            text: "You just updated your profile successfully",
            icon: "success",
            buttons: false,
            closeOnClickOutside: false,
            timer: 1000,
        }).then((value) => {
            swal.close();
            window.location.reload();
        }).catch(err => {
            swal("Oops!", "Seems there's an issue on your registration. Please try again or contact administrator", "error");
        });
      });
    }
    
}

  return (
    <main className="main">
      <div className="main__author" style={{
        backgroundImage: `url(${Cover})`,
        backgroundPosition: `center center`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`
      }}></div>
      <div className="container-fluid px-md-5">
        <div className="row row--grid">
          <ProfileInfo 
            {...props}
            ProfilePic={ProfilePic}
            DijauxName={DijauxName}
            DijauxUserDescription={DijauxUserDescription}
            SocialFacebook={SocialFacebook}
            SocialInstagram={SocialInstagram}
            SocialTwitter={SocialTwitter}
            address={address}
            copyCodeAuthor={copyCodeAuthor}
            allFollows={allFollows}
            Follows={Follows}
            setFollows={setFollows}
          />
  
          <div className="col-12 col-xl-9">
            <div className="profile">
              {/* tabs nav */}
              <Tabs 
                    selected={settings_url ? 7 : activity_url ? 6 : 0} 
                    className="pb-3 profile__tabs w-100 d-block tabs-selection"
                    onSelect={(index, label) => {

                    const endOffset = props.itemOffset + 6;
                    if(index == 0) { // On sale
                        setArts(props.arts.filter(cards => cards.owner.toLowerCase() == address.toLowerCase() && cards.forsale));
                    }else if(index == 1) { // Collections
                        setArts(props.arts.filter(cards => cards.owner.toLowerCase() == address.toLowerCase() && !cards.forsale));
                    }else if(index == 2) { // Created
                        setArts(props.arts.filter(cards => cards.creator.toLowerCase() == address.toLowerCase()));
                    }else if(index == 3) { // Liked
                        setArts(props.arts.filter(cards => props.likes.find(l => l.tokenid == cards.tokenid && l.currency == cards.currency && l.address.toLowerCase() == address.toLowerCase())));
                    }
                }}
              >
                <Tab label={"On Sale (" + props.arts.filter(cards => cards.owner.toLowerCase() == address.toLowerCase() && cards.forsale).length + ")"}>
                    <div className="row row--grid">
                        <ShowItems 
                            {...props}
                            allArts={allArts} 
                            allLikes={allLikes} 
                            setLikes={setLikes} 
                        />
                    </div>
                </Tab>
                <Tab label={"Collections (" + props.arts.filter(cards => cards.owner.toLowerCase() == address.toLowerCase() && !cards.forsale).length + ")"}>
                    <div className="row row--grid">
                        <ShowItems 
                            {...props}
                            isCollection={true}
                            allArts={allArts} 
                            allLikes={allLikes} 
                            setLikes={setLikes} 
                        />
                    </div>
                </Tab>
                <Tab label={"Created (" + props.arts.filter(cards => cards.creator.toLowerCase() == address.toLowerCase() ).length + ")"}>
                    <div className="row row--grid">
                        <ShowItems 
                            {...props}
                            allArts={allArts} 
                            allLikes={allLikes} 
                            setLikes={setLikes} 
                        />
                    </div>
                </Tab>
                <Tab label={"Liked (" + props.arts.filter(cards => props.likes.find(l => l.tokenid == cards.tokenid && l.currency == cards.currency && l.address.toLowerCase() == address.toLowerCase())).length + ")"}>
                    <div className="row row--grid">
                        <ShowItems 
                            {...props}
                            allArts={allArts} 
                            allLikes={allLikes} 
                            setLikes={setLikes} 
                        />
                    </div>
                </Tab>
                <Tab label={"Followers (" + allFollows.filter(follows => follows.address.toLowerCase() == address.toLowerCase()).length + ")"}>
                    <Followers
                        {...props}
                        allFollows={allFollows}
                        address={address}
                    />
                </Tab>
                {props.metamaskConnected ? (
                  <Tab label="Statistics">
                    <Statistics
                        {...props}
                        NumberOfPurchase={NumberOfPurchase}
                        Numberoflikes={Numberoflikes}
                        RoyaltiesIncome={RoyaltiesIncome}
                        BiggestPurchase={BiggestPurchase}
                        TotalPurchase={TotalPurchase}
                        convertETH={convertETH}
                    />
                  </Tab>
                ) : (
                  <Tab label="Statistics">
                    <div>
                      <hr/>
                      <h2>Please connect to metamask to view!</h2>
                    </div>
                  </Tab>
                )}
                <Tab label={address.toLowerCase() == props.account.toLowerCase() ? "My Activity" : "Activity"}>
                    <ActivityProfile
                        {...props}
                        toggleFilter={toggleFilter}
                        filterToggle={filterToggle}
                        applyFilter={applyFilter}
                        allActivities={allActivities}
                        address={address}
                        filter={filter}
                        activity_type={activity_type}
                        convertETH={convertETH}
                    />
                </Tab>
                {props.account.toLowerCase() == address.toLowerCase() ? (
                  <Tab label="Settings">
                        <Settings
                            {...props}
                            changeHandlerProfilePic={changeHandlerProfilePic}
                            changeHandlerCover={changeHandlerCover}
                            DijauxName={DijauxName}
                            updateFieldSignupName={updateFieldSignupName}
                            Email={Email}
                            updateEmailAddress={updateEmailAddress}
                            DijauxUserDescription={DijauxUserDescription}
                            updateFieldSignupDescription={updateFieldSignupDescription}
                            SocialFacebook={SocialFacebook}
                            updateSocialFacebook={updateSocialFacebook}
                            SocialTwitter={SocialTwitter}
                            updateSocialTwitter={updateSocialTwitter}
                            SocialInstagram={SocialInstagram}
                            updateSocialInstagram={updateSocialInstagram}
                            Loading={Loading}
                            EditProfileSubmimt={EditProfileSubmimt}
                        />
                  </Tab>
                ) : (
                  <div></div>
                )}
                
              </Tabs>
              {/* end tabs nav */}
            </div>
            </div>
        </div>	
      </div>
    </main>
  );
  
    
}