import axios from 'axios'

export function Follows(e,following, method, account, apiurl, setFollows)  { 

    e.preventDefault();

    e.target.className = e.target.className + " disable";
    e.target.innerHTML = "Loading";

    const data = {
        address: following,
        follower: account
    }
    axios.post(apiurl+"/follow/"+ method, data).then(async (res) => {

        console.log(res)

        axios.get(apiurl+"/follows/").then((response) => {
        if(response.data) {
            setFollows(response.data)
            e.target.className = method == "add" ? "author__follow followed" : "author__follow";
            e.target.innerHTML = method == "add" ? "Unfollow" : "Follow";
        }
        });
    });

}