export default function Filter(props) {


    return (
        <div className="filter-wrap">
            <button className="filter-wrap__btn" onClick={props.toggleFilter}>Open filter</button>

            <div className={"collapse filter-wrap__content p-3 " + (props.filterToggle === true ? 'show' : '')} id="collapseFilter">
                {/* filter */}
                <div className="filter filter--sticky">

                    <h4 className="filter__title">Sort by</h4>
                    <div className="filter__group">
                        <div className="sign__group">
                            <select id="sort-by" name="sort-by" className="sign__select" onChange={(e) => {
                                props.sortArtists(e.target.value);
                            }}>
                                <option value="latest">Latests artists</option>
                                <option value="oldest">Oldest artists</option>
                            </select>
                        </div>
                    </div>

                    <h4 className="filter__title">Search by name</h4>
                    <div className="filter__group">
                        <input
                            type="text"
                            value={props.searchName}
                            className="sign__input"
                            placeholder="Start typing..."
                            onChange={(e) => {props.searchByName(e.target.value);}}
                        />
                    </div>
                    <h4 className="filter__title">Filters <button type="button" onClick={props.clearFilter}>Clear all</button></h4>
                </div>
                {/* end filter */}
            </div>
        </div>
    );
}