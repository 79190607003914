import FontAwesome from 'react-fontawesome'

export default function SignUpForm(props) {
    return (
        <div action="#" className={props.Loading ? "sign__form sign__form--create disable mt-5" : "sign__form sign__form--create mt-5"}>
            <div className="row">
                <div className="col-12">
                    <div className="main__title main__title--create mt-0">
                            <h2>Be on listings!</h2>
                            <p>You can easily import your minted tokens by just signing your metamask in our platform and adding some details to your profile</p> 
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-12 col-xl-6">
                <div className="sign__file">
                    <label id="profilePicture" className="sign__file-upload" htmlFor="DijauxProfilePicture">(Upload Profile picture*)</label>
                    <input 
                    data-name="#profilePicture"
                    className="sign__file-upload"
                    accept=".png,.jpg,.jpeg"
                    type="file" 
                    name="DijauxProfilePicture"
                    id="DijauxProfilePicture" 
                    onChange={props.changeHandlerProfilePic} 
                    />
                </div>
                </div>

                <div className="col-12 col-md-6 col-lg-12 col-xl-6">
                <div className="sign__file">
                    <label id="coverPhoto" className="sign__file-upload" htmlFor="DijauxCoverPhoto">(Upload Cover photo*)</label>
                    <input 
                    data-name="#coverPhoto"
                    className="sign__file-upload"
                    accept=".png,.jpg,.jpeg"
                    type="file" 
                    name="DijauxCoverPhoto"
                    id="DijauxCoverPhoto" 
                    onChange={props.changeHandlerCover} 
                    />
                </div>
                </div>
                <div className="col-12 col-md-12">
                    <div className="sign__group">
                        <input 
                        type="text" 
                        className="sign__input" 
                        name="DijauxName"
                        id="DijauxName" 
                        placeholder="Nickname (Required)" 
                        value={props.DijauxName}
                        onChange={props.updateFieldSignupName}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-12">
                    <div className="sign__group">
                        <input 
                        type="text" 
                        className="sign__input" 
                        name="Email"
                        id="Email" 
                        placeholder="Email (Required)" 
                        value={props.Email}
                        onChange={props.updateEmailAddress}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-12">
                    <div className="sign__group">
                        <textarea 
                        className="sign__textarea" 
                        id="DijauxUserDescription" 
                        name="DijauxUserDescription"
                        placeholder="About me"
                        rows="3"
                        value={props.DijauxUserDescription}
                        onChange={props.updateFieldSignupDescription}
                        ></textarea>
                    </div>
                </div>
                

                <div className="col-12">
                <h4 className="sign__title">Social profiles</h4>
                </div>

                <div className="col-12 col-md-12">
                    <label>Facebook</label>
                    <div className="sign__group">
                        <input 
                        type="text" 
                        className="sign__input" 
                        name="SocialFacebook"
                        id="SocialFacebook" 
                        placeholder="Facebook URL" 
                        value={props.SocialFacebook}
                        onChange={props.updateSocialFacebook}
                        />
                    </div>
                </div>

                <div className="col-12 col-md-12">
                    <label>Twitter</label>
                    <div className="sign__group">
                        <input 
                        type="text" 
                        className="sign__input" 
                        name="SocialTwitter"
                        id="SocialTwitter" 
                        placeholder="Twitter URL" 
                        value={props.SocialTwitter}
                        onChange={props.updateSocialTwitter}
                        />
                    </div>
                </div>
                
                <div className="col-12 col-md-12">
                    <label>Instagram</label>
                    <div className="sign__group">
                        <input 
                        type="text" 
                        className="sign__input" 
                        name="SocialInstagram"
                        id="SocialInstagram" 
                        placeholder="Instagram URL" 
                        value={props.SocialInstagram}
                        onChange={props.updateSocialInstagram}
                        />
                    </div>
                </div>
                {props.metamaskConnected && props.account ? (
                    <div className="col-12 col-xl-3">
                        <button type="button" className="sign__btn" onClick={props.SignupSubmimt}>
                            {props.Loading ? (
                                <div>
                                    <FontAwesome spin name="circle-o-notch" /> Please wait...
                                </div>
                            ) : (
                                <div>Complete Signup</div>
                            )}</button>
                    </div>
                ) : (
                    <a href="#/" className="disable bg-danger asset__btn asset__btn--clr">Please connect Metamask!</a>
                )}
                
            </div>
        </div>
    );
}