import { useState, useEffect } from 'react'
import { sort } from 'fast-sort';
import Filter from './filter'
import Authors from './authors'

export function Artists(props) {

  const [allFollows, setFollows] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [searchName, setSearchName] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [filterToggle, setfilterToggle] = useState(false);
  const postsPerPage = 8;

  useEffect(() => {
    setFollows(props.follows)
    const endOffset = itemOffset + 8;
    setCurrentItems(sort(props.creators).desc(u => u._id).slice(itemOffset, endOffset));
    setPageCount(Math.ceil(sort(props.creators).desc(u => u._id).length / 8));
  }, [props.follows, props.creators, itemOffset, 8])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 8) % sort(props.creators).desc(u => u._id).length;
    setItemOffset(newOffset);
  };

  const clearFilter = () => {
    const endOffset = itemOffset + postsPerPage;
    setCurrentItems(sort(props.creators).desc(u => u._id).slice(itemOffset, endOffset))
    setPageCount(Math.ceil(sort(props.creators).desc(u => u._id).length / postsPerPage));
    setSearchName("");
  }

  function toggleFilter() {
    setfilterToggle(filterToggle === true ? false : true);
  }

  const searchByName = (val) => {
    const endOffset = itemOffset + postsPerPage;
    setCurrentItems(props.creators.filter(v => v.name.toLowerCase().includes(val)).slice(itemOffset, endOffset));
    setPageCount(Math.ceil(props.creators.filter(v => v.name.toLowerCase().includes(val)).length / postsPerPage));
    setSearchName(val);
  }

  const sortArtists = (val) => {
    const endOffset = itemOffset + postsPerPage;
    if (val == "latest") {
      setCurrentItems(sort(props.creators.slice(itemOffset, endOffset)).desc(u => u._id))
    } else if (val == "oldest") {
      setCurrentItems(sort(props.creators.slice(itemOffset, endOffset)).asc(u => u._id))
    }
    setPageCount(Math.ceil(sort(props.arts).desc(u => u.modifieddate).length / postsPerPage));
  }

  return (
    <main className="main">
      <div className="container-fluid px-md-5">
        <div className="row row--grid">

          {/* title */}
          <div className="col-12">
            <div className="main__title main__title--page">
              <h1>Creators</h1>
            </div>
          </div>
          {/* end title */}

        </div>

        {/* tabs nav */}
        <div className="row row--grid">
          <div className="col-12 col-xl-2">
            <Filter
              {...props}
              toggleFilter={toggleFilter}
              filterToggle={filterToggle}
              clearFilter={clearFilter}
              sortArtists={sortArtists}
              searchName={searchName}
              searchByName={searchByName}
            />
          </div>
          <div className="col-12 col-xl-10 px-md-5">
            <Authors
              {...props}
              handlePageClick={handlePageClick}
              currentItems={currentItems}
              allFollows={allFollows}
              pageCount={pageCount}
              setFollows={setFollows}
            />
          </div>
        </div>
      </div>
    </main>
  );
}