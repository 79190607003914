import React from 'react'
//import Countdown from 'react-countdown'
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { Likes } from '../../../actions'
import Discplaying from '../../../main/assets/img/discplaying.gif';
import ReactPaginate from 'react-paginate';
import convertETH from 'ether-converter';
import { sort } from 'fast-sort';
import { checkNSFW, time2TimeAgo } from '../../../utils'

export default function Explore(props) {

  function Items({ currentItems }) {

    return (
      <>
        {
          //props.auction.map((auctionextras, index) => (              
          //allArts.filter(cards => cards.forsale && cards.tokenid == auctionextras.tokenid && (auctionextras.auction_endtime > new Date().getTime())).map((auction, index) => ( 
          currentItems && currentItems.map((card, index) => (
            <div key={index} className={"col-12 col-sm-6 col-lg-4 col-xl-3 sorted-items outer"}>
              <div className={"card"}>
                <a href={"/nft/" + card.tokenid + "/" + card.currency} className={"card__cover " + checkNSFW(card.category)}>
                  {['mp3', 'wav'].includes(card.image.split('.').pop()) ? (
                    <React.Fragment>
                      <div className="floating-text-loop">Audio</div>
                      <img src={Discplaying} alt="" width="260" height="300" />
                    </React.Fragment>

                  ) : (
                    <React.Fragment>
                      {['mp4', 'ogg', 'webm', 'mov'].includes(card.image.split('.').pop()) ? (
                        <React.Fragment>
                          <div className="floating-text-loop">Video</div>
                          <video autoPlay loop muted playsInline key={card.image}>
                            <source src={card.image} type={"video/" + card.image.split('.').pop()} />
                            Your browser does not support the video tag.
                          </video>
                        </React.Fragment>
                      ) : (
                        <img src={card.image} alt="" width="260" height="300" />
                      )}
                    </React.Fragment>
                  )}

                  {/* for auction
                    <span className="card__time">
                        <Countdown date={parseInt(auction.auction_endtime)}>
                            <strong>Auction Ended!</strong>
                        </Countdown>
                    </span>
                  */}

                </a>

                <h3 className="card__title"><a href={"/nft/" + card.tokenid + "/" + card.currency}>{card.name}</a></h3>
                <div>
                  {card.category.split(',').map((v) => [
                    <span className="category-balloon"><a href={"/discover?cat=" + v}>{v}</a></span>
                  ])}
                </div>
                <div className="card__author">
                  {props.creators.filter(creator => creator.address.toLowerCase() == card.owner.toLowerCase()).map((artist, index) => (
                    <div key={index} className="card__author card__author--verified">
                      <img src={artist.picture} alt="" />
                      <a href={"/profile?address=" + artist.address}>{artist.name}</a>
                    </div>
                  ))}
                </div>

                <div className="card__info">

                  <div className="card__price">
                    <span>{card.forsale ? "Fixed Price" : "Reserved Price"}</span>
                    <span>{convertETH(card.price, 'wei', 'ether')} {card.currency} <small className="text-secondary fw-bold">(${props.convertUSD(convertETH(card.price, 'wei', 'ether'), card.currency)})</small></span>
                  </div>

                  {/* for auction
                    <div className="card__price">
                        <span>{auction.price > auctionextras.auction_highestbid ? "Starting price" : "Current bid"}</span>
                        <span>{convertETH(auction.price > auctionextras.auction_highestbid ? auction.price : auctionextras.auction_highestbid,'wei','ether')} {auction.currency} <small className="text-secondary fw-bold">(${props.convertUSD(convertETH(auction.price > auctionextras.auction_highestbid ? auction.price : auctionextras.auction_highestbid,'wei','ether'),auction.currency)})</small>
                        </span>
                    </div>
                  */}

                  {props.metamaskConnected && !props.noProvider ? (
                    <div class="d-flex flex-column justify-content-end">
                      <span className="activity__time">{time2TimeAgo(Math.round(card.created_datetime / 1000))}</span>
                      <button className="card__likes" type="button" onClick={(e) => Likes(e, card.tokenid, card.currency, props.allLikes.some(r => r.tokenid == card.tokenid && r.address == props.account && r.currency == card.currency) ? "delete" : "add", props.account, props.APIUrl, props.setLikes)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          {props.allLikes.some(r => r.tokenid == card.tokenid && r.address.toLowerCase() == props.account.toLowerCase() && r.currency == card.currency) ? (
                            <path d="M 20.16 5 A 6.29 6.29 0 0 0 12 4.36 a 6.27 6.27 0 0 0 -8.16 9.48 l 6.21 6.22 a 2.78 2.78 0 0 0 3.9 0 l 6.21 -6.22 A 6.27 6.27 0 0 0 20.16 5 Z Z" />
                          ) : (
                            <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z" />
                          )}
                        </svg>
                        <span>{props.allLikes.filter(r => r.tokenid == card.tokenid && r.currency == card.currency).length}</span>
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
      </>
    );
  }

  return (
    <Tabs
      className="main__tabs d-block tabs-selection discover"
      onSelect={(index, label) => {

        const endOffset = props.itemOffset + 6;
        if (index == 0) { // All items
          props.setCurrentItems(sort(props.arts.filter(cards => !props.auctionexists.includes(cards.tokenid.toString()))).desc(u => u.tokenid).slice(props.itemOffset, endOffset));

          props.setPageCount(Math.ceil(props.arts.filter(cards => !props.auctionexists.includes(cards.tokenid.toString())).length / 6));
        } else if (index == 1) { // Only fixed price
          props.setCurrentItems(sort(props.arts.filter(cards => cards.forsale && !props.auctionexists.includes(cards.tokenid.toString()))).desc(u => u.tokenid).slice(props.itemOffset, endOffset));

          props.setPageCount(Math.ceil(props.arts.filter(cards => cards.forsale && !props.auctionexists.includes(cards.tokenid.toString())).length / 6));
        } else { // Reserved price
          props.setCurrentItems(sort(props.arts.filter(cards => !cards.forsale && !props.auctionexists.includes(cards.tokenid.toString()))).desc(u => u.tokenid).slice(props.itemOffset, endOffset));

          props.setPageCount(Math.ceil(props.arts.filter(cards => !cards.forsale && !props.auctionexists.includes(cards.tokenid.toString())).length / 6));
        }
      }}
    >
      <Tab label="All">
        <div className="row row--grid ">
          <div className="row row--grid">
            <Items currentItems={props.currentItems} />
          </div>
          <div className="row row--grid mt-5">
            <div className="col-12 d-flex justify-content-end">
              <ReactPaginate
                nextLabel="next"
                onPageChange={props.handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={props.pageCount}
                previousLabel="previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      </Tab>
      {/*<Tab label="Live Auction">
                <div className="row row--grid">
                    <div className="row row--grid">
                    <Items currentItems={props.currentItems} />  
                    </div>
                    <div className="row row--grid mt-5">
                    <div className="col-12 d-flex justify-content-end">
                        <ReactPaginate
                            nextLabel="next"
                            onPageChange={props.handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={props.pageCount}
                            previousLabel="previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />   
                    </div>     
                    </div>
                </div>
            </Tab>*/}
      <Tab label="Fixed Price">
        <div className="row row--grid">
          <div className="row row--grid">
            <Items currentItems={props.currentItems} />
          </div>
          <div className="row row--grid mt-5">
            <div className="col-12 d-flex justify-content-end">
              <ReactPaginate
                nextLabel="next"
                onPageChange={props.handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={props.pageCount}
                previousLabel="previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      </Tab>
      <Tab label="Reserved">
        <div className="row row--grid">
          <div className="row row--grid">
            <Items currentItems={props.currentItems} />
          </div>
          <div className="row row--grid mt-5">
            <div className="col-12 d-flex justify-content-end">
              <ReactPaginate
                nextLabel="next"
                onPageChange={props.handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={props.pageCount}
                previousLabel="previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      </Tab>
    </Tabs>
  );
}