import React, { useState } from 'react';
import { Link } from "react-router-dom";

import swal from 'sweetalert'
import axios from 'axios'
import { emailFormatting } from '../../utils'

import Discplaying from '../../main/assets/img/discplaying.gif';
function Header(props) {

  const [toggleMenu, settoggleMenu] = useState(false);
  const [toggleheader, setheaderMenu] = useState(false);
  const [togglesearch, setsearch] = useState(false);
  const [searchArts, setsearchArts] = useState(false);
  const [searchArtists, setsearchArtists] = useState(false);
  const [searchLoad, setsearchLoad] = useState(false);

  const openProfiledropdown = (event) => {
    settoggleMenu((toggleMenu == false) ? true : false);
  }

  const openheaderdropdown = (event) => {
    setheaderMenu((toggleheader == false) ? true : false);
  }

  const openSearch = (event) => {
    setsearch((togglesearch == false) ? true : false);
    setsearchLoad(false);
  }

  const resendConfirmation = (email) => {
    const emailContent = {
      email: email,
      subject: "Thank you for joining Dijaux!",
      message: emailFormatting(
        props.myaccount.name,
        "Congrats on creating your account with Dijaux Marketplace. Have fun buying and selling your NFTs. 🙌",
        "https://dijaux.com/wp-content/uploads/2021/12/Dijaux_Welcome_Email.png",
        `<div style="position: relative; width: 255px; height: 48px; margin-bottom: 24px;">
              <a href="https://dijauxnfts.com/profile?address=`+ props.myaccount.address.toLowerCase() + `&confirm=yes" target="_blank" style="background: #00BA88; padding: 15px 20px; text-transform: uppercase; font-weight: 600; border: 0; color: #fff; text-decoration: none; position: absolute; top: 0; left: 0; bottom: 0; right: 0; font-family: 'Poppins', sans-serif; letter-spacing: 0.05em;white-space: nowrap;">Confirm email</a>
          </div>`
      )
    }

    // Send email notification to user
    axios.post(props.APIUrl + "/email", emailContent).then((res) => {
      swal({
        title: "Resend Confirmation Success!",
        text: "Thank you for waiting! This window will close in 5 seconds or you can reload this page.",
        icon: "success",
        buttons: false,
        closeOnClickOutside: false,
        timer: 5000,
      }).then((value) => {
          swal.close();
          window.location.reload();
      }).catch(err => {
          swal("Oops!", "Seems there's an issue. Please contact administrator", "error");
      });
    });
  }

  const searchAllSite = async (e) => {
    setsearchLoad(true);

    const searchValue = e.target.value.toLowerCase();

    if (searchValue == "") {
      setsearchLoad(false);
    }

    const artsResults = await props.arts.filter(function (el, i) { // All arts results
      return el.name.toLowerCase().indexOf(searchValue) > -1 || el.description.toLowerCase().indexOf(searchValue) > -1
    });

    setsearchArts(artsResults == "" ? false : artsResults)

    const artistsResults = await props.creators.filter(function (el) { // All artists results
      return el.name.toLowerCase().indexOf(searchValue) > -1 || el.description.toLowerCase().indexOf(searchValue) > -1
    });

    setsearchArtists(artistsResults == "" ? false : artistsResults)
  }

  return (
    <div>

      <header className="header">
        <div className="bg-color-scheme">
          {props.myaccount && props.myaccount.name && window.web3 ? (
            <div className="container-fluid px-md-5 text-light text-center">Welcome <strong>{props.myaccount.name}</strong></div>
          ) : (
            <React.Fragment>
              {window.web3 ? (
                <div className="container-fluid px-md-5 text-light text-center">Please connect your wallet</div>
              ) : (
                !props.noProvider ? (
                  <div className="container-fluid px-md-5 text-light text-center">Welcome! Please click this <Link className="fw-bold text-light" to="/signup">link</Link> to sign up!</div>
                ) : (
                  <div className="container-fluid px-md-5 text-light text-center">Please install metamask!</div>
                )

              )}
            </React.Fragment>
          )}

        </div>
        <div className="header__content">
          <div className="header__logo">
            <a href="/" className="text-white" style={{ color: '#222222' }}>
              {/*<span className="Logo-styled">Outgive.ca</span>*/}
              <span className="Logo-styled">Dijaux</span>
            </a>
          </div>

          <form action="#" className={"header__search " + (togglesearch == true ? "header__search--active" : "")}>
            <input type="text" id="main-searchbar" placeholder="Search items, collections, and creators" onChange={searchAllSite} autoComplete='off' />
            <button type="button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" /></svg></button>
            <button type="button" className="close" onClick={openSearch}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" /></svg></button>

            <div className={searchLoad ? 'search-results-floating show' : 'search-results-floating'} >
              <h5>NFTs</h5>
              <hr />
              {!searchArts ? (
                <React.Fragment>
                  No results found in NFT...
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {searchArts.map((val, i) => (
                    <div key={i}>
                      <a href={"/nft/" + val.tokenid + "/" + val.currency} className="d-flex text-dark">
                        <div className="img-container mr-3">

                          {['mp3', 'wav', 'ogg'].includes(val.image.split('.').pop()) ? (
                            <React.Fragment>
                              <img src={Discplaying} alt="" width="50" height="50" />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {['mp4', 'ogv', 'webm', 'avi', 'mov'].includes(val.image.split('.').pop()) ? (
                                <React.Fragment>
                                  <video width="50" height="50" className="search-videos" autoPlay loop muted playsInline>
                                    <source src={val.image} type={"video/" + val.image.split('.').pop()} />
                                    Your browser does not support the video tag.
                                  </video>
                                </React.Fragment>
                              ) : (
                                <img src={val.image} width="50" height="50" className="rounded-3" />
                              )}
                            </React.Fragment>
                          )}
                        </div>
                        <div className="information">
                          <strong>{val.name}</strong>
                          <p><small>{val.description.substring(0, 40) + "..."}</small></p>
                        </div>
                      </a>
                    </div>
                  ))}
                </React.Fragment>
              )}
              <hr />
              <h5>Artists</h5>
              <hr />
              {!searchArtists ? (
                <React.Fragment>
                  No results found in NFT...
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {searchArtists.map((val, i) => (
                    <div key={i}>
                      <a href={"/profile?address=" + val.address} className="d-flex text-dark">
                        <div className="img-container mr-3">
                          <img src={val.picture} width="50" height="50" className="rounded-3" />
                        </div>
                        <div className="information">
                          <strong>{val.name}</strong>
                          <p><small>{val.description.substring(0, 40) + "..."}</small></p>
                        </div>
                      </a>
                    </div>
                  ))}
                </React.Fragment>
              )}
            </div>
          </form>

          <div className={"header__menu " + (toggleheader == true ? "header__menu--active" : "")}>
            <ul className="header__nav">
              <li className="header__nav-item">
                <a className="header__nav-link" href="/">Home</a>
              </li>
              <li className="header__nav-item">
                <a className="header__nav-link" href="/discover">Explore</a>
              </li>
              <li className="header__nav-item">
                <a className="header__nav-link" href="/artists">Creators</a>
              </li>
              <li className="header__nav-item">
                <a className="header__nav-link" href="/activities">Activity</a>
              </li>
              {!props.noProvider ? (
                <li className="header__nav-item mobile-view">
                  <a className="header__nav-link" href="/mint"><strong>MINT</strong></a>
                </li>
              ) : null}
              <li className="header__nav-item">
                <a className="header__nav-link" href="https://dijaux.com">NFT Showcase</a>
              </li>
            </ul>
          </div>

          <div className="header__actions">
            <div className="header__action header__action--search" onClick={openSearch}>
              <button className="header__action-btn" type="button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" /></svg></button>
            </div>
            {!props.noProvider ? (
              <div className="header__action w-100 desktop-view">
                <a className="home__btn home__btn--clr m-0 rainbow-animated" href="/mint"><strong>MINT NFT</strong></a>
              </div>
            ) : null}

            <div className="header__action header__action--profile">
              {props.myaccount && props.myaccount.name && props.metamaskConnected === true ? (
                <a href="#/" className="header__profile-btn header__profile-btn--verified" onClick={openProfiledropdown}>
                  <img src={props.myaccount.picture} alt="" />
                  <div>
                    <p>{props.myaccount.name}</p>
                    <span>{props.ethBalance} {props.currency}</span>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path></svg>
                </a>
              ) : (
                <React.Fragment>
                  {props.metamaskConnected === false && window.web3 ? (
                    <a href="#/" className="home__btn home__btn--clr m-0 rainbow-animated" onClick={() => { props.connectWallet() }}>Connect Wallet</a>
                  ) : (
                    !props.noProvider ? (
                      <a href="/signup" className="home__btn home__btn--clr m-0">Sign up</a>
                    ) : (
                      <div></div>
                    )

                  )}
                </React.Fragment>
              )}
              <ul className={"dropdown-menu header__profile-menu " + (toggleMenu == true ? "show" : "")} aria-labelledby="dropdownMenuProfile">
                <li><a href="/profile"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z" /></svg> <span>Profile</span></a></li>
                <li><a href="/profile?activity=yes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3.71,16.29a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21,1,1,0,0,0-.21.33,1,1,0,0,0,.21,1.09,1.15,1.15,0,0,0,.33.21.94.94,0,0,0,.76,0,1.15,1.15,0,0,0,.33-.21,1,1,0,0,0,.21-1.09A1,1,0,0,0,3.71,16.29ZM7,8H21a1,1,0,0,0,0-2H7A1,1,0,0,0,7,8ZM3.71,11.29a1,1,0,0,0-1.09-.21,1.15,1.15,0,0,0-.33.21,1,1,0,0,0-.21.33.94.94,0,0,0,0,.76,1.15,1.15,0,0,0,.21.33,1.15,1.15,0,0,0,.33.21.94.94,0,0,0,.76,0,1.15,1.15,0,0,0,.33-.21,1.15,1.15,0,0,0,.21-.33.94.94,0,0,0,0-.76A1,1,0,0,0,3.71,11.29ZM21,11H7a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2ZM3.71,6.29a1,1,0,0,0-.33-.21,1,1,0,0,0-1.09.21,1.15,1.15,0,0,0-.21.33.94.94,0,0,0,0,.76,1.15,1.15,0,0,0,.21.33,1.15,1.15,0,0,0,.33.21,1,1,0,0,0,1.09-.21,1.15,1.15,0,0,0,.21-.33.94.94,0,0,0,0-.76A1.15,1.15,0,0,0,3.71,6.29ZM21,16H7a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z" /></svg> <span>Activity</span></a></li>
                <li><a href="/mint"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10,13H4a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V14A1,1,0,0,0,10,13ZM9,19H5V15H9ZM20,3H14a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,20,3ZM19,9H15V5h4Zm1,7H18V14a1,1,0,0,0-2,0v2H14a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V18h2a1,1,0,0,0,0-2ZM10,3H4A1,1,0,0,0,3,4v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,10,3ZM9,9H5V5H9Z" /></svg> <span>Add Asset</span></a></li>
                <li><a href="/profile?setting=yes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.9,12.66a1,1,0,0,1,0-1.32L21.18,9.9a1,1,0,0,0,.12-1.17l-2-3.46a1,1,0,0,0-1.07-.48l-1.88.38a1,1,0,0,1-1.15-.66l-.61-1.83A1,1,0,0,0,13.64,2h-4a1,1,0,0,0-1,.68L8.08,4.51a1,1,0,0,1-1.15.66L5,4.79A1,1,0,0,0,4,5.27L2,8.73A1,1,0,0,0,2.1,9.9l1.27,1.44a1,1,0,0,1,0,1.32L2.1,14.1A1,1,0,0,0,2,15.27l2,3.46a1,1,0,0,0,1.07.48l1.88-.38a1,1,0,0,1,1.15.66l.61,1.83a1,1,0,0,0,1,.68h4a1,1,0,0,0,.95-.68l.61-1.83a1,1,0,0,1,1.15-.66l1.88.38a1,1,0,0,0,1.07-.48l2-3.46a1,1,0,0,0-.12-1.17ZM18.41,14l.8.9-1.28,2.22-1.18-.24a3,3,0,0,0-3.45,2L12.92,20H10.36L10,18.86a3,3,0,0,0-3.45-2l-1.18.24L4.07,14.89l.8-.9a3,3,0,0,0,0-4l-.8-.9L5.35,6.89l1.18.24a3,3,0,0,0,3.45-2L10.36,4h2.56l.38,1.14a3,3,0,0,0,3.45,2l1.18-.24,1.28,2.22-.8.9A3,3,0,0,0,18.41,14ZM11.64,8a4,4,0,1,0,4,4A4,4,0,0,0,11.64,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,11.64,14Z" /></svg> <span>Settings</span></a></li>
              </ul>
            </div>
          </div>
          <button className={"header__btn " + (toggleheader == true ? "header__btn--active" : "")} type="button" onClick={openheaderdropdown}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        {props.myaccount && props.myaccount.confirmed === false ? (
          <div className="bg-danger">
            <div className="container-fluid px-md-5 text-light text-center">Please confirm your email! <button className="text-light" onClick={() => { resendConfirmation(props.myaccount.email) }}><u>Re send confirmation email</u></button></div>
          </div>
        ) : null}
      </header>

    </div>
  );
}

export default Header;
