import React, { useState } from 'react';
import { create } from 'ipfs-http-client'
import AWS from "aws-sdk";
import convert from 'image-file-resize';
import convertETH from 'ether-converter';
import axios from 'axios'
import { alertPopup, emailFormatting, trimFileName } from '../../utils'

import MintForm from './mintform';
import { ethers } from "ethers";
const projectId = '2LfRokppU2UTp9kuefJX1kiJmQA';
const projectSecret = 'bd267e7d39b26f03c5e0b51cae9f2ef0';
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');
const client = create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    apiPath: '/api/v0',
    headers: {
        authorization: auth
    }
})
const s3 = new AWS.S3({
    endpoint: 'nyc3.digitaloceanspaces.com',
    accessKeyId: 'YGH6JB5BESBUDFMJP63D',
    secretAccessKey: '15BQpZMLAsNwS4ala5Bva1SvtX63fU0sXVBBrUqIQt0'
});

export function Create(props) {

    const [extraAuction, setextraauction] = useState(false);

    const [NFTFile, setNFTFile] = useState('');
    const [name, setname] = useState('');
    const [price, setprice] = useState('');
    const [loading, setloading] = useState('');
    const [description, setdescription] = useState('');
    const [royalties, setRoyalties] = useState(0);
    const [forsaleval, setForsaleval] = useState(true);
    const [UnlockableFile, setunlockablefile] = useState('');
    const [TypeofListingVal, setTypeofListingVal] = useState('fixed');
    const [category, setcategory] = useState('');

    const { ethereum } = window;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

    // For auction
    //const [ auctiontime, setAuctionTime ] = useState(5);
    //const [ endTime, setendTime ] = useState('');

    const updateNFTFile = (event) => {

        const accepted = ['mp3', 'wav', 'mp4', 'ogg', 'webm', 'mov', 'png', 'jpg', 'jpeg', 'gif'];
        if (accepted.includes(event.target.files[0].name.split('.').pop()) && ((event.target.files[0].size / 1024 / 1024) < 10)) {
            setNFTFile(event.target.files[0]);
            document.getElementById('file1').innerHTML = event.target.files[0].name;
        } else {
            alertPopup('wrongformat');
        }
    }

    const updatename = (event) => {
        setname(event.target.value);

    }

    const updateRoyalties = (event) => {
        setRoyalties(event.target.value);
    }

    //const updateAuctionTime = (event) => {
    //    setAuctionTime(parseInt(event.target.value));
    //}

    const updatedescription = (event) => {
        setdescription(event.target.value);
    }

    const TypeOfListings = (val) => {
        setTypeofListingVal(val)

        if (val == "fixed") {
            setForsaleval(true);
            setextraauction(false);
        } else if (val == "reserved") {
            setForsaleval(false);
            setextraauction(false);
        } else {
            setForsaleval(true);
            setextraauction(true);
        }
    }

    const updateunlockablefile = (event) => {
        setunlockablefile(event.target.value);
    }

    const handleCategory = (event) => {
        var selectArray = []; //initialise empty array
        const form = document.getElementById('mint-nft');

        for (var i = 0; i < form.category_nft.length; i++) {
            if (form.category_nft[i].type == 'checkbox') {
                if (form.category_nft[i].checked == true) {
                    selectArray.push(form.category_nft[i].value.replace(/\s+/g, '').toLowerCase());
                }
            }
        }

        setcategory(selectArray.join(','));
    }

    const submit = async (event) => {
        const otherFormats = ['mp3', 'wav', 'mp4', 'ogg', 'webm', 'mov', 'gif'];
        if (price == "" || name == "" || category == "" || NFTFile == "" || description == "") {
            alertPopup('mintrequired');
        } else {

            const self = props;
            setloading(true);
            const BaseURL = process.env.PUBLIC_URL || props.BaseUrl;
            const NFTname = name;
            const NFTdescription = description;
            const NFTprice = price;
            event.preventDefault();

            // If Video or GIF, don't compress
            /***if (otherFormats.includes(NFTFile.name.split('.').pop())) {
                // Add a file to a Space
                var NFTParams = {
                    Body: NFTFile,
                    Bucket: "dijauxnft",
                    Key: "nft/" + trimFileName(NFTFile.name),
                    ACL: 'public-read'
                };

                s3.putObject(NFTParams, function (err, data) {
                    if (err) {
                        console.log(err, err.stack);
                    } else {
                        console.log("successfully added NFT file")
                    }
                });
            }
            // If only image then process the compression
            else {
                let img = new Image()
                let heightCalc = 500;
                img.src = window.URL.createObjectURL(NFTFile)
                img.onload = () => {
                    var percentage = (500 / img.width);
                    heightCalc = Math.round(img.height * percentage);
                    convert({
                        file: NFTFile,
                        width: 500,
                        height: heightCalc,
                        type: NFTFile.name.split('.').pop()
                    }).then(resp => {
                        // Add a file to a Space
                        var NFTParams = {
                            Body: resp,
                            Bucket: "dijauxnft",
                            Key: "nft/" + trimFileName(resp.name),
                            ACL: 'public-read'
                        };

                        s3.putObject(NFTParams, function (err, data) {
                            if (err) {
                                console.log(err, err.stack);
                            } else {
                                console.log("successfully added NFT file")
                            }
                        });

                    }).catch(error => {
                        console.log("error");
                    })
                }
            }***/

            alertPopup('mintprocess');

            try {
                await client.add(
                    {
                        path: trimFileName(NFTFile.name), // a string for our desired path, including the filename
                        content: NFTFile // in our case, a File object
                    }).then(async (res) => {

                        const ImageUrl = 'ipfs://' + res.cid.toString(); // Done generating ipfs image
                        // Start adding metadata
                        const metaDataNFT = JSON.stringify({
                            "name": NFTname,
                            "description": NFTdescription,
                            "image": ImageUrl
                        });

                        await client.add( // Process adding the metadata.json
                            {
                                path: 'metadata.json',
                                content: metaDataNFT
                            }).then(async (resMeta) => {

                                const mintTokenUri = resMeta.cid.toString(); // Done generating ipfs meta data Token Uri
                                
                                await props.contract.methods.MintNFT(mintTokenUri, convertETH(NFTprice, 'ether', 'wei'), royalties, forsaleval).estimateGas({ from: props.account }, function (error, gasAmount) {
                                    var GasfeewithAllowance = Math.round(gasAmount * 1.15);
                                    alertPopup('gottransaction');
                                    // Last step to push this in smart contract
                                    props.contract.methods.MintNFT(mintTokenUri, convertETH(NFTprice, 'ether', 'wei'), royalties, forsaleval).send({ from: props.account, gas: GasfeewithAllowance, gasPrice: props.estimateGasPrice, maxPriorityFeePerGas: null, maxFeePerGas: null })
                                        .on('transactionHash', async function (hash) {
                                            
                                            alertPopup('gottransaction');

                                        }).on('confirmation', async function (confirmationNumber, receipt) {

                                            if(confirmationNumber == 0) {

                                                // After minting lazy mint, then update the new token from counter
                                                var newTokenId = await props.contract.methods.dijauxNFTCounter().call();

                                                // Finally after transaction, set NFT DB data to modified = true
                                                axios.post(props.APIUrl+"/nftupdate/" + newTokenId +"/"+ props.currency, { category: category }).then((res) => {
                                                    console.log("nft category updated")
                                                    
                                                    alertPopup("mintsuccess");

                                                    if (extraAuction) {
                                                        /*await props.contract.methods.approve(props.addressAuction, parseInt(counter) + 1).estimateGas({ from: props.account }, function(error, gasAmount){
                                                            var GasfeewithAllowance = Math.round(gasAmount* 1.15);
                                                            props.contract.methods.approve(props.addressAuction, parseInt(counter) + 1).send({ from: props.account, gas: GasfeewithAllowance, gasPrice: props.estimateGasPrice, maxPriorityFeePerGas: null, maxFeePerGas: null })
                                                            .on('transactionHash', async function(hash){
                                                                alertPopup('gottransaction');
            
                                                            }).on('confirmation', async function(confirmationNumber, receipt){ 
                                            
                                                                if(confirmationNumber == 0) {
            
                                                                alertPopup('approved');
            
                                                                    await self.contract.methods.CreateAuction(parseInt(counter) + 1,new Date().getTime() + (auctiontime*60*60*1000)).estimateGas({ from: self.account }, function(error, gasAmount){
                                                                        var GasfeewithAllowance = Math.round(gasAmount* 1.15);
                                                                        // Trigger create auction from auction contract
                                                                        self.contract.methods.CreateAuction(parseInt(counter) + 1,new Date().getTime() + (auctiontime*60*60*1000)).send({ from: self.account, gas: GasfeewithAllowance, gasPrice: props.estimateGasPrice, maxPriorityFeePerGas: null, maxFeePerGas: null })
                                                                        .on('transactionHash', async function(hash){
            
                                                                            const new_listed = {
                                                                                type: "listed",
                                                                                from: self.account,
                                                                                amount: convertETH(NFTprice,'ether','wei'),
                                                                                tokenid: parseInt(counter) + 1,
                                                                                currency: props.currency,
                                                                                time: new Date().getTime(),
                                                                                transactionhash: hash,
                                                                                active:false
                                                                            }
                                                                            
                                                                            const emailContent = {
                                                                                email: props.myaccount.email,
                                                                                subject: "Your NFT is now up for auction!",
                                                                                message: emailFormatting(
                                                                                            props.myaccount.name,
                                                                                            "Congrats, You just listed your NFT. Make sure to upload your work to Dijaux Showcase for more promotion. 🙌",
                                                                                            "https://dijaux.com/wp-content/uploads/2021/12/New_Listing_NFT_Email.png",
                                                                                            ""
                                                                                        )
                                                                            }
            
                                                                            // Send email notification to user
                                                                            axios.post(props.APIUrl+"/email", emailContent).then((res) => {
                                                                                console.log("email sent")
                                                                            });
            
                                                                            axios.post(props.APIUrl+"/activity/add", new_listed).then((res) => {
                                                                                console.log("activity added");
                                                                            });
            
                                                                            alertPopup('gottransaction');
            
                                                                        }).on('confirmation', async function(confirmationNumber, receipt){ 
                                                
                                                                            if(confirmationNumber == 0) {
            
                                                                                alertPopup('toauction', 'https://dijauxnfts.com');
                                                                                setloading(false);
                                                                            }
            
                                                                        }).on('error', function(error, receipt) { 
                                                                            alertPopup('error', error.code);
                                                                            setloading(false);
                                                                        });
                                                                    });
                                                                }
                                                            }).on('error', function(error, receipt) { 
                                            
                                                                alertPopup('error', error.code);
                                                                setloading(false);
            
                                                            });
                                                        }); */
                                                    } else {

                                                        alertPopup('mintlisted', 'https://dijauxnfts.com');
                                                        setloading(false);
                                                        window.location.href="/";    
                                                    }

                                                });
                                            }
                                        }).on('error', function (error, receipt) {

                                            alertPopup('error', error.code);
                                            setloading(false);

                                        });
                                });
                            });
                    })
            } catch (error) {
                console.log('Error uploading file: ', error)
            }
        }

    }

    const submitFREE = async (event) => {
        console.log("props.account", props.account)
        const otherFormats = ['mp3', 'wav', 'mp4', 'ogg', 'webm', 'mov', 'gif'];
        if (price == "" || name == "" || category == "" || NFTFile == "" || description == "") {
            alertPopup('mintrequired');
        } else {

            const self = props;
            setloading(true);
            const BaseURL = process.env.PUBLIC_URL || props.BaseUrl;
            const NFTname = name;
            const NFTdescription = description;
            const NFTprice = price;
            event.preventDefault();

            // If Video or GIF, don't compress
            /***if (otherFormats.includes(NFTFile.name.split('.').pop())) {
                // Add a file to a Space
                var NFTParams = {
                    Body: NFTFile,
                    Bucket: "dijauxnft",
                    Key: "nft/" + trimFileName(NFTFile.name),
                    ACL: 'public-read'
                };

                s3.putObject(NFTParams, function (err, data) {
                    if (err) {
                        console.log(err, err.stack);
                    } else {
                        console.log("successfully added NFT file")
                    }
                });
            }
            // If only image then process the compression
            else {
                let img = new Image()
                let heightCalc = 500;
                img.src = window.URL.createObjectURL(NFTFile)
                img.onload = () => {
                    var percentage = (500 / img.width);
                    heightCalc = Math.round(img.height * percentage);
                    convert({
                        file: NFTFile,
                        width: 500,
                        height: heightCalc,
                        type: NFTFile.name.split('.').pop()
                    }).then(resp => {
                        // Add a file to a Space
                        var NFTParams = {
                            Body: resp,
                            Bucket: "dijauxnft",
                            Key: "nft/" + trimFileName(resp.name),
                            ACL: 'public-read'
                        };

                        s3.putObject(NFTParams, function (err, data) {
                            if (err) {
                                console.log(err, err.stack);
                            } else {
                                console.log("successfully added NFT file")
                            }
                        });

                    }).catch(error => {
                        console.log("error");
                    })
                }
            }***/

            alertPopup('mintprocess');

            try {
                await client.add(
                    {
                        path: trimFileName(NFTFile.name), // a string for our desired path, including the filename
                        content: NFTFile // in our case, a File object
                    }).then(async (res) => {

                        const ImageUrl = 'ipfs://' + res.cid.toString(); // Done generating ipfs image
                        // Start adding metadata
                        const metaDataNFT = JSON.stringify({
                            "name": NFTname,
                            "description": NFTdescription,
                            "image": ImageUrl
                        });

                        await client.add( // Process adding the metadata.json
                            {
                                path: 'metadata.json',
                                content: metaDataNFT
                            }).then(async (resMeta) => {

                                const mintTokenUri = resMeta.cid.toString(); // Done generating ipfs meta data Token Uri

                                const types = {
                                    LazyMintVoucher: [
                                        { name: "tokenId", type: "uint256" },
                                        { name: "price", type: "uint256" },
                                        { name: "uri", type: "string" },
                                        { name: "royalties", type: "uint" },
                                        { name: "forsale", type: "bool" }
                                    ]
                                }

                                let lazymintId = Math.floor((Math.random() * 99999999) + 10000000);

                                const SIGNING_DOMAIN = "Lazy-Domain";
                                const SIGNING_VERSION = "1";
                                const domain = {
                                    name: SIGNING_DOMAIN,
                                    version: SIGNING_VERSION,
                                    verifyingContract: props.contract._address,
                                    chainId: props.networkID
                                }
                                let voucher = {
                                    tokenId: lazymintId,
                                    price: convertETH(NFTprice, 'ether', 'wei'),
                                    uri: mintTokenUri,
                                    royalties: royalties,
                                    forsale: forsaleval
                                }

                                const signature = await signer._signTypedData(domain, types, voucher);

                                voucher["signature"] = signature;
                                console.log("signature", signature, voucher);

                                await axios.get(props.BaseIPFSUrl + mintTokenUri).then(async function (response) {

                                    const newnft = {
                                        tokenid: parseInt(voucher.tokenId),
                                        unlockable_link: "",
                                        category: category,
                                        last_auction: new Date().getTime(),
                                        created_datetime: new Date().getTime(),
                                        // New Datas from blockchain
                                        creator: props.account,
                                        owner: props.account,
                                        forsale: voucher.forsale,
                                        price: voucher.price,
                                        royalties: voucher.royalties,
                                        metadata: mintTokenUri,
                                        asset: response.data.image,
                                        image: props.BaseIPFSUrl + response.data.image.split("ipfs://")[1],
                                        networkid: props.networkID,
                                        currency: props.currency,
                                        // Metadata
                                        name: response.data.name,
                                        description: response.data.description,
                                        modified: false,
                                        modifieddate: new Date().getTime(),
                                        transactionhash: "",
                                        voucher: voucher

                                    };

                                    // Now add data to database
                                    await axios.post(props.APIUrl + "/nft/add", newnft).then(async (res) => {
                                        console.log("nft added");

                                        // Minted activity
                                        const new_mint = {
                                            type: "minted",
                                            from: props.account,
                                            amount: voucher.price,
                                            tokenid: parseInt(voucher.tokenId),
                                            currency: props.currency,
                                            time: new Date().getTime(),
                                            active: true
                                        }

                                        // Add activity
                                        await axios.post(props.APIUrl + "/activity/add", new_mint).then(async (res) => {
                                            console.log("activity added");
                                            let priceBought = convertETH(voucher.price, "wei", 'ether');
                                            const emailContent = {
                                                email: props.myaccount.email,
                                                subject: 'Congrats on Minting NFT ' + response.data.name + ' on Dijaux!',
                                                message: emailFormatting(
                                                    props.myaccount.name,
                                                    "Congrats, You just listed your NFT. Make sure to upload your work to Dijaux Showcase for more promotion. 🙌",
                                                    response.data.image,
                                                    `<div style="margin:10px 0;">
                                                <strong>Details:</strong>
                                                <br><br>
                                                <strong>Title:</strong> <u>`+ response.data.name + `</u>
                                                <br>
                                                <strong>Description:</strong> <u>`+ response.data.description + `</u>
                                                <br>
                                                <strong>Price:</strong> <u>`+ priceBought + ` `+props.currency+`</u>
                                                <br>
                                                <strong>Metadata:</strong> <u><a href="`+ props.BaseIPFSUrl + mintTokenUri + `">` + mintTokenUri + `</a></u>
                                                </div>`
                                                )
                                            }
                                            alertPopup("mintsuccess");
                                            // Send email notification to user
                                            await axios.post(props.APIUrl + "/email", emailContent).then(async (res) => {
                                                console.log("email sent")
                                                alertPopup('mintlisted', 'https://dijauxnfts.com');
                                                window.location.href="/nft/"+parseInt(voucher.tokenId)+"/"+props.currency;
                                            });
                                        });
                                    });

                                });


                            });
                    })
                alertPopup("mintsuccess");
            } catch (error) {
                console.log('Error uploading file: ', error)
            }
        }
    }

    return (
        <main className="main">
            {props.myaccount && props.myaccount.name ? (
                <div className="main__author" style={{
                    backgroundImage: `url(${props.myaccount.cover})`,
                    backgroundPosition: `center center`,
                    backgroundRepeat: `no-repeat`,
                    backgroundSize: `cover`
                }}></div>
            ) : null}
            <div className="container d-flex align-items-center justify-content-center">
                {props.contract ? (
                    <MintForm
                        {...props}
                        handleCategory={handleCategory}
                        updateNFTFile={updateNFTFile}
                        updatename={updatename}
                        updateunlockablefile={updateunlockablefile}
                        updatedescription={updatedescription}
                        updateRoyalties={updateRoyalties}
                        setprice={setprice}
                        TypeofListingVal={TypeofListingVal}
                        TypeOfListings={TypeOfListings}
                        extraAuction={extraAuction}
                        //updateAuctionTime={updateAuctionTime}
                        submit={submit}
                        submitFREE={submitFREE}
                        loading={loading}
                    />
                ) : (
                    <h1>Please switch to deployed network! Minting/Lazy minting are not allowed.</h1>
                )}
                
            </div>

        </main>
    );
} 