import React from 'react'
import Discplaying from '../../../main/assets/img/discplaying.gif'
import convertETH from 'ether-converter';
import { Likes } from '../../../actions'
import { checkNSFW, time2TimeAgo } from '../../../utils'

export default function Explore(props) {

  const arrayUniqueByKey = props.allArts.filter((e, i) => props.allArts.findIndex(a => a['creator'] === e['creator']) === i && e.forsale);

  return (
    <section className="row row--grid">
      <div className="col-12">
        <div className="main__title">
          <h2>
            <div>Explore <small>({props.arts.length})</small></div>
          </h2>
          <a className="home__btn home__btn--clr m-0" href="/discover">View all NFTs</a>
          <hr />
        </div>
      </div>
      {/* end title */}

      {/* carousel */}
      <div className="col-12">
        <div className="main__carousel-wrap">
          <div className="row row--grid">
            {arrayUniqueByKey.filter(cards => !props.auctionexists.includes(cards.tokenid.toString())).slice(0, 8).map((card, index) => (
              <div key={index} className={"col-12 col-sm-6 col-lg-4 col-xl-3"}>
                <div className={"card"}>
                  <a href={"/nft/" + card.tokenid + "/" + card.currency} className={"card__cover " + checkNSFW(card.category)}>

                    {['mp3', 'wav'].includes(card.image.split('.').pop()) ? (
                      <React.Fragment>
                        <div className="floating-text-loop">Audio</div>
                        <img src={Discplaying} alt="" width="260" height="300" />
                      </React.Fragment>

                    ) : (
                      <React.Fragment>
                        {['mp4', 'ogg', 'webm', 'mov'].includes(card.image.split('.').pop()) ? (
                          <React.Fragment>
                            <div className="floating-text-loop">Video</div>
                            <video autoPlay loop muted playsInline>
                              <source src={card.image} type={"video/" + card.image.split('.').pop()} />
                              Your browser does not support the video tag.
                            </video>
                          </React.Fragment>
                        ) : (
                          <img src={card.image} alt="" width="260" height="300" />
                        )}
                      </React.Fragment>
                    )}

                  </a>

                  <h3 className="card__title"><a href={"/nft/" + card.tokenid + "/" + card.currency}>{card.name}</a></h3>
                  <div className="card__author">
                    {props.creators.filter(creator => creator.address.toLowerCase() == card.owner.toLowerCase()).map((artist, index) => (
                      <div key={index} className="card__author card__author--verified">
                        <img src={artist.picture} alt="" />
                        <a href={"/profile?address=" + artist.address}>{artist.name}</a>
                      </div>
                    ))}
                  </div>

                  <div className="card__info">
                    <div className="card__price">
                      <span>{card.forsale ? "Fixed Price" : "Reserved Price"}</span>
                      <span>{convertETH(card.price, 'wei', 'ether')} {card.currency} <small className="text-secondary fw-bold">(${props.convertUSD(convertETH(card.price, 'wei', 'ether'), card.currency)})</small></span>
                    </div>

                    {props.metamaskConnected && !props.noProvider ? (
                      <div className="d-flex flex-column justify-content-end">
                        <span className="activity__time">{time2TimeAgo(Math.round(card.created_datetime / 1000))}</span>
                        <button className="card__likes" type="button" onClick={(e) => Likes(e, card.tokenid, card.currency, props.allLikes.some(r => r.tokenid == card.tokenid && r.address == props.account && r.currency == card.currency) ? "delete" : "add", props.account, props.APIUrl, props.setLikes)}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            {props.allLikes.some(r => r.tokenid == card.tokenid && r.address == props.account && r.currency == card.currency) ? (
                              <path d="M 20.16 5 A 6.29 6.29 0 0 0 12 4.36 a 6.27 6.27 0 0 0 -8.16 9.48 l 6.21 6.22 a 2.78 2.78 0 0 0 3.9 0 l 6.21 -6.22 A 6.27 6.27 0 0 0 20.16 5 Z Z" />
                            ) : (
                              <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z" />
                            )}
                          </svg>
                          <span>{props.allLikes.filter(r => r.tokenid == card.tokenid && r.currency == card.currency).length}</span>
                        </button>
                      </div>

                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* end carousel */}
    </section>
  );
}