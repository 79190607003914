import FontAwesome from 'react-fontawesome'

export default function Settings(props) {
    return (
        <div className="row row--grid">
            {/* details form */}
            <div className="col-12 col-lg-12">
            <div action="#" className="sign__form sign__form--profile">
                <div className="row">
                <div className="col-12">
                    <h4 className="sign__title">Profile details</h4>
                </div>

                <div className="col-12 col-md-6 col-lg-12 col-xl-6">
                <div className="sign__file">
                    <label id="profilePicture" className="sign__file-upload" htmlFor="DijauxProfilePicture">(Click to change)</label>
                    <input 
                    data-name="#profilePicture"
                    className="sign__file-upload"
                    accept="video/mp4,video/x-m4v,video/*,.png,.jpg,.jpeg"
                    type="file" 
                    name="DijauxProfilePicture"
                    id="DijauxProfilePicture" 
                    onChange={props.changeHandlerProfilePic} 
                    />
                </div>
                </div>

                <div className="col-12 col-md-6 col-lg-12 col-xl-6">
                <div className="sign__file">
                    <label id="coverPhoto" className="sign__file-upload" htmlFor="DijauxCoverPhoto">(Click to change)</label>
                    <input 
                    data-name="#coverPhoto"
                    className="sign__file-upload"
                    accept="video/mp4,video/x-m4v,video/*,.png,.jpg,.jpeg"
                    type="file" 
                    name="DijauxCoverPhoto"
                    id="DijauxCoverPhoto" 
                    onChange={props.changeHandlerCover} 
                    />
                </div>
                </div>

                <div className="col-12">
                    <div className="sign__group">
                    <label className="sign__label" htmlFor="DijauxName">Display Name</label>
                    <input 
                    type="text" 
                    className="sign__input" 
                    name="DijauxName"
                    id="DijauxName" 
                    placeholder={props.DijauxName}
                    onChange={props.updateFieldSignupName}
                    />
                    </div>
                </div>
                
                <div className="col-12">
                    <div className="sign__group">
                    <label className="sign__label" htmlFor="Email">Email</label>
                    <input 
                    type="text" 
                    className="sign__input" 
                    name="Email"
                    id="Email" 
                    placeholder={props.Email}
                    onChange={props.updateEmailAddress}
                    />
                    </div>
                </div>

                <div className="col-12">
                    <div className="sign__group">
                    <label className="sign__label" htmlFor="DijauxUserDescription">About Me</label>
                    <textarea 
                    className="sign__textarea" 
                    id="DijauxUserDescription" 
                    name="DijauxUserDescription"
                    rows="3"
                    placeholder={props.DijauxUserDescription}
                    value={props.DijauxUserDescription}
                    onChange={props.updateFieldSignupDescription}
                    ></textarea>
                    </div>
                </div>

                <div className="col-12">
                    <h4 className="sign__title">Social profiles</h4>
                </div>

                <div className="col-12 col-md-12">
                    <label>Facebook</label>
                    <div className="sign__group">
                        <input 
                        type="url" 
                        className="sign__input" 
                        name="SocialFacebook"
                        id="SocialFacebook" 
                        placeholder="Facebook URL" 
                        value={props.SocialFacebook}
                        onChange={props.updateSocialFacebook}
                        />
                    </div>
                </div>

                <div className="col-12 col-md-12">
                    <label>Twitter</label>
                    <div className="sign__group">
                        <input 
                        type="url" 
                        className="sign__input" 
                        name="SocialTwitter"
                        id="SocialTwitter" 
                        placeholder="Twitter URL" 
                        value={props.SocialTwitter}
                        onChange={props.updateSocialTwitter}
                        />
                    </div>
                </div>
                
                <div className="col-12 col-md-12">
                    <label>Instagram</label>
                    <div className="sign__group">
                        <input 
                        type="url" 
                        className="sign__input" 
                        name="SocialInstagram"
                        id="SocialInstagram" 
                        placeholder="Instagram URL" 
                        value={props.SocialInstagram}
                        onChange={props.updateSocialInstagram}
                        />
                    </div>
                </div>

                <div className="col-12">
                <button 
                className={"sign__btn" + (props.Loading ? " disabled" : "")}
                onClick={props.EditProfileSubmimt}>
                {props.Loading ? 
                    <div><FontAwesome spin name="circle-o-notch" /> Please wait...</div>
                :
                    <div>Update Changes</div>
                }
                </button>
                    
                </div>
                </div>
            </div>
            </div>
            {/* end details form */}
        </div>
    );
}