import Modal from 'react-modal';
import FontAwesome from 'react-fontawesome'

Modal.setAppElement('#root');

export default function ModalActions(props) {
    return (
        <Modal
            isOpen={props.PriceModal}
            onRequestClose={props.closeModalPrice}
            className="modal modal--form"
            >
                <button className="modal__close" type="button" onClick={props.closeModalPrice}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg></button>

                <h4 className="sign__title">Change your NFT price</h4>

                <div className="sign__group sign__group--row">
                <label className="sign__label" htmlFor="newprice">New price</label>
                <input id="newprice" type="text" name="newprice" className="sign__input" onChange={(e) => {props.setnewNFTprice(e.target.value)}} />

                <span className="sign__text sign__text--small">Update your new price for your NFT asset</span>
                </div>

                <button 
                className={"sign__btn " + (props.loading ? "disable" : "")}
                type="button" 
                onClick={() => {props.changeTokenPrice(props, props.artwork)}}>
                {props.loading ? (
                    <div>
                        <FontAwesome spin name="circle-o-notch" /> Loading...
                    </div>
                ) : (
                    <div>Update</div>
                )}
                </button>
        </Modal>
        
    /*
    <Modal
      isOpen={BidModal}
      onRequestClose={closeModalBid}
      className="modal modal--form"
      >
        <button className="modal__close" type="button" onClick={closeModalBid}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg></button>

        <h4 className="sign__title">Place a bid</h4>

        <div className="sign__group sign__group--row">
          <label className="sign__label" htmlFor=" placebid">Your bid</label>
          <input id="placebid" type="text" name="placebid" className="sign__input" onChange={(e) => {UpdateBidPrice(e.target.value)}} />

          <span className="sign__text sign__text--small">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</span>
        </div>

        <button 
        className={"sign__btn " + (props.loading ? "disable" : "")} 
        type="button"
        onClick={() => {bidAuction(props.artwork)}}>
        {props.loading ? (
            <div>
                <FontAwesome spin name="circle-o-notch" /> Bidding...
            </div>
        ) : (
            <div>Place Bid</div>
        )}
        </button>
      </Modal>
      
      <Modal
      isOpen={AuctionModal}
      onRequestClose={closeModalAuction}
      className="modal modal--form"
      >
        <button className="modal__close" type="button" onClick={closeModalAuction}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg></button>
        <h4 className="sign__title">Choose auction time</h4>
        <div className="col-12">
          <div className="sign__group">
              <label className="sign__label" htmlFor="royalties">Select hours</label>
              <select id="royalties" name="royalties" className="sign__select" onChange={updateAuctionTime}>
                  <option value="1">1 Hour</option>
                  <option value="2">2 Hours</option>
                  <option value="3">3 Hours</option>
                  <option value="4">4 Hours</option>
                  <option value="5" defaultValue>5 Hours</option>
                  <option value="6">6 Hours</option>
                  <option value="7">7 Hours</option>
                  <option value="8">8 Hours</option>
                  <option value="9">9 Hours</option>
                  <option value="10">10 Hours</option>
                  <option value="11">11 Hours</option>
                  <option value="12">12 Hours</option>
                  <option value="13">13 Hours</option>
                  <option value="14">14 Hours</option>
                  <option value="15">15 Hours</option>
                  <option value="16">16 Hours</option>
                  <option value="17">17 Hours</option>
                  <option value="18">18 Hours</option>
                  <option value="19">19 Hours</option>
                  <option value="20">20 Hours</option>
                  <option value="21">21 Hours</option>
                  <option value="22">22 Hours</option>
                  <option value="23">23 Hours</option>
                  <option value="24">24 Hours</option>
              </select>
              <span className="sign__text sign__text--small">You have 3 days interval for every listing for this NFT</span>
            </div>
        </div>

        <button 
        className={"sign__btn " + (props.loading ? "disable" : "")} 
        type="button" 
        onClick={() => {AddToAuction(startPrice)}}>
        {props.loading ? (
            <div>
                <FontAwesome spin name="circle-o-notch" /> Loading...
            </div>
        ) : (
            <div>Update</div>
        )}
        </button>
        </Modal>
      */
    );
}