function Footer(props) {

    return (
        <div>
          <footer className="footer">
            <div className="container-fluid px-md-5">
              <div className="row">
                <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4 order-4 order-md-1 order-lg-4 order-xl-1">
                  <div className="footer__logo">
                    <span className="Logo-styled white">Dijaux</span>
                  </div> 
                  <p className="footer__tagline">Digital marketplace for crypto collectibles <br />and non-fungible tokens (NFTs). <br />Buy, sell, and discover exclusive digital assets. <br/>Please contact us at support@dijaux.com<br/> if you encountered some issues.</p>

                </div>

                <div className="col-6 col-md-4 col-lg-3 col-xl-2 order-1 order-md-2 order-lg-1 order-xl-2 offset-md-2 offset-lg-0">
                  <h6 className="footer__title">NTF Marketplace</h6>
                  <div className="footer__nav">
                    <a href="/discover">Explore</a>
                    <a href="/activities">Activity</a>
                    <a href="/artists">Creators</a>
                    <a href="https://dijaux.com/privacy-policy/">Privacy Policy</a>
                    <a href="https://dijaux.com/contact-us/">Contact Us</a>
                  </div>
                </div>

                <div className="col-12 col-md-8 col-lg-6 col-xl-4 order-3 order-lg-2 order-md-3 order-xl-3">
                  <div className="row">
                    <div className="col-12">
                      <h6 className="footer__title">Explore</h6>
                    </div>

                    <div className="col-6">
                      <div className="footer__nav">
                        <a href="https://dijaux.com/nft-category/3d-arts/">3D Art</a>
                        <a href="https://dijaux.com/nft-category/audio-music/">Audio/Music</a>
                        <a href="https://dijaux.com/nft-category/cards/">Cards</a>
                        <a href="https://dijaux.com/nft-category/collectibles/">Collectibles</a>
                        <a href="https://dijaux.com/nft-category/virtual-worlds/">Virtual Worlds</a>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="footer__nav">
                        <a href="https://dijaux.com/nft-category/digital-art/">Digital Art</a>
                        <a href="https://dijaux.com/nft-category/lands/">Lands</a>
                        <a href="https://dijaux.com/nft-category/nsfw/">NSFW</a>
                        <a href="https://dijaux.com/nft-category/original-art/">Original Art</a>
                        <a href="https://dijaux.com/nft-category/video/">Video</a>
                        
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-4 col-lg-3 col-xl-2 order-2 order-lg-3 order-md-4 order-xl-4">
                  <h6 className="footer__title">Community</h6>
                  <div className="footer__nav">
                    <a href="https://dijaux.com/about-us/">About us</a>
                    <a href="https://dijaux.com/">Visit Showcase Site</a>
                    <a href="https://dijaux.com/faqs/">FAQs</a>
                    <a href="https://discord.gg/SBJVcPVN">Join Discord</a>
                    <a href="/signup">Sign up</a>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="footer__content">
                    <div className="footer__social">
                      <a href="https://www.facebook.com/Dijaux.nfts/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.9,2H3.1A1.1,1.1,0,0,0,2,3.1V20.9A1.1,1.1,0,0,0,3.1,22h9.58V14.25h-2.6v-3h2.6V9a3.64,3.64,0,0,1,3.88-4,20.26,20.26,0,0,1,2.33.12v2.7H17.3c-1.26,0-1.5.6-1.5,1.47v1.93h3l-.39,3H15.8V22h5.1A1.1,1.1,0,0,0,22,20.9V3.1A1.1,1.1,0,0,0,20.9,2Z"/></svg></a>

                      <a href="https://www.instagram.com/dijaux_nft/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.34,5.46h0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Zm4.6,2.42a7.59,7.59,0,0,0-.46-2.43,4.94,4.94,0,0,0-1.16-1.77,4.7,4.7,0,0,0-1.77-1.15,7.3,7.3,0,0,0-2.43-.47C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.3,7.3,0,0,0-2.43.47A4.78,4.78,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.78,4.78,0,0,0,1.77,1.15,7.3,7.3,0,0,0,2.43.47C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.3,7.3,0,0,0,2.43-.47,4.7,4.7,0,0,0,1.77-1.15,4.85,4.85,0,0,0,1.16-1.77,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.06,3.06,0,0,1-.75,1.15,3.19,3.19,0,0,1-1.15.75,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.73,5.73,0,0,1,6.1,19.8,3.27,3.27,0,0,1,5,19.05a3,3,0,0,1-.74-1.15A5.54,5.54,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.54,5.54,0,0,1,4.21,6.1,3,3,0,0,1,5,5,3.14,3.14,0,0,1,6.1,4.2,5.73,5.73,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34A3.06,3.06,0,0,1,19.05,5,3.06,3.06,0,0,1,19.8,6.1,5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16ZM12,6.87A5.13,5.13,0,1,0,17.14,12,5.12,5.12,0,0,0,12,6.87Zm0,8.46A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z"/></svg></a>

                      <a href="https://twitter.com/Dijaux_nft" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z"/></svg></a>
                    </div>

                    <small className="footer__copyright">© Dijaux NFT Marketplace, 2022.</small>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
    );
}

export default Footer;
