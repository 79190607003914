import React from 'react'
import Discplaying from '../../../main/assets/img/discplaying.gif'
import convertETH from 'ether-converter';
import { Likes } from '../../../actions'
import { checkNSFW, time2TimeAgo } from '../../../utils'

export default function Hero(props) {
    
  return (
    <div className="home">
        <div className="container-fluid p-5">
        <div className="row">
            <div className="col-md-6">
            <div className="home__content">
                <strong className="mb-3">MINT | BUY | SELL | CONNECT</strong>
                <h1 className="home__title">NFT Marketplace & NFT Showcase</h1>
                <p className="home__text">Dijaux Looks forward to sharing your creativity <br/> to the world of NFT Collectors</p>
                <strong className="mt-3">Polygon Mainnet and lazy minting is now available.</strong>
                <a href="https://medium.com/prepo/setting-up-metamask-and-getting-eth-matic-on-polygon-step-by-step-guide-fd55147a0f05" target="_blank" className="mt-3 text-info"><u>How to add polygon on metamask?</u></a>
                {/*<h1 className="home__title">Outgive Practice node</h1>
                <p className="home__text">This is a practice for mongo db node js</p>*/}
                <div className="home__btns">
                <a href="/discover" className="home__btn home__btn--clr">Explore</a>
                {!props.noProvider ? (
                    <a href="/mint" className="home__btn">Mint</a>
                ) : null}
                <a href="https://dijauxnfts.com/signup" className="home__btn">Sign Up Showcase</a>
                </div>
            </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center">
                {props.NFTcount > 0 ? (
                    props.allArts.filter(cards => cards.tokenid == props.NFTcount).map((card, index) => ( 
                        <div key={index} className="col-lg-7 col-md-10">
                            <div className="card">
                                <a href={"/nft/" + card.tokenid + "/" + card.currency} className={"card__cover "+checkNSFW(card.category)}>

                                {['mp3', 'wav'].includes(card.image.split('.').pop()) ? (
                                    <React.Fragment>
                                    <div className="floating-text-loop">Audio</div>
                                    <img src={Discplaying} alt="" width="260" height="300"  />
                                    </React.Fragment>
                                    
                                ) : (
                                    <React.Fragment>
                                    {['mp4', 'ogg', 'webm', 'mov'].includes(card.image.split('.').pop()) ? (
                                        <React.Fragment>
                                            <div className="floating-text-loop">Video</div>
                                            <video autoPlay loop muted playsInline>
                                            <source src={ card.image } type={"video/" + card.image.split('.').pop()} />
                                            Your browser does not support the video tag.
                                            </video>
                                        </React.Fragment>
                                    ) : (
                                        <img src={ card.image } alt="" width="260" height="300"  />
                                        
                                    )}
                                    </React.Fragment>
                                )}
                                    
                                </a>

                                <h3 className="card__title"><a href={"/nft/" + card.tokenid + "/" + card.currency}>{card.name}</a></h3>
                                    <div className="card__author">
                                    {props.creators.filter(creator => creator.address.toLowerCase() == card.owner.toLowerCase() ).map((artist, index) => ( 
                                        <div key={index} className="card__author card__author--verified">
                                        <img src={artist.picture} alt="" />
                                        <a href={"/profile?address=" + artist.address}>{artist.name}</a>
                                        </div>
                                    ))}
                                    </div>

                                <div className="card__info">
                                    <div className="card__price">
                                    <span>Fixed price</span>
                                    <span>{convertETH(card.price,'wei','ether')} {card.currency} <small className="text-secondary fw-bold">(${props.convertUSD(convertETH(card.price,'wei','ether'))})</small></span>
                                    </div>
                                    
                                    {props.metamaskConnected && !props.noProvider ? (
                                        <div className="d-flex flex-column justify-content-end">
                                            <span className="activity__time">{time2TimeAgo(Math.round(card.created_datetime / 1000))}</span>
                                            <button className="card__likes" type="button" onClick={(e) => Likes(e,card.tokenid, card.currency, props.allLikes.some(r => r.tokenid == card.tokenid && r.address == props.account && r.currency == card.currency) ? "delete" : "add", props.account, props.APIUrl, props.setLikes)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                {props.allLikes.some(r => r.tokenid == card.tokenid && r.address == props.account && r.currency == card.currency) ? (
                                                <path d="M 20.16 5 A 6.29 6.29 0 0 0 12 4.36 a 6.27 6.27 0 0 0 -8.16 9.48 l 6.21 6.22 a 2.78 2.78 0 0 0 3.9 0 l 6.21 -6.22 A 6.27 6.27 0 0 0 20.16 5 Z Z"/>
                                                ) : (
                                                <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z"/>
                                                )}
                                                </svg>
                                            <span>{props.allLikes.filter(r => r.tokenid == card.tokenid && r.currency == card.currency).length}</span>
                                            </button>
                                        </div>
                                ) : null}
                                </div>
                            </div>
                        </div>
                    ))
                ) :null}
                {/**<img src={Drops} className="img-fluid" />**/}
                
            </div>
        </div>
        </div>
    </div>
  );
}