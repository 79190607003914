import React, { useState, useEffect } from 'react'
import { sort } from 'fast-sort';
import convertETH from 'ether-converter';
import Explore from './explore'
import Filter from './filter'

export function Discover(props) {

    var url_string = window.location;
    var url = new URL(url_string);
    var categoryPreselect = (url.searchParams.get("cat") == null) ? null : url.searchParams.get("cat");

    const [filterToggle, setfilterToggle] = useState(false);
    const [allLikes, setLikes] = useState([]);
    const [priceVal, setValues] = useState([0, 10000]);
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const postsPerPage = 8;

    useEffect(()=>{
        setLikes(props.likes)

        let endOffset = itemOffset + postsPerPage;
        setCurrentItems(sort(props.arts).desc(u => u.modifieddate).slice(itemOffset, endOffset));
        setPageCount(Math.ceil(sort(props.arts).desc(u => u.modifieddate).length / postsPerPage));

        if(priceVal[0] > 0) {
            setCurrentItems(props.arts.filter(key => convertETH(key.price,'wei','ether') > priceVal[0] && convertETH(key.price,'wei','ether') < priceVal[1]).slice(itemOffset, endOffset));
            setPageCount(Math.ceil(props.arts.filter(key => convertETH(key.price,'wei','ether') > priceVal[0] && convertETH(key.price,'wei','ether') < priceVal[1]).length / postsPerPage));
        }

        if(categoryPreselect) {
            document.querySelector("input[value=sports]").setAttribute("checked", "checked");
            setCurrentItems(props.arts.filter(key => key.category.split(",").some(v => v == categoryPreselect)).slice(itemOffset, endOffset));
            setPageCount(Math.ceil(props.arts.filter(key => key.category.split(",").some(v => v == categoryPreselect)).length / postsPerPage));
        }

    }, [props.arts,props.likes, priceVal, itemOffset, 8])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * postsPerPage) % sort(props.arts).desc(u => u.modifieddate).length;
        setItemOffset(newOffset);
    };

    const applyFilter = async (e) => {

        const endOffset = itemOffset + postsPerPage;
        const filterVal = Array.from(document.querySelectorAll("input[type=checkbox][name=art-filter]:checked")).map(e => e.value)
        setCurrentItems(filterVal.length > 0 ? props.arts.filter(key => key.category.split(",").some(v => filterVal.includes(v))).slice(itemOffset, endOffset) : sort(props.arts).desc(u => u.modifieddate).slice(itemOffset, endOffset));
        setPageCount(filterVal.length > 0 ? Math.ceil(props.arts.filter(key => key.category.split(",").some(v => filterVal.includes(v))).length / postsPerPage) : Math.ceil(sort(props.arts).desc(u => u.modifieddate).length / postsPerPage));

    }

    const clearFilter = () => {
        document.querySelectorAll("input[type=checkbox][name=art-filter]:checked").forEach( el => el.checked = false );
        setCurrentItems(sort(props.arts).desc(u => u.modifieddate))
    }

    function toggleFilter() {
        setfilterToggle(filterToggle === true ? false : true);
    }

    const sortArts = (val) => {
        const endOffset = itemOffset + postsPerPage;
        if(val == "highest") {
            setCurrentItems(sort(props.arts.slice(itemOffset, endOffset)).desc(u => u.price))
        }else if(val == "lowest") {
            setCurrentItems(sort(props.arts.slice(itemOffset, endOffset)).asc(u => u.price))
        }else if(val == "latest") {
            setCurrentItems(sort(props.arts.slice(itemOffset, endOffset)).desc(u => u.modifieddate))
        }else if(val == "oldest") {
            setCurrentItems(sort(props.arts.slice(itemOffset, endOffset)).asc(u => u.modifieddate))
        }
        setPageCount(Math.ceil(sort(props.arts).desc(u => u.modifieddate).length / postsPerPage));
    }

    return (
        <main className="main">
            <div className="container-fluid px-md-5">
                <div className="row row--grid">

                    {/* title */}
                    <div className="col-12">
                        <div className="main__title main__title--page">
                            <h1>Discover NFTs</h1>
                        </div>
                    </div>
                    {/* end title */}

                    {/* tabs nav */}
                    <div className="row row--grid">
                        <div className="col-12 col-xl-2">
                            <Filter
                                {...props}
                                toggleFilter={toggleFilter}
                                filterToggle={filterToggle}
                                sortArts={sortArts}
                                applyFilter={applyFilter}
                                clearFilter={clearFilter}
                                setValues={setValues}
                            />
                        </div>
                        <div className="col-12 col-xl-10 px-md-5">
                            <Explore 
                                {...props}
                                allLikes={allLikes}
                                setLikes={setLikes}
                                itemOffset={itemOffset}
                                setCurrentItems={setCurrentItems}
                                setPageCount={setPageCount}
                                currentItems={currentItems}
                                handlePageClick={handlePageClick}
                                pageCount={pageCount}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}