import React, { useState } from 'react'
import axios from 'axios'
import AWS from "aws-sdk";
import convert from 'image-file-resize';
import { alertPopup, emailFormatting, trimFileName } from '../../utils'

import SignUpForm from './signupform';

const s3 = new AWS.S3({
    endpoint: 'nyc3.digitaloceanspaces.com',
    accessKeyId: 'YGH6JB5BESBUDFMJP63D',
    secretAccessKey: '15BQpZMLAsNwS4ala5Bva1SvtX63fU0sXVBBrUqIQt0'
});

export function Signup(props) {

    const [ DijauxName, setDijauxName ] = useState('');
    const [ DijauxUserDescription, setDijauxUserDescription ] = useState('');
    const [ ProfilePic, setProfilePic ] = useState('');
    const [ Cover, setCover ] = useState('');
    const [ Email, setEmail ] = useState('');
    const [ SocialFacebook, setSocialFacebook ] = useState('');
    const [ SocialTwitter, setSocialTwitter ] = useState('');
    const [ SocialInstagram, setSocialInstagram ] = useState('');
    const [ Loading, setLoading ] = useState(false);

    const updateSocialFacebook = event => {
        setSocialFacebook(event.target.value);
    }

    const updateSocialTwitter = event => {
        setSocialTwitter(event.target.value);
    }

    const updateSocialInstagram = event => {
        setSocialInstagram(event.target.value);
    }

    const updateFieldSignupName = event => {
        setDijauxName(event.target.value);
    }
    
    const updateEmailAddress = event => {
        setEmail(event.target.value);
    }

    const updateFieldSignupDescription = event => {
        setDijauxUserDescription(event.target.value);
    }

    const changeHandlerProfilePic = (event) => {
        const accepted = ["jpg","jpeg","png"];

        if(accepted.includes(event.target.files[0].name.split('.').pop()) && ((event.target.files[0].size / 1024 / 1024) < 20 )) {

            let img = new Image()
            img.src = window.URL.createObjectURL(event.target.files[0])
            img.onload = () => {
                var percentage = (160 / img.width);
                var heightCalc = Math.ceil(img.height * percentage);
                convert({ 
                    file: event.target.files[0],  
                    width: 160, 
                    height: heightCalc,
                    type: event.target.files[0].name.split('.').pop()
                    }).then(resp => {
                        setProfilePic(resp);
                    }).catch(error => {
                         console.log("error");
                    })
            }
            document.getElementById('profilePicture').innerHTML = event.target.files[0].name;
        }else {
            alertPopup("wrongformatprofile");
        }
    }

    const changeHandlerCover = (event) => {
        const accepted = ["jpg","jpeg","png"];

        if(accepted.includes(event.target.files[0].name.split('.').pop()) && ((event.target.files[0].size / 1024 / 1024) < 20 )) {

            let img = new Image()
            img.src = window.URL.createObjectURL(event.target.files[0])
            img.onload = () => {
                var percentage = (1500 / img.width);
                var heightCalc = Math.ceil(img.height * percentage);
                convert({ 
                    file: event.target.files[0],  
                    width: 1500, 
                    height: heightCalc,
                    type: event.target.files[0].name.split('.').pop()
                    }).then(resp => {
                        setCover(resp);
                    }).catch(error => {
                         console.log("error");
                    })
            }

            document.getElementById('coverPhoto').innerHTML = event.target.files[0].name;
        }else {
            alertPopup("wrongformatprofile");
        }
    }

    const SignupSubmimt = async () => {

        if(ProfilePic == "" || Cover == "" || DijauxName == "" || Email == "") {
            alertPopup("profilerequired");
        }else {

            axios.get(props.APIUrl+"/usercheck/"+Email).then((res) => {

                // Do email validation
                if(res.data) {
                    alertPopup("emailexist");
                }else { // Only proceed with registration if email don't have record yet
                    setLoading(true);
                    alertPopup("profileupload");
                    // Add a file to a Space
                    var profilepicuser = {
                        Body: ProfilePic,
                        Bucket: "dijauxnft",
                        Key: "users/profile/"+trimFileName(ProfilePic.name),
                        ACL: 'public-read'
                    };
                    
                    s3.putObject(profilepicuser, function(err, data) {
                        if (err) { 
                            console.log(err, err.stack);
                        }else {
                            const ProfilePhoto = "https://dijauxnft.nyc3.digitaloceanspaces.com/users/profile/"+trimFileName(ProfilePic.name);

                            var coveruser = {
                                Body: Cover,
                                Bucket: "dijauxnft",
                                Key: "users/cover/"+trimFileName(Cover.name),
                                ACL: 'public-read'
                            };
                            
                            s3.putObject(coveruser, function(err, data) {
                                if (err) { 
                                    console.log(err, err.stack);
                                }else {
                                    const CoverPicture = "https://dijauxnft.nyc3.digitaloceanspaces.com/users/cover/"+trimFileName(Cover.name);

                                    const newperson = {
                                        name: DijauxName,
                                        email: Email,
                                        description: DijauxUserDescription,
                                        picture: ProfilePhoto,
                                        cover: CoverPicture,
                                        facebook: SocialFacebook,
                                        twitter: SocialTwitter,
                                        instagram: SocialInstagram,
                                        address: props.account.toLowerCase(),
                                        confirmed: false
                                    };
                                    axios.post(props.APIUrl+"/user/add", newperson).then((res) => {

                                        const emailContent = {
                                            email: Email,
                                            subject: "Thank you for joining Dijaux!",
                                            message: emailFormatting(
                                                DijauxName,
                                                "Congrats on creating your account with Dijaux Marketplace. Have fun buying and selling your NFTs. 🙌",
                                                "https://dijaux.com/wp-content/uploads/2021/12/Dijaux_Welcome_Email.png",
                                                `<div style="position: relative; width: 255px; height: 48px; margin-bottom: 24px;">
                                                    <a href="https://dijauxnfts.com/profile?address=`+props.account.toLowerCase()+`&confirm=yes" target="_blank" style="background: #00BA88; padding: 15px 20px; text-transform: uppercase; font-weight: 600; border: 0; color: #fff; text-decoration: none; position: absolute; top: 0; left: 0; bottom: 0; right: 0; font-family: 'Poppins', sans-serif; letter-spacing: 0.05em;white-space: nowrap;">Confirm email</a>
                                                </div>`
                                            )
                                        }

                                        // Send email notification to user
                                        axios.post(props.APIUrl+"/email", emailContent).then((res) => {
                                            
                                            setLoading(false); 
                                            alertPopup("confirmemail");
                                        });

                                    });
                                }     
                            });
                        }     
                    });
                }
            });
        }

    }

    return (
        <main className="main">
            <div className="container-fluid px-md-5">
                <div className="row row--grid">
                    <div className="col-md-2">{""}</div>
                    <div className="col-md-8">
                        <SignUpForm 
                            {...props}
                            Loading={Loading}
                            DijauxName={DijauxName}
                            changeHandlerProfilePic={changeHandlerProfilePic}
                            changeHandlerCover={changeHandlerCover}
                            updateFieldSignupName={updateFieldSignupName}
                            Email={Email}
                            updateEmailAddress={updateEmailAddress}
                            DijauxUserDescription={DijauxUserDescription}
                            updateFieldSignupDescription={updateFieldSignupDescription}
                            SocialFacebook={SocialFacebook}
                            updateSocialFacebook={updateSocialFacebook}
                            SocialTwitter={SocialTwitter}
                            updateSocialTwitter={updateSocialTwitter}
                            SocialInstagram={SocialInstagram}
                            updateSocialInstagram={updateSocialInstagram}
                            SignupSubmimt={SignupSubmimt}
                        />
                    </div>
                    <div className="col-md-2">{""}</div>
                </div>
            </div>
        </main> 
      );
}