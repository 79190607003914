import swal from 'sweetalert';
import { alertPopup } from '../../../../utils'
import axios from 'axios';

export const AddToAuction = async (props, startPrice) => {

    swal({
      title: "Are you sure?",
      text: "You are only allowed to open auction for 24 hrs per NFT every 3 days",
      icon: "warning",
      buttons: {
        cancel: true,
        confirm: "Proceed"
      },
      dangerMode:true
    })
    .then(async (willDelete) => {
      if (willDelete) {
        props.setloading(true);
        var self = props;
        var id = props.artwork;

        alertPopup('waitingapproval');

        await props.contract.methods.approve(props.addressAuction, id).estimateGas({ from: props.account }, function(error, gasAmount){
          var GasfeewithAllowance = gasAmount;
        
          props.contract.methods.approve(props.addressAuction, id).send({ from: props.account, gas: GasfeewithAllowance, gasPrice: props.estimateGasPrice, maxPriorityFeePerGas: null, maxFeePerGas: null })
          .on('transactionHash', async function(hash){

            alertPopup('gottransaction');

          }).on('confirmation', async function(confirmationNumber, receipt){ 
                                
            if(confirmationNumber == 0) {

                  alertPopup('approvalaccepted');

                  await self.contract.methods.CreateAuction(id,new Date().getTime() + (props.auctiontime*60*60*1000)).estimateGas({ from: self.account }, function(error, gasAmount){
                    var GasfeewithAllowance = gasAmount;
                    // Trigger create auction from auction contract
                    self.contract.methods.CreateAuction(id,new Date().getTime() + (props.auctiontime*60*60*1000)).send({ from: self.account, gas: GasfeewithAllowance, gasPrice: props.estimateGasPrice, maxPriorityFeePerGas: null, maxFeePerGas: null })
                    .on('transactionHash', async function(hash){

                      // Finally after transaction, set NFT DB data to modified = true
                      axios.post(props.APIUrl+"/nftupdate/" + id +"/"+ props.currency, { modified:true, transactionhash: hash }).then((res) => {
                        console.log("nft is in queue")
                        axios.get(props.APIUrl+"/nfts/").then((response) => {
                          if(response.data) {
                            props.setThisNFT(response.data)
                          }
                        });
                      });

                      const new_listed = {
                        type: "listed",
                        from: self.account,
                        amount: startPrice,
                        tokenid: id,
                        currency: props.currency,
                        time: new Date().getTime(),
                        transactionhash: hash,
                        active:false
                      }
                      axios.post(props.APIUrl+"/activity/add", new_listed).then((res) => {
                          console.log("activity added");
                      });

                      const emailContent = {
                          email: props.myaccount.email,
                          subject: "Congratulations! Your NFT is now at auction!",
                          message: "Congrats, You just listed your NFT. Make sure to upload your work to Dijaux Showcase for more promotion."
                      }
          
                      // Send email notification to user
                      axios.post(props.APIUrl+"/email", emailContent).then((res) => {
                          console.log("email sent")
                      });

                      alertPopup('gottransaction');

                    }).on('confirmation', async function(confirmationNumber, receipt){ 
                                  
                      if(confirmationNumber == 0) {
                        
                        props.setloading(false);
                        alertPopup('toauction');

                      }
                    }).on('error', function(error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                                  
                      alertPopup('error', error.code);
                      props.setloading(false);
                    });
                  });
              }
          }).on('error', function(error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                                
            alertPopup('error', error.code);
            props.setloading(false);

          });
        });
      }
    });
    
  }