import swal from 'sweetalert';
import axios from 'axios';
import { emailFormatting, alertPopup } from '../../../../utils'

export const burnNFT = (props, tokenId) => {

    swal({
      title: "Are you sure?",
      text: "Once you burn your token there's no coming back.",
      icon: "warning",
      buttons: {
        cancel: true,
        confirm: "Proceed"
      },
      dangerMode:true
    })
    .then(async (willDelete) => {
      if (willDelete) {
        props.setloading(true);
        
        alertPopup('waitingconfirmation');

        await props.contract.methods.burn(tokenId).estimateGas({ from: props.account }, function(error, gasAmount){
          var GasfeewithAllowance = gasAmount;
          // Trigger toggle sale from main contract
          props.contract.methods.burn(tokenId).send({ from: props.account, gas: GasfeewithAllowance, gasPrice: props.estimateGasPrice, maxPriorityFeePerGas: null, maxFeePerGas: null })
          .on('transactionHash', async function(hash){
            
            // Finally after transaction, set NFT DB data to modified = true
            axios.post(props.APIUrl+"/nftupdate/" + tokenId +"/"+ props.currency, { removed:true, transactionhash: hash }).then((res) => {
              console.log("nft is in queue")
              axios.get(props.APIUrl+"/nfts/").then((response) => {
                if(response.data.filter(key => !key.removed)) {
                  props.setThisNFT(response.data)
                }
              });
            });

            const emailContent = {
                email: props.myaccount.email,
                subject: "Congrats on burning NFT",
                message: emailFormatting(
                    props.myaccount.name,
                    "You just burnt your NFT. 🙌",
                    "https://dijaux.com/wp-content/uploads/2021/12/Burn_NFTNFT_Email.png",
                    ""
                  )
            }
  
            // Send email notification to user
            axios.post(props.APIUrl+"/email", emailContent).then((res) => {
              console.log("email sent")
            });

            alertPopup('gottransaction');
            
          }).on('confirmation', async function(confirmationNumber, receipt){ 
                                    
            if(confirmationNumber == 0) {

              swal({
                title: "Burn NFT transaction confirmed!",
                text: "Thank you for waiting! This window will close in 5 seconds or you can reload this page.",
                icon: "success",
                buttons: false,
                closeOnClickOutside: false,
                timer: 5000,
              }).then((value) => {
                  swal.close();
                  window.location.reload();
              }).catch(err => {
                  swal("Oops!", "Seems there's an issue. Please contact administrator", "error");
              });
              // Sync after confirmation
              //props.processTransactionConfirmation(receipt.transactionHash, tokenId, "burnNFT");
              
            }
            }).on('error', function(error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                                
              alertPopup('error', error.code);
              props.setloading(false);

            });
          });
      }
    });
    
  }