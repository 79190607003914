import swal from 'sweetalert'
import { ALERT_POPUP_CODES, METAMASK_POSSIBLE_ERRORS } from '../../constants'

export const alertPopup = (props, link) => {

    let details = ALERT_POPUP_CODES[props];
    let content = {
        title: details.title,
        text: details.text,
        icon: details.icon
    }

    if(props == "error") {
        content['text'] = typeof link !== undefined && METAMASK_POSSIBLE_ERRORS[link] ? METAMASK_POSSIBLE_ERRORS[link].message : "Unknown error please contact Administrator"
    }

    const buttonsOff = ['gottransaction', 'mintprocess', 'mintsuccess', 'profileupload','waitingconfirmation', 'transactionconfirmed'];
    const withRedirection = ['toauction', 'mintlisted', 'confirmemail','nftnotexist','nfthasbeentransferred','youplacebid'];

    if(buttonsOff.includes(props)) {
        content['buttons'] = false;
        content['closeOnClickOutside'] = false;
    }

    if(withRedirection.includes(props)) {
        content['buttons'] = false;
        content['closeOnClickOutside'] = false;
        //content['timer'] = 2000;
    }

    swal(content).then((value) => {

        if(withRedirection.includes(props) && link) {
            swal.close();
            window.location.href = link;
        }

        if(props == "error") {
            swal.close();
        }

        if(props == "confirmemail") {
            window.location = "/";
        }

    }).catch(err => {
        swal("Oops!", details.error, "error");
    });
}