import axios from 'axios'

export function Likes(e, tokenid, currency, method, account, apiurl, setLikes)  { 

    e.preventDefault();
    e.target.className = e.target.className + " disable";

    const data = {
    tokenid: tokenid,
    address: account,
    currency:currency
    }

    axios.post(apiurl+"/like/"+ method, data).then(async (res) => {
        axios.get(apiurl+"/likes/").then((response) => {
        if(response.data) {
            setLikes(response.data)
            e.target.className = e.target.className.replace("disable", "");
        }
        });
    });

}