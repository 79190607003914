import swal from 'sweetalert';
import axios from 'axios';
import { alertPopup } from '../../../../utils'
import convertETH from 'ether-converter'

export const changeTokenPrice = (props, tokenId) => {

    if(props.newNFTprice == "") {

      alertPopup('missingprice');

    }else {

      swal({
        title: "Update price?",
        text: "You can change this anytime",
        icon: "warning",
        buttons: {
          cancel: true,
          confirm: "Proceed"
        },
        dangerMode:true
      })
      .then(async (willDelete) => {
        if (willDelete) {
          const newTokenPrice = convertETH(props.newNFTprice, "ether", 'wei');

          await props.contract.methods.changeTokenPrice(tokenId, newTokenPrice).estimateGas({ from: props.account }, function(error, gasAmount){
              var GasfeewithAllowance = gasAmount;
              // trigger change price from main contract
              props.contract.methods.changeTokenPrice(tokenId, newTokenPrice).send({ from: props.account, gas: GasfeewithAllowance, gasPrice: props.estimateGasPrice, maxPriorityFeePerGas: null, maxFeePerGas: null })
              .on('transactionHash', async function(hash){
                
                // Finally after transaction, set NFT DB data to modified = true
                axios.post(props.APIUrl+"/nftupdate/" + tokenId +"/"+ props.currency, { modified:true, transactionhash: hash }).then((res) => {
                  console.log("nft is in queue")
                  axios.get(props.APIUrl+"/nfts/").then((response) => {
                    if(response.data) {
                      props.setThisNFT(response.data)
                    }
                  });
                });

                // Change price activity
                const new_price = {
                  type: "changeprice",
                  from: props.account,
                  amount: newTokenPrice,
                  tokenid: tokenId,
                  currency: props.currency,
                  time: new Date().getTime(),
                  transactionhash: hash,
                  active:false
                }
                axios.post(props.APIUrl+"/activity/add", new_price).then((res) => {
                    console.log("activity added");
                });

                const emailContent = {
                    email: props.myaccount.email,
                    subject: "Congrats on changing price of NFT",
                    message: "You're NFT has now updated price."
                }
      
                // Send email notification to user
                axios.post(props.APIUrl+"/email", emailContent).then((res) => {
                  console.log("email sent")
                });

                alertPopup('gottransaction');

              }).on('confirmation', async function(confirmationNumber, receipt){

                if(confirmationNumber == 0) {

                  swal({
                    title: "Change token price transaction confirmed!",
                    text: "Thank you for waiting! This window will close in 5 seconds or you can reload this page.",
                    icon: "success",
                    buttons: false,
                    closeOnClickOutside: false,
                    timer: 5000,
                  }).then((value) => {
                      swal.close();
                      window.location.reload();
                  }).catch(err => {
                      swal("Oops!", "Seems there's an issue. Please contact administrator", "error");
                  });
                  // Sync after confirmation
                  //props.processTransactionConfirmation(receipt.transactionHash, tokenId, "changeTokenPrice");
                  
                }
              }).on('error', function(error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                                  
                alertPopup('error', error.code);
                props.setloading(false);

              });
          });
          // End gas estimation
        }
      });
    }

  }