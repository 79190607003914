import React, { Component } from 'react';
import axios from 'axios'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import convertETH from 'ether-converter';

// Abis
import DijauxABI from '../abis/DijauxAuction.json'

// Templates
import Header from './pages/templates/Header'
import Footer from './pages/templates/Footer'

// Pages
import { Home, Signup, Artists, Activities, Discover, Create, Profile, Artwork } from './pages';

// Data Loadder
import { loadWeb3, loadData } from './dataloader';

// Utils
import ScrollIntoView, { Loading, time2TimeAgo, checkContractAddress, NotFoundPage } from './utils';

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      metamaskConnected: false,
      finishedLoading: false,
      // All Blockchain datas
      auction: [],
      auctionexists: [],
      BaseIPFSUrl: "https://dijaux.infura-ipfs.io/ipfs/",
      APIUrl: "https://api.dijauxnfts.com",
      //APIUrl: "http://localhost:4000",
      BaseUrl: "https://dijauxnfts.com",
      //BaseUrl: "http://localhost:3000",
      serviceCharge: 2,
      currency: "ETH"
    }
  }

  componentDidMount = () => {
    loadWeb3();
    this.loadBlockchainData() 
  };

  loadBlockchainData = async () => {
    if(window.web3) {
      
      window.ethereum.on('accountsChanged', function (accounts) {
        window.location.reload()
      })
      window.ethereum.on('chainChanged', function (accounts) {
        window.location.reload()
      })
  
      const web3 = window.web3;
      //const provider = await detectEthereumProvider();
      //const accounts = await provider.request({ method: 'eth_requestAccounts' });
      const accounts = await web3.eth.getAccounts();

      if (accounts.length === 0) {
        this.setState({ metamaskConnected: false });
        loadData(this);
      } else {
        this.setState({ metamaskConnected: true });
        loadData(this, true, accounts[0]);
        console.log("accounts loaded");
        //const networkId = await provider.request({ method: 'net_version' });
        const networkId = await web3.eth.net.getId();
        const networkData = DijauxABI.networks[networkId];
        if (networkData) {
          // DijauxData ABI
          const contract = await new web3.eth.Contract(DijauxABI.abi, networkData.address)
          //const totalSupply = await contract.methods.dijauxNFTCounter().call();
          //const totalSupplyAuction = await contract.methods.GettokenIdToIndex().call()
          console.log(contract)
          axios.get('https://chainid.network/chains.json').then(async (res) => {

            const chainDetails = res.data.find(key => key.networkId == networkId);

            this.setState({ 
              ethBalance: parseFloat(convertETH(await web3.eth.getBalance(accounts[0]), 'wei' , 'ether')).toFixed(2),
              addressAuction: networkData.address,
              contract,
              estimateGasPrice: await web3.eth.getGasPrice(),
              networkID: networkId,
              currency: chainDetails.chain ? chainDetails.chain : "DEV",
              scanURL: chainDetails.explorers ? chainDetails.explorers[0].url+"/token" : "test/token",
            })

          });
          
          
          
          /*totalSupplyAuction.forEach(async (item, index) => {
              const tokenID_ = parseInt(item);
              
              const dijauxnftAuctions = await contract.methods.tokenIdToAuction(tokenID_).call() // Method is from contracts public string
              dijauxnftAuctions["tokenid"] = tokenID_;
  
              self.setState({
                auction: [...self.state.auction, dijauxnftAuctions] // Store all arts here
              })
          });*/
  
          //console.log("auction loaded");
          
          console.log("blockchain loaded");
        } else {
          window.alert('Smart contract not deployed to detected network.')
          loadData(this);
        }
      }
    }else {
      loadData(this);
    }
  };

  connectWallet = async () => {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
  }

  convertUSD = (value,currency) => {
      let cryptoValue = currency == "ETH" ? this.state.ETHValue : this.state.MATICValue;
      return parseInt(cryptoValue * value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  render() {
    return (
      this.state.finishedLoading === true ? (
        <Router>
          <Header 
            account={this.state.account}
            metamaskConnected={this.state.metamaskConnected}
            BaseIPFSUrl={this.state.BaseIPFSUrl}
            ethBalance={this.state.ethBalance}
            connectWallet={this.connectWallet}
            creators={this.state.creators}
            arts={this.state.arts}
            myaccount={this.state.myaccount}
            currency={this.state.currency}
            APIUrl={this.state.APIUrl}
            noProvider={this.state.noProvider}
          />
            <ScrollIntoView>
              <Switch>
                <Route exact path="/">
                  <Home 
                    account={this.state.account} 
                    metamaskConnected={this.state.metamaskConnected}
                    convertUSD={this.convertUSD}
                    BaseIPFSUrl={this.state.BaseIPFSUrl}
                    creators={this.state.creators}
                    arts={this.state.arts}
                    auction={this.state.auction}
                    auctionexists={this.state.auctionexists}
                    likes={this.state.likes}
                    collections={this.state.collections}
                    follows={this.state.follows}
                    APIUrl={this.state.APIUrl}
                    currency={this.state.currency}
                    noProvider={this.state.noProvider}
                    NFTcount={this.state.NFTcount}
                  />
                </Route>
                <Route path="/profile">
                  <Profile
                    account={this.state.account} 
                    metamaskConnected={this.state.metamaskConnected}
                    BaseIPFSUrl={this.state.BaseIPFSUrl}
                    time2TimeAgo={time2TimeAgo}
                    convertUSD={this.convertUSD}
                    creators={this.state.creators}
                    arts={this.state.arts}
                    collections={this.state.collections}
                    auction={this.state.auction}
                    auctionexists={this.state.auctionexists}
                    likes={this.state.likes}
                    follows={this.state.follows}
                    activities={this.state.activities}
                    APIUrl={this.state.APIUrl}
                    currency={this.state.currency}
                    noProvider={this.state.noProvider}
                  />
                </Route>
                <Route path="/discover">
                  <Discover 
                    account={this.state.account} 
                    metamaskConnected={this.state.metamaskConnected}
                    BaseIPFSUrl={this.state.BaseIPFSUrl}
                    convertUSD={this.convertUSD}
                    creators={this.state.creators}
                    arts={this.state.arts}
                    artsextras={this.state.arts}
                    auction={this.state.auction}
                    auctionexists={this.state.auctionexists}
                    likes={this.state.likes}
                    APIUrl={this.state.APIUrl}
                    currency={this.state.currency}
                    noProvider={this.state.noProvider}
                  />
                </Route>
                <Route path="/artists">
                  <Artists 
                    BaseIPFSUrl={this.state.BaseIPFSUrl}
                    metamaskConnected={this.state.metamaskConnected}
                    convertUSD={this.convertUSD}
                    creators={this.state.creators}
                    follows={this.state.follows}
                    account={this.state.account}
                    APIUrl={this.state.APIUrl}
                    noProvider={this.state.noProvider}
                    arts={this.state.arts}
                    />
                </Route>
                <Route path="/activities">
                  <Activities
                  BaseIPFSUrl={this.state.BaseIPFSUrl}
                  time2TimeAgo={time2TimeAgo}
                  convertUSD={this.convertUSD}
                  creators={this.state.creators}
                  arts={this.state.arts}
                  auction={this.state.auction}
                  activities={this.state.activities}
                  currency={this.state.currency}
                  />
                </Route>
                <Route path="/nft/:id/:currency">
                  <Artwork
                    web3={window.web3}
                    account={this.state.account}
                    metamaskConnected={this.state.metamaskConnected}
                    addressAuction={this.state.addressAuction}
                    checkContractAddress={checkContractAddress}
                    contract={this.state.contract}
                    BaseIPFSUrl={this.state.BaseIPFSUrl}
                    convertUSD={this.convertUSD}
                    creators={this.state.creators}
                    scanURL={this.state.scanURL}
                    arts={this.state.arts}
                    auction={this.state.auction}
                    auctionexists={this.state.auctionexists}
                    myaccount={this.state.myaccount}
                    likes={this.state.likes}
                    activities={this.state.activities}
                    APIUrl={this.state.APIUrl}
                    serviceCharge={this.state.serviceCharge}
                    currency={this.state.currency}
                    noProvider={this.state.noProvider}
                    estimateGasPrice={this.state.estimateGasPrice}
                  />
                </Route>
                <Route path="/collection/:id/:currency">
                  <Artwork
                    web3={window.web3}
                    isCollection={false}
                    account={this.state.account}
                    metamaskConnected={this.state.metamaskConnected}
                    addressAuction={this.state.addressAuction}
                    checkContractAddress={checkContractAddress}
                    contract={this.state.contract}
                    BaseIPFSUrl={this.state.BaseIPFSUrl}
                    convertUSD={this.convertUSD}
                    creators={this.state.creators}
                    scanURL={this.state.scanURL}
                    arts={this.state.arts}
                    auction={this.state.auction}
                    auctionexists={this.state.auctionexists}
                    myaccount={this.state.myaccount}
                    likes={this.state.likes}
                    activities={this.state.activities}
                    APIUrl={this.state.APIUrl}
                    serviceCharge={this.state.serviceCharge}
                    currency={this.state.currency}
                    noProvider={this.state.noProvider}
                    estimateGasPrice={this.state.estimateGasPrice}
                  />
                </Route>
                <Route path="/mint">
                  <Create
                    account={this.state.account} 
                    contract={this.state.contract}
                    BaseIPFSUrl={this.state.BaseIPFSUrl}
                    convertUSD={this.convertUSD}
                    addressAuction={this.state.addressAuction}
                    creators={this.state.creators}
                    myaccount={this.state.myaccount}
                    BaseUrl={this.state.BaseUrl}
                    APIUrl={this.state.APIUrl}
                    networkID={this.state.networkID}
                    currency={this.state.currency}
                    estimateGasPrice={this.state.estimateGasPrice}
                    ethBalance={this.state.ethBalance}
                    NFTcount={this.state.NFTcount}
                  />
                </Route>
                <Route path="/signup">
                  <Signup
                    account={this.state.account} 
                    BaseIPFSUrl={this.state.BaseIPFSUrl}
                    APIUrl={this.state.APIUrl}
                    metamaskConnected={this.state.metamaskConnected}
                  />
                </Route>
                <Route path="/404">
                  <NotFoundPage
                    creators={this.state.creators}
                    arts={this.state.arts}
                    currency={this.state.currency}
                  />
                </Route>
                <Route path="*">
                  <Redirect to="/404" />
                </Route>
              </Switch>
            </ScrollIntoView>
          <Footer />
        </Router>  
      ) : (
        <Loading />
      )
    );
  }
}

export default App;
