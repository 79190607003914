import React from 'react'
import { Follows } from '../../../actions'

export default function Creators(props) {

    return (
        <section className="row row--grid">
            {/* title */}
            <div className="col-12">
              <div className="main__title">
                <h2>
                  <div>Creators <small>({props.allCreators.length})</small></div>
                </h2>
                <a className="home__btn home__btn--clr m-0" href="/artists">View all Creators</a>
                <hr />
              </div>
            </div>
            {/* end title */}
    
            {/* carousel */}
            <div className="col-12">
              <div className="main__carousel-wrap">
                <div className="row row--grid">
                  {props.allCreators.slice(0, 8).map((artist,i) => (     
                    <div key={i} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                      <div className="author">
                        <a href={"/profile?address=" + artist.address} className="author__cover author__cover--bg" style={{
                          backgroundImage: `url(${artist.cover})`,
                          backgroundPosition: `center center`,
                          backgroundRepeat: `no-repeat`,
                          backgroundSize: `cover`
                        }}>
                        </a>
                        <div className="author__meta">
                          <a href={"/profile?address=" + artist.address} className="author__avatar author__avatar--verified">
                            <img src={artist.picture} alt="" width="60" height="60" />
                          </a>
                          <h3 className="author__name"><a href={"/profile?address=" + artist.address}>{artist.name}</a></h3>
                          <h3 className="author__nickname"><a href={"/profile?address=" + artist.address}>@{artist.name.replace(/\s+/g, '').toLowerCase()}</a></h3>
                          <p className="author__text">{artist.description.slice(0,100)} ...</p>
                          <div className="author__wrap">
                            <div className="author__followers">
                              <p>{props.allFollows.filter(r => r.address == artist.address).length}</p>
                              <span>Followers</span>
                            </div>

                            <div className="author__followers">
                              <p>{props.allArts.filter(r => r.creator.toLowerCase() == artist.address.toLowerCase()).length}</p>
                              <span>Posts</span>
                            </div>
      
                            {props.metamaskConnected && !props.noProvider ? (
                              <React.Fragment>
                                {artist.address != props.account ? (
                                  <button 
                                  className={props.allFollows.some(r => r.address == artist.address && r.follower == props.account) ? "author__follow followed" : "author__follow"} 
                                  type="button" 
                                  onClick={(e) => Follows(e,artist.address, props.allFollows.some(r => r.address == artist.address && r.follower == props.account) ? "delete" : "add", props.account, props.APIUrl, props.setFollows)}
                                  >
                                    {props.allFollows.some(r => r.address == artist.address && r.follower == props.account) ? "Unfollow" : "Follow"}
                                  </button>
                                ) : null}
                              </React.Fragment>
                            ) : null}
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>          
              </div>          
            </div>       
            {/* end carousel */}
        </section>
    );
}