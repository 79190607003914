import { emailFormatting, alertPopup } from '../../../../utils'
import axios from 'axios';
import swal from 'sweetalert'
import convertETH from 'ether-converter'

export const buyNFT = async (props, tokenId, price, lastowner) => {

    props.setloading(true);

    alertPopup('waitingconfirmation');
    
    // If the NFT is in lazy mint, then mint it first
    if(tokenId > 1000000) {

      swal({
          title: 'You are buying a Lazy mint NFT',
          text: 'You will need to pay 2 transaction for both mint and buy for this NFT and the original minter will be the creator of this NFT then you will be the owner. Are you sure you want to proceed?',
          icon: "warning",
          buttons: true,
          dangerMode: true,
          closeOnClickOutside: false,
      }).then(async (value) => {
          swal.close();
          if (value){
            let newTokenId = 0;

            const selectedArt = props.arts.find((a)=>a.tokenid==tokenId);

            const to = selectedArt.creator;
            const signature = selectedArt && selectedArt.voucher && selectedArt.voucher.signature || "0x5dd841470978437367622de0752d7a734b919c61cfc51923e801e6a67c91425b14a70d45e2fed21face81f3f7518716f17ebfe464989527a4be298c11887807a1b";
            const mintVoucher = [tokenId, selectedArt.price, selectedArt.metadata, selectedArt.royalties, selectedArt.forsale, signature];

            await props.contract.methods.lazyMintNFT(to, mintVoucher).estimateGas({ from: props.account, value: selectedArt.price },async function(error, gasAmount){
              var GasfeewithAllowance = gasAmount;

              await props.contract.methods.lazyMintNFT(to, mintVoucher).send({ from: props.account, value: selectedArt.price, gas: GasfeewithAllowance, gasPrice: props.estimateGasPrice, maxPriorityFeePerGas: null, maxFeePerGas: null })
              .on('transactionHash', async function(hash){

                alertPopup('lazymintprocess');
                
              }).on('confirmation', async function(confirmationNumber, receipt){ 

                if(confirmationNumber == 0) {

                  // After minting lazy mint, then update the new token from counter
                  newTokenId = await props.contract.methods.dijauxNFTCounter().call();

                  await props.contract.methods.BuyNFTToken(newTokenId, props.serviceCharge).estimateGas({ from: props.account, value: selectedArt.price },async function(error, gasAmount){
                    var GasfeewithAllowance = gasAmount;
                    
                    // trigger to buy method in main contract
                    await props.contract.methods.BuyNFTToken(newTokenId, props.serviceCharge).send({ from: props.account, value: selectedArt.price, gas: GasfeewithAllowance, gasPrice: props.estimateGasPrice, maxPriorityFeePerGas: null, maxFeePerGas: null })
                    .on('transactionHash', async function(hash){

                      alertPopup('gottransaction');
                      
                      // Finally after transaction, set NFT DB data to modified = true
                      axios.post(props.APIUrl+"/nftupdate/" + newTokenId +"/"+ props.currency, { modified:true, transactionhash: hash, category: selectedArt.category }).then((res) => {
                        console.log("nft is in queue")
                        axios.get(props.APIUrl+"/nfts/").then((response) => {
                          if(response.data) {
                            props.setThisNFT(response.data);

                            var originalTimeStamp = props.allActivities.find(key => key.tokenid == tokenId && key.currency == props.currency);

                            // update the new minted with past date of original mint from lazy mint activity
                            axios.post(props.APIUrl+"/activityupdatebyid/"+newTokenId+"/"+props.currency, { time:originalTimeStamp.time }).then(async (res) => {
                              console.log("updated new activity with past time for mint")
                              // Finally delete the lazy mint original mint activity
                              axios.post(props.APIUrl+"/activity/delete", { tokenid:tokenId, currency:props.currency }).then(async (res) => {console.log("old activity is deleted")});
                            });

                            // delete all existing lazy mint activity and NFT and resync with updated token ID
                            axios.post(props.APIUrl+"/nft/delete", { tokenid:tokenId, currency:props.currency }).then(async (res) => {});
                            
                          }
                        });
                      });
              
                      // Bought activity
                      const new_bought = {
                        type: "bought",
                        from: props.account,
                        amount: price,
                        tokenid: newTokenId,
                        currency: props.currency,
                        time: new Date().getTime(),
                        transactionhash: hash,
                        active:false
                      }
                      axios.post(props.APIUrl+"/activity/add", new_bought).then((res) => {
                          console.log("activity added");
                      });
              
                      // new owned auction
                      const new_sold = {
                        type: "sold",
                        from: lastowner,
                        amount: price,
                        tokenid: newTokenId,
                        currency: props.currency,
                        time: new Date().getTime(),
                        transactionhash: hash,
                        active:false
                      }
                      console.log("nft updated");
              
                      axios.post(props.APIUrl+"/activity/add", new_sold).then((res) => {
                          console.log("activity added");
                      });
              
                      let priceBought = convertETH(price, "wei", 'ether');
              
                      const emailContent = {
                          email: props.myaccount.email,
                          subject: "Congrats on buying NFT",
                          message: emailFormatting(
                              props.myaccount.name,
                              "Congrats you just purchased your NFT. 🙌",
                              "https://dijaux.com/wp-content/uploads/2021/12/Bought_NFT_Email.png",
                              `<div style="margin:10px 0;">
                              <strong>Details:</strong>
                              <br><br>
                              <strong>Winning Price:</strong> <u>`+priceBought+` `+props.currency+`</u>
                              <br>
                              <strong>Link:</strong> <u><a href="https://dijauxnfts.com/nft/`+newTokenId+`/`+props.currency+`">https://dijauxnfts.com/nft/`+newTokenId+`/`+props.currency+`</a></u>
                              </div>`
                            )
                      }
              
                      // Send email notification to user
                      axios.post(props.APIUrl+"/email", emailContent).then((res) => {
                        console.log("email sent")
                      });
              
                      // For the owner of NFT email notification
                      axios.get(props.APIUrl+"/user/" + lastowner.toLowerCase()).then(response => {   
                        
                        
                        const owneremailContent = {
                            email: response.data.email,
                            subject: "Congrats on selling your NFT",
                            message: emailFormatting(
                              props.creators.find(v => v.address.toLowerCase() == lastowner.toLowerCase()).name,
                              "Congrats on selling your NFT. 🙌",
                              "https://dijaux.com/wp-content/uploads/2021/12/Sold_NFT_Email.png",
                              `<div style="margin:10px 0;">
                              <strong>Details:</strong>
                              <br><br>
                              <strong>Winning Price:</strong> <u>`+priceBought+` `+props.currency+`</u>
                              <br>
                              <strong>Link:</strong> <u><a href="https://dijauxnfts.com/nft/`+newTokenId+`/`+props.currency+`">https://dijauxnfts.com/nft/`+newTokenId+`/`+props.currency+`</a></u>
                              </div>`
                            )
                        }
                        axios.post(props.APIUrl+"/email", owneremailContent).then((res) => {
                          console.log("email sent")
                        });
                        
                      }).catch(err => console.log(err));
              
                    }).on('confirmation', async function(confirmationNumber, receipt){ 
                                              
                      if(confirmationNumber == 0) {

                        swal({
                          title: "Buy NFT transaction confirmed!",
                          text: "Thank you for waiting! This window will close in 5 seconds or you can reload this page.",
                          icon: "success",
                          buttons: false,
                          closeOnClickOutside: false,
                          timer: 5000,
                        }).then((value) => {
                            swal.close();
                            window.location.href="/nft/"+newTokenId+"/"+props.currency;
                        }).catch(err => {
                            swal("Oops!", "Seems there's an issue. Please contact administrator", "error");
                        });
                        
              
                        // Sync after confirmation
                        //props.processTransactionConfirmation(receipt.transactionHash, newTokenId, "buyNFT");
                        
                      }
                    }).on('error', function(error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                                              
                      alertPopup('error', error.code);
                      window.location.href="/nft/"+newTokenId+"/"+props.currency;
                      
                    });
                  });

                }
                                        
              }).on('error', function(error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                                        
                alertPopup('error', error.code);
                props.setloading(false);

              });
            });
          }else {
            props.setloading(false);
          }
      }).catch(err => {
          swal("Oops!", "Seems there's an issue. Please contact administrator", "error");
      });

      

    // If the NFt is already minted
    }else {

      await props.contract.methods.BuyNFTToken(tokenId, props.serviceCharge).estimateGas({ from: props.account, value: price }, function(error, gasAmount){
        var GasfeewithAllowance = gasAmount;
        // trigger to buy method in main contract
        props.contract.methods.BuyNFTToken(tokenId, props.serviceCharge).send({ from: props.account, value: price, gas: GasfeewithAllowance, gasPrice: props.estimateGasPrice, maxPriorityFeePerGas: null, maxFeePerGas: null })
        .on('transactionHash', async function(hash){
  
          // Finally after transaction, set NFT DB data to modified = true
          axios.post(props.APIUrl+"/nftupdate/" + tokenId +"/"+ props.currency, { modified:true, transactionhash: hash }).then((res) => {
            console.log("nft is in queue")
            axios.get(props.APIUrl+"/nfts/").then((response) => {
              if(response.data) {
                props.setThisNFT(response.data)
              }
            });
          });
  
          // Bought activity
          const new_bought = {
            type: "bought",
            from: props.account,
            amount: price,
            tokenid: tokenId,
            currency: props.currency,
            time: new Date().getTime(),
            transactionhash: hash,
            active:false
          }
          axios.post(props.APIUrl+"/activity/add", new_bought).then((res) => {
              console.log("activity added");
          });
  
          // new owned auction
          const new_sold = {
            type: "sold",
            from: lastowner,
            amount: price,
            tokenid: tokenId,
            currency: props.currency,
            time: new Date().getTime(),
            transactionhash: hash,
            active:false
          }
          console.log("nft updated");
  
          axios.post(props.APIUrl+"/activity/add", new_sold).then((res) => {
              console.log("activity added");
          });
  
          let priceBought = convertETH(price, "wei", 'ether');
  
          const emailContent = {
              email: props.myaccount.email,
              subject: "Congrats on buying NFT",
              message: emailFormatting(
                  props.myaccount.name,
                  "Congrats you just purchased your NFT. 🙌",
                  "https://dijaux.com/wp-content/uploads/2021/12/Bought_NFT_Email.png",
                  `<div style="margin:10px 0;">
                  <strong>Details:</strong>
                  <br><br>
                  <strong>Winning Price:</strong> <u>`+priceBought+` `+props.currency+`</u>
                  <br>
                  <strong>Link:</strong> <u><a href="https://dijauxnfts.com/nft/`+tokenId+`/`+props.currency+`">https://dijauxnfts.com/nft/`+tokenId+`/`+props.currency+`</a></u>
                  </div>`
                )
          }
  
          // Send email notification to user
          axios.post(props.APIUrl+"/email", emailContent).then((res) => {
            console.log("email sent")
          });
  
          // For the owner of NFT email notification
          axios.get(props.APIUrl+"/user/" + lastowner.toLowerCase()).then(response => {   
            
            
            const owneremailContent = {
                email: response.data.email,
                subject: "Congrats on selling your NFT",
                message: emailFormatting(
                  props.creators.find(v => v.address.toLowerCase() == lastowner.toLowerCase()).name,
                  "Congrats on selling your NFT. 🙌",
                  "https://dijaux.com/wp-content/uploads/2021/12/Sold_NFT_Email.png",
                  `<div style="margin:10px 0;">
                  <strong>Details:</strong>
                  <br><br>
                  <strong>Winning Price:</strong> <u>`+priceBought+` `+props.currency+`</u>
                  <br>
                  <strong>Link:</strong> <u><a href="https://dijauxnfts.com/nft/`+tokenId+`/`+props.currency+`">https://dijauxnfts.com/nft/`+tokenId+`/`+props.currency+`</a></u>
                  </div>`
                )
            }
            axios.post(props.APIUrl+"/email", owneremailContent).then((res) => {
              console.log("email sent")
            });
            
          }).catch(err => console.log(err));
  
          alertPopup('gottransaction');
  
        }).on('confirmation', async function(confirmationNumber, receipt){ 
                                  
          if(confirmationNumber == 0) {

            swal({
              title: "Buy NFT transaction confirmed!",
              text: "Thank you for waiting! This window will close in 5 seconds or you can reload this page.",
              icon: "success",
              buttons: false,
              closeOnClickOutside: false,
              timer: 5000,
            }).then((value) => {
                swal.close();
                window.location.reload();
            }).catch(err => {
                swal("Oops!", "Seems there's an issue. Please contact administrator", "error");
            });
            // Sync after confirmation
            //props.processTransactionConfirmation(receipt.transactionHash, tokenId, "buyNFT");
  
          }
        }).on('error', function(error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                                  
          alertPopup('error', error.code);
          props.setloading(false);
  
        });
      });
    }

    

    
  }