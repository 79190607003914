import swal from 'sweetalert';
import axios from 'axios';
import { emailFormatting, alertPopup } from '../../../../utils'

export const finalizedAuction = async (props, id, owner, winningPrice) => {

    props.setloading(true);
    alertPopup('waitingconfirmation');
    
    await props.contract.methods.FinalizedAuction(id, props.serviceCharge).estimateGas({ from: props.account }, function(error, gasAmount){
      var GasfeewithAllowance = gasAmount;
      // trigger to finalized auction from auction contract
      props.contract.methods.FinalizedAuction(id, props.serviceCharge).send({ from: props.account, gas: GasfeewithAllowance, gasPrice: props.estimateGasPrice, maxPriorityFeePerGas: null, maxFeePerGas: null })
      .on('transactionHash', async function(hash){
        
        const finalize_auction = {
          last_auction: new Date().getTime() + (72*60*60*1000),
          modified: true
        }

        await axios.post(props.APIUrl+"/nftupdate/" + id +"/"+ props.currency, finalize_auction).then(response => {
            console.log("nft updated");
        }); 

        // new owned auction
        const new_bought = {
          type: "owned",
          from: owner,
          amount: winningPrice,
          tokenid: id,
          currency: props.currency,
          time: new Date().getTime(),
          transactionhash: hash,
          active:false
        }
        console.log("nft updated");
        axios.post(props.APIUrl+"/activity/add", new_bought).then((res) => {
            console.log("activity added");
        });  

        // new owned auction
        const new_sold = {
          type: "sold",
          from: props.account,
          amount: winningPrice,
          tokenid: id,
          currency: props.currency,
          time: new Date().getTime(),
          transactionhash: hash,
          active:false
        }  

        axios.post(props.APIUrl+"/activity/add", new_sold).then((res) => {
          console.log("activity added");
        });  

        const emailContent = {
            email: props.myaccount.email,
            subject: "NFT has been bought successfully and been transferred",
            message: emailFormatting(
                props.myaccount.name,
                "Congrats you just purchased your NFT. 🙌",
                "https://dijaux.com/wp-content/uploads/2021/12/Bought_NFT_Email.png",
                `<div style="margin:10px 0;">
                <strong>Details:</strong>
                <br><br>
                <strong>Winning Price:</strong> <u>`+winningPrice+` `+props.currency+`</u>
                <br>
                <strong>Link:</strong> <u><a href="https://dijauxnfts.com/nft/`+id+`/`+props.currency+`">https://dijauxnfts.com/nft/`+id+`/`+props.currency+`</a></u>
                </div>`
              )
        }

        // Send email notification to user
        axios.post(props.APIUrl+"/email", emailContent).then((res) => {
          console.log("email sent")
        });

        // For the owner of NFT email notification
        axios.get(props.APIUrl+"/user/" + owner.toLowerCase()).then(response => {
            
          const owneremailContent = {
              email: response.data.email,
              subject: "You're NFT has been bought in auction!",
              message: emailFormatting(
                  props.creators.find(v => v.address.toLowerCase() == owner.toLowerCase()).name,
                  "Congrats on selling your NFT. 🙌",
                  "https://dijaux.com/wp-content/uploads/2021/12/Sold_NFT_Email.png",
                  `<div style="margin:10px 0;">
                  <strong>Details:</strong>
                  <br><br>
                  <strong>Winning Price:</strong> <u>`+winningPrice+` `+props.currency+`</u>
                  <br>
                  <strong>Link:</strong> <u><a href="https://dijauxnfts.com/nft/`+id+`/`+props.currency+`">https://dijauxnfts.com/nft/`+id+`/`+props.currency+`</a></u>
                  </div>`
                )
          }

          axios.post(props.APIUrl+"/email", owneremailContent).then((res) => {
            console.log("email sent");
          });
        });

        alertPopup('gottransaction');

      }).on('confirmation', async function(confirmationNumber, receipt){ 
                                
        if(confirmationNumber == 0) {

          props.setloading(false);
          alertPopup('nfthasbeentransferred', "https://dijauxnfts.com");

        }
      }).on('error', function(error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                                
        alertPopup('error', error.code);
        props.setloading(false);
      });
    });

  }