import React from 'react'
import convertETH from 'ether-converter'
import FontAwesome from 'react-fontawesome'
import {Tabs, Tab} from 'react-bootstrap-tabs';
//import Countdown from 'react-countdown'

import { 
    //AddToAuction, 
    //BidAuction, 
    finalizedAuction,
    buyNFT, 
    burnNFT, 
    toggleforsale
 } from '../web3actions'

export default function NFTSidebar(props) {
    return (
        <div className="col-12 col-xl-3">
            <div className="asset__info">
            
            {/* Start show buttons to owner both auction or not */}

            {(props.myaccount && (props.myaccount.confirmed || props.myaccount.confirmed === undefined)) && props.currency === props.card.currency && props.contract && !props.isCollection ? (
            props.auctionexists.includes(props.card.tokenid.toString()) ? ( // when art is in auction
                <React.Fragment>
                {props.auction.filter(auctionextras => auctionextras.tokenid == props.card.tokenid && props.account.toLowerCase() == auctionextras.seller.toLowerCase()).map((auctionextra, index) => (  // When the owner owns this NFT
                    <div className="asset__btns">
                    {props.arts.filter(auctions => auctions.tokenid == auctionextra.tokenid).map((auctionextralist, index) => (
                        <React.Fragment key={index}>  
                        <button 
                            key={index}
                            className={"asset__btn asset__btn--full asset__btn--clr mr-0 " + (props.loading || auctionextralist.modified ? "disable" : "" )} 
                            onClick={() => {finalizedAuction(props, props.artwork, auctionextra.auction_highestbidder, auctionextra.auction_highestbid)}}
                            >
                            {props.loading ? (
                                <div>
                                    <FontAwesome spin name="circle-o-notch" /> Confirming...
                                </div>
                            ) : (
                                <div>Finish Auction</div>
                            )}
                            </button>
                        </React.Fragment>
                    ))}
                    </div>
                ))}
                </React.Fragment>
            ) : ( // when art is not in auction
                <React.Fragment>
                {props.card.owner.toLowerCase() == props.account.toLowerCase() && props.card.tokenid < 1000000 ? ( // check if it's the current owner
                    props.card.forsale ? ( // check if it's currently for sale
                    <div className="asset__btns">
                    <button 
                        className={"asset__btn asset__btn--full asset__btn--clr mr-0 " + (props.loading || props.card.modified ? "disable" : "" )} 
                        onClick={() => {toggleforsale(props, props.card.tokenid) }}
                        >
                        {props.loading ? (
                            <div>
                                <FontAwesome spin name="circle-o-notch" /> Loading...
                            </div>
                        ) : (
                            <div>Remove From Sale</div>
                        )}
                        </button>
                        {/*
                        <button 
                        onClick={() => {openModalAuction(props.card.price)}}
                        className={"asset__btn asset__btn--full asset__btn--clr open-modal " + (props.loading || parseInt(props.card.last_auction) > (new Date().getTime())  ? "disable" : "" )}
                        >
                            {props.loading ? (
                                <div>
                                    <FontAwesome spin name="circle-o-notch" /> Verifying...
                                </div>
                            ) : (
                            
                                <Countdown date={props.card.last_auction} renderer={renderer} />
                            
                            )}
                        </button>*/}
                    </div>
                    ) : ( // when art is not for sale
                    <React.Fragment>
                        <div className="asset__btns">
                        <button 
                        className={"asset__btn asset__btn--full asset__btn--clr " + (props.loading || props.card.modified ? "disable" : "" )} 
                        onClick={() => {toggleforsale(props, props.artwork) }}
                        >
                        {props.loading ? (
                                <div>
                                    <FontAwesome spin name="circle-o-notch" /> Loading...
                                </div>
                            ) : (
                                <div>Put back to sale</div>
                            )}
                        </button>
                        </div>
                    {props.card.owner.toLowerCase() == props.account.toLowerCase() ? ( // when you're the owner and not for sale you can burn it
                        <div className="asset__btns">
                        <button 
                        className={"asset__btn asset__btn--full asset__btn--clr " + (props.loading || props.card.modified ? "disable" : "" )} 
                        onClick={() => {burnNFT(props, props.artwork) }}
                        >
                        {props.loading ? (
                                <div>
                                    <FontAwesome spin name="circle-o-notch" /> Loading...
                                </div>
                            ) : (
                                <div>Burn NFT</div>
                            )}
                        </button>
                        </div>
                    ) :null}
                    </React.Fragment>
                    )
                ) : null} 
                </React.Fragment>
            )
            ) : (
                !props.isCollection ?    
                    <div>If you're the owner of this NFT please switch network where it was minted. If you're still seeing this please confirm your email</div>
                : null
            )}   
            {/* End show buttons to owner both auction or not */}

            {props.account && props.card.owner.toLowerCase() == props.account.toLowerCase() && !props.auctionexists.includes(props.card.tokenid.toString()) && props.currency === props.card.currency && !props.isCollection && props.card.tokenid < 1000000 ? ( // Show change price for nft of you're the owner
            
            <div className="asset__btns mb-3">
                <button onClick={props.openModalPrice} className={"asset__btn asset__btn--full asset__btn--clr open-modal " + (props.loading || props.card.modified ? "disable" : "" )}>Change Token Price</button>
            </div>
            ) : null}

            <div className="asset__desc mt-3">
                <h2>Descriptions</h2>
                <p>{props.card.description}</p>
            </div>

            <ul className="asset__authors">
                <li>
                <span>Owned by</span>
                {!props.auctionexists.includes(props.card.tokenid.toString()) ? (
                    props.creators.filter(users => users.address.toLowerCase() == props.card.owner.toLowerCase()).map((user, index) => (
                    <div key={index} className="asset__author asset__author--verified">
                    <img src={user.picture} alt="" />
                    <a href={"/profile?address=" + user.address}>{user.name}</a>
                    </div>
                    ))
                ) : (
                    <React.Fragment>
                    {props.auction.filter(auctionextras => auctionextras.tokenid == props.card.tokenid).map((auctionextra, index) => (
                        props.creators.filter(users => users.address.toLowerCase() == auctionextra.seller.toLowerCase()).map((user, index) => (
                        <div key={index} className="asset__author asset__author--verified">
                        <img src={user.picture} alt="" />
                        <a href={"/profile?address=" + user.address}>{user.name}</a>
                        </div>
                        ))
                    ))}
                    </React.Fragment>
                )}
                </li>
            </ul>

            {/* tabs */}

            <Tabs className="asset__tabs d-block tabs-selection">
                <Tab label="History">
                <div className="asset__actions asset__actions--scroll" id="asset__actions--scroll">
                    {props.allActivities.filter(activity => activity.tokenid == props.card.tokenid && props.card.currency == activity.currency).map((v,i) => (
                    <React.Fragment key={i}>
                        {
                        !props.auctionexists.includes(props.card.tokenid.toString()) ? (
                            props.creators.filter(users => users.address.toLowerCase() == v.from.toLowerCase()).map((user, index) => (
                            <div key={index} className="asset__action asset__action--verified">
                            <img src={user.picture} alt="" />
                            <p>{props.activity_type(v.type)} by <a href={"/profile?address=" + user.address} className="history-tab-profile">{user.name}</a> for <b>{convertETH(v.amount.toString(),'wei','ether')} {props.card.currency}</b> <small className="text-secondary fw-bold">(${props.convertUSD(convertETH(v.amount.toString(),'wei','ether'),props.card.currency)})</small>
                            </p>
                            </div>
                            ))
                        ) : (  
                            props.auction.filter(auctionextras => auctionextras.tokenid == props.card.tokenid && props.account.toLowerCase() == auctionextras.seller.toLowerCase()).map((auctionextra, index) => (
                            props.creators.filter(users => users.address.toLowerCase() == v.from.toLowerCase()).map((user, index) => (
                                <div key={index} className="asset__action asset__action--verified">
                                <img src={user.picture} alt="" />
                                <p>{props.activity_type(v.type)} by <a href={"/profile?address=" + user.address} className="history-tab-profile">{user.name}</a> for <b>{convertETH(v.amount.toString(),'wei','ether')} {props.card.currency}</b> <small className="text-secondary fw-bold">(${props.convertUSD(convertETH(v.amount.toString(),'wei','ether'),props.card.currency)})</small>
                                </p>
                                </div>
                            ))
                            ))
                        )
                        }
                    </React.Fragment>
                    ))}
                    </div>
                </Tab>
                {/*<Tab label="Bids">
                <div className="asset__actions asset__actions--scroll">
                    {props.activities.filter(activities => activities.tokenid == props.artwork && activities.type == "bid" && props.card.currency == activities.currency ).map((activity, index) => (
                    props.creators.filter(users => users.address.toLowerCase() == activity.from.toLowerCase()).map((user, index) => (
                    <div key={index} className="asset__action asset__action--verified">
                        <img src={user.picture} alt="" />
                        <p>Bid placed for <b>{convertETH(activity.amount, 'wei','ether')} {props.card.currency}</b> <br />by <a href={"/profile?address=" + user.address}>@{user.name.replace(/\s+/g, '').toLowerCase()}</a></p>
                    </div>
                    ))
                    ))}
                    </div>
                </Tab>*/}
                <Tab label="Details">
                <ul className="asset__authors asset__authors--tab">
                    <li>
                    <span>Creator</span>
                    {props.creators.filter(users => users.address.toLowerCase() == props.card.creator.toLowerCase()).map((user, index) => (
                        <div key={index} className="asset__author asset__author--verified">
                        <img src={user.picture} alt="" />
                        <a href={"/profile?address=" + user.address}>{user.name}</a>
                        </div>
                    ))}
                    </li>
                    <li>
                    <span>Date created</span>
                    <p>{new Date(props.card.created_datetime).toLocaleDateString()}</p>
                    </li>
                </ul>
                </Tab>
                <Tab label={"Royalties (%" + props.card.royalties+")"}>
                <div className="asset__actions asset__actions--scroll">
                {props.activities.filter(activities => activities.tokenid == props.artwork && (activities.type == "bought" || activities.type == "owned") && activities.from != props.card.owner && props.card.currency == activities.currency ).map((activity, index) => (
                    props.creators.filter(users => users.address.toLowerCase() == activity.from.toLowerCase()).map((user, index) => (
                    <div key={index} className="asset__action asset__action--verified">
                    <img src={user.picture} alt="" />
                    <p>
                    {props.account && props.account.toLowerCase() == props.card.creator.toLowerCase() ? "You Earned " : "Creator Earned " }
                    <b>{convertETH(((parseInt(props.card.royalties) / 100) * parseInt(activity.amount)), 'wei', 'ether')} {props.card.currency}</b> from <a href={"/profile?address=" + user.address}>@{user.name.replace(/\s+/g, '').toLowerCase()}</a></p>
                    </div>
                    ))
                ))}
                </div>
                </Tab>

                {props.account && props.card.owner.toLowerCase() == props.account.toLowerCase() && props.card.unlockable_link != "" ? (
                <Tab label="Unlockables">
                    <React.Fragment>
                    <br/>
                    <strong>Since you're the owner you have unlockable content!</strong>
                    <a href={props.card.unlockable_link} target="_blank" class="asset__btn asset__btn--full asset__btn--clr d-flex justify-content-between"><span><span aria-hidden="true" class="fa fa-eye mr-2"></span> View File</span> <span aria-hidden="true" class="fa fa-external-link mr-2"></span></a>
                    </React.Fragment>
                </Tab>
                ) : (
                <React.Fragment></React.Fragment>
                )}
            </Tabs>
            {/* end tabs */}
            
            {props.myaccount && props.myaccount.name ? ( // Check if user is signed up
                props.card.forsale ? ( 
                props.auctionexists.includes(props.card.tokenid.toString()) ? ( // if in auction
                    <div className="w-100">
                    {props.auction.filter(auctionlist => auctionlist.tokenid == props.artwork).map((auctionextralist, index) => (
                        <React.Fragment>
                        <div className="asset__wrap">
                            <div className="asset__timer">
                            <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.3,8.59l.91-.9a1,1,0,0,0-1.42-1.42l-.9.91a8,8,0,0,0-9.79,0l-.91-.92A1,1,0,0,0,4.77,7.69l.92.91A7.92,7.92,0,0,0,4,13.5,8,8,0,1,0,18.3,8.59ZM12,19.5a6,6,0,1,1,6-6A6,6,0,0,1,12,19.5Zm-2-15h4a1,1,0,0,0,0-2H10a1,1,0,0,0,0,2Zm3,6a1,1,0,0,0-2,0v1.89a1.5,1.5,0,1,0,2,0Z"/></svg> Auction ends in</span>
                            <div className="">

                                {/*<Countdown date={parseInt(auctionextralist.auction_endtime)} className="asset__clock">
                                    <strong>Auction Ended!</strong>
                                </Countdown>*/}

                            </div>
                            </div>
            
                            <div className="asset__price">
                            <span>Minimum bid</span>
                            <span>{convertETH(props.card.price, 'wei','ether')} {props.card.currency} <small className="text-secondary fw-bold">(${props.convertUSD(convertETH(props.card.price, 'wei', 'ether'),props.card.currency)})</small></span>
                            </div>
                            <div className="asset__price">
                            <span>Highest bid</span>
                            <span>{convertETH((auctionextralist.auction_highestbid ? auctionextralist.auction_highestbid : 0), 'wei','ether')} {props.card.currency} <small className="text-secondary fw-bold">(${props.convertUSD(convertETH((auctionextralist.auction_highestbid ? auctionextralist.auction_highestbid : 0), 'wei','ether'),props.card.currency)})</small></span>
                            </div>
                        </div>
                        
                        {auctionextralist.seller.toLowerCase() != props.account.toLowerCase() ? (
                            parseInt(auctionextralist.auction_endtime) > (new Date().getTime()) ? ( // when auction is ended
                            <div className="asset__btns">
                                {props.currency == props.card.currency && !props.isCollection ? (
                                <button onClick={props.openModalBid} className={"asset__btn asset__btn--full asset__btn--clr open-modal " + (props.loading || props.card.modified ? "disable" : "" )}>Place a bid</button>
                                ) : (
                                    !props.isCollection ?
                                    <button className="asset__btn asset__btn--full asset__btn--clr disable">Switch network to bid</button>
                                    : null
                                )}
                                
                            </div>
                            ) : (
                            <div className="asset__btns">
                                <button className="asset__btn asset__btn--full asset__btn--clr open-modal disable">Auction ended</button>
                            </div>
                            )
                        ) : (
                            <div className="asset__btns">
                            <a href="#/" className="asset__btn asset__btn--full asset__btn--clr disable">Owned</a>
                            </div>
                        )}
                        </React.Fragment>
                    ))}
                    {/* end actions */}
                    </div>
                ) : ( // if only sale in fixed price
                    <div className="w-100">
                    <div className="asset__wrap">
                        <div className="asset__timer">
                        <span>Availability</span>
                        {!props.isCollection ? (
                            props.card.forsale ? (
                                <div className="text-dark">For Sale</div>
                            ) : (
                            <div className="text-dark">Reserved</div>
                            )
                        ) : (
                            <div className="text-dark">After major update</div>
                        )}
                        </div>
                        <div className="asset__price">
                        <span>Fixed Price</span>
                        <span>{convertETH(props.card.price, 'wei','ether')} {props.card.currency} <small className="text-secondary fw-bold">(${props.convertUSD(convertETH(props.card.price, 'wei','ether'),props.card.currency)})</small></span>
                        </div>
                    </div>
        
                    {/* actions */}
                    {props.myaccount && props.myaccount.confirmed === false ? (
                        <button
                        className={"asset__btn asset__btn--full asset__btn--clr bg-danger disable"}
                        >
                        Please confirm your email
                        </button>
                    ) : (
                        <React.Fragment>
                        {props.card.owner.toLowerCase() != props.account.toLowerCase() ? (
                        <div className="asset__btns">
                            {props.currency == props.card.currency && props.contract && !props.isCollection ? (
                            <button
                            className={"asset__btn asset__btn--full asset__btn--clr " + (props.loading || props.card.modified ? "disable" : "" )}
                            onClick={() => {buyNFT(props, props.artwork, props.card.price, props.card.owner)}}
                            >
                                {props.loading ? (
                                    <div>
                                        <FontAwesome spin name="circle-o-notch" /> Loading...
                                    </div>
                                ) : (
                                    <div>Buy Now</div>
                                )}
                            </button>
                            ) : (
                                !props.isCollection ?
                                <   button
                                    className={"asset__btn asset__btn--full asset__btn--clr disable"}
                                    >
                                    Switch network to buy
                                    </button>
                                : null
                            )}
                            
                        </div>
                        ) : (
                        <div className="asset__btns">
                            <a href="#/" className="asset__btn asset__btn--full asset__btn--clr disable">Owned</a>
                        </div>
                        )}
                        </React.Fragment>
                    )}
                    
                    {/* end actions */}
                    </div>
                )
            ) : (
                <div className="w-100">
                    <div className="asset__wrap">
                    <div className="asset__timer">
                        <span>Availability</span>
                        {!props.isCollection ? (
                            props.card.forsale ? (
                                <div className="text-dark">For Sale</div>
                            ) : (
                            <div className="text-dark">Reserved</div>
                            )
                        ) : (
                            <div className="text-dark">After major update</div>
                        )}
                    </div>
                    <div className="asset__price">
                        <span>Reserved price</span>
                        <span>{convertETH(props.card.price, 'wei','ether')} {props.card.currency} <small className="text-secondary fw-bold">(${props.convertUSD(convertETH(props.card.price, 'wei','ether'),props.card.currency)})</small></span>
                    </div>
                    </div>
        
                    {/* actions */}
                    <div className="asset__btns">
                    <a href="#modal-bid" className="disable asset__btn asset__btn--full asset__btn--clr open-modal">Reserved</a>
                    </div>
                    {/* end actions */}
                </div>
            )


        ) : ( // Show sign up otherwise when not signed up
        <div className="w-100">
            <div className="asset__wrap">
            <div className="asset__btns">
                {!props.noProvider ? (
                    <a href="/signup" className="asset__btn asset__btn--full asset__btn--clr">Sign up</a>
                ) : (
                    <a href="#/" className="asset__btn asset__btn--full asset__btn--clr disable">Please install Metamask</a>
                )}
                
            </div>
            </div>
        </div>
        )} {/* check if the user already sign up*/}
            
            </div>
        </div>
    );
}