import {useState, useEffect} from 'react'
import { sort } from 'fast-sort';
import axios from 'axios';
import Moments from './moments'

export function Activities(props)  { 

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [filterToggle, setfilterToggle] = useState(false);

  useEffect(() => {
    const endOffset = itemOffset + 8;
    setCurrentItems(sort(props.activities).desc(u => u.time).slice(itemOffset, endOffset));
    setPageCount(Math.ceil(sort(props.activities).desc(u => u.time).length / 8));

  }, [props.activities, itemOffset, 8]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 8) % sort(props.activities).desc(u => u.time).length;
    setItemOffset(newOffset);
  };

  const applyFilter = async (e) => {

    const endOffset = itemOffset + 8;
    const filterVal = Array.from(document.querySelectorAll("input[type=checkbox][name=activities]:checked")).map(e => e.value)
    setCurrentItems(filterVal.length > 0 ? props.activities.filter(key => filterVal.includes(key.type)).slice(itemOffset, endOffset) : sort(props.activities).desc(u => u.time).slice(itemOffset, endOffset));
    setPageCount(filterVal.length > 0 ? Math.ceil(props.activities.filter(key => filterVal.includes(key.type)).length / 6) : Math.ceil(sort(props.activities).desc(u => u.time).length / 6));

  }

  function toggleFilter() {
    setfilterToggle(filterToggle === true ? false : true);
  }

  const clearFilter = () => {
    document.querySelectorAll("input[type=checkbox][name=activities]:checked").forEach( el => el.checked = false );
    setCurrentItems(sort(props.activities).desc(u => u.time))
  }

  const activity_type = (type) => {
      var text = "";
      if(type == "minted") {
        text = "Minted";
      }else if(type == "listed") {
        text = "Listed in auction";
      }else if(type == "bid") {
        text = "Placed bid";
      }else if(type == "bought") {
        text = "Bought";
      }else if(type == "owned") {
        text = "Owned in auction";
      }else if(type == "sold") {
        text = "Sold by seller";
      }else if(type == "changeprice") {
        text = "Updated the price";
      }else if(type == "removesale") {
        text = "Removed in sale";
      }else if(type == "forsale") {
        text = "Put to sale";
      }
      
      return text;
  }
    
  const sortActivities = (val) => {
    if(val == "latest") {
      setCurrentItems(sort(props.activities).desc(u => u.time))
    }else if(val == "oldest") {
      setCurrentItems(sort(props.activities).asc(u => u.time))
    }
  }

    return (
      <main className="main">
        
          <div className="container-fluid px-md-5">
              <div className="row row--grid">

                  {/* title */}
                  <div className="col-12">
                      <div className="main__title main__title--page">
                          <h1>Activity</h1>
                      </div>
                  </div>
                  {/* end title */}
              </div>

              <Moments 
                {...props}
                handlePageClick={handlePageClick}
                applyFilter={applyFilter}
                currentItems={currentItems}
                pageCount={pageCount}
                sortActivities={sortActivities}
                activity_type={activity_type}
                clearFilter={clearFilter}
                toggleFilter={toggleFilter}
              />
          </div>
      </main>
    );
  }