// Abis
import DijauxABI from '../../../../src/abis/DijauxAuction.json'

export const checkContractAddress = (currency) => {

    const listOfContracts = {
        "BSC": {
            networkId: 97
        }
    }

    return DijauxABI.networks[listOfContracts[currency].networkId].address;

    
}