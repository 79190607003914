import React, {useState, useEffect} from 'react'
import { sort } from 'fast-sort';

// Template essentials
import Hero from './hero'
//import Auction from './auction'
import Explore from './explore'
import Creators from './creators'
import Getstarted from './getstarted'
import Collections from './collections'

export function Home(props) {

  const [allLikes, setLikes] = useState([]);
  const [allFollows, setFollows] = useState([]);
  const [allArts, setArts] = useState([]);
  const [allCreators, setCreators] = useState([]);

  useEffect(()=>{

    setLikes(props.likes)
    setFollows(props.follows)
    setArts(sort(props.arts).desc(u => u.modifieddate))
    setCreators(sort(props.creators).desc(u => u._id))
  }, [props.follows, props.likes])
  
    return (
      <main className="main">

        <Hero 
            {...props}
            allLikes={allLikes}
            allArts={allArts}
            NFTcount={props.NFTcount}
            setLikes={setLikes}
        />
        {/*<div className="container-fluid bg-danger">
          <div className="p-5 text-center">
            <h3>To all artists that has existing NFTs in Dijaux, We are currently having a major update that makes this site in maintenance for now. We will update you soon. Thank you !</h3>
          </div>
        </div>*/}
        
        <div className="container-fluid px-md-5 ">

          <section className="row row--grid py-5">
            <div className="col-12 text-center">    
              <h3 className="container text-danger">
                To all artists that has existing NFTs in Dijaux, We are currently having a major update that makes this site in maintenance for now. We will update you soon. Thank you ! All old NFTs and Profiles will be restored once we're done testing.
                </h3>
            </div>
          </section>
          
          {/*<Auction
            {...props}
            allLikes={allLikes}
            allArts={allArts}
            setLikes={setLikes}
          />*/}

          <Explore
            {...props}
            allLikes={allLikes}
            allArts={allArts}
            setLikes={setLikes}
          />

          {/*<Collections
            {...props}
          />*/}
          
          <Creators
            {...props}
            allCreators={allCreators}
            allFollows={allFollows}
            allArts={allArts}
            setFollows={setFollows}
          />

          <Getstarted />
        </div>
      </main>
      );
}