import React from 'react'
import ReactPaginate from 'react-paginate';
import Discplaying from '../../../main/assets/img/discplaying.gif';
import convertETH from 'ether-converter';

export default function Moments(props) {

    function Items({ currentItems }) {
        return (
          <>
            {currentItems && currentItems.map((activity, index) => ( // All Activities
              props.arts.filter(cards => cards.tokenid == activity.tokenid && cards.currency == activity.currency).map((card, index) => (
                props.creators.filter(users => users.address.toLowerCase() == activity.from.toLowerCase()).map((user, index) => (
                <div key={index} data-cat={activity.type} data-time={parseInt(activity.time)} className={"col-12 col-lg-4 sorted-items"}>
                  <div className="activity">
                    <a href={"/nft/"+ card.tokenid + "/" + card.currency} className="activity__cover">
                      
                      {['mp3', 'wav'].includes(card.image.split('.').pop()) ? (
                          <React.Fragment>
                            <div className="floating-text-loop">Audio</div>
                            <img src={Discplaying} alt="" />
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {['mp4', 'ogg', 'webm', 'mov'].includes(card.image.split('.').pop()) ? (
                                <React.Fragment>
                                  <div className="floating-text-loop">Video</div>
                                  <video autoPlay loop muted playsInline>
                                    <source src={ card.image } type={"video/" + card.image.split('.').pop()} />
                                    Your browser does not support the video tag.
                                  </video>
                                </React.Fragment>
                            ) : (
                              <img src={card.image } alt="" />
                            )}
                          </React.Fragment>
                        )}
                    </a>
                    <div className="activity__content">
                      <h3 className="activity__title"><a href={"/nft/"+ card.tokenid + "/" + card.currency}>{card.name}</a></h3>
                      <p className="activity__text">{props.activity_type(activity.type)} for <b>{convertETH(activity.amount.toString(),'wei','ether')} {card.currency}</b> <small className="text-secondary fw-bold">(${props.convertUSD(convertETH(activity.amount.toString(),'wei','ether'),card.currency)})</small><br/> by <a href={"/profile?address="+user.address}><img src={user.picture} className="img-fluid rounded-3 mr-1" width="35" height="35" /> {user.name}</a></p>
                      <span className="activity__time">{props.time2TimeAgo(Math.round(activity.time / 1000))}</span>
                    </div>
                  </div>
                </div>
                ))
              ))
          ))}
          </>
        );
    }
    return (
        <div className="row">
            {/* sidebar */}
            <div className="col-12 col-xl-2">
                <div className="filter-wrap">
                    <button className="filter-wrap__btn" onClick={props.toggleFilter}>Open filter</button>

                    <div className={"collapse filter-wrap__content p-3 " + (props.filterToggle === true ? 'show' : '' )} id="collapseFilter">
                        {/* filter */}
                        <div className="filter filter--sticky">
                            <h4 className="filter__title">Filters <button type="button" onClick={props.clearFilter}>Clear all</button></h4>


                            <hr />
                            <h4 className="filter__title">Sort by</h4>
                            <div className="filter__group">
                                <div className="sign__group">
                                    <select id="sort-by" name="sort-by" className="sign__select" onChange={(values) => {
                                    props.sortActivities(values.target.value);
                                    }}>
                                        <option value="latest">Latest</option>
                                        <option value="oldest">Oldest</option>
                                    </select>
                                </div>
                            </div>

                            <div className="filter__group">
                                <ul className="filter__checkboxes">
                                    <li>
                                        <input id="type5" type="checkbox" name="activities" value="listed" onClick={props.applyFilter} />
                                        <label htmlFor="type5">New Listings</label>
                                    </li>
                                    <li>
                                        <input id="type6" type="checkbox" name="activities" value="bid" onClick={props.applyFilter} />
                                        <label htmlFor="type6">Bids</label>
                                    </li>
                                    <li>
                                        <input id="type7" type="checkbox" name="activities" value="bought" onClick={props.applyFilter} />
                                        <label htmlFor="type7">Collected</label>
                                    </li>
                                    <li>
                                        <input id="type8" type="checkbox" name="activities" value="minted" onClick={props.applyFilter}/>
                                        <label htmlFor="type8">Minted</label>
                                    </li>
                                    <li>
                                        <input id="type10" type="checkbox" name="activities" value="owned" onClick={props.applyFilter} />
                                        <label htmlFor="type10">Won Auctions</label>
                                    </li>
                                    <li>
                                        <input id="type11" type="checkbox" name="activities" value="sold" onClick={props.applyFilter} />
                                        <label htmlFor="type11">Sold</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* end filter */}
                    </div>
                </div>
            </div>
            {/* end sidebar */}

            {/* content */}
            <div className="col-12 col-xl-10">
                <div className="row row--grid">
                <Items currentItems={props.currentItems} />  
                </div>
                <div className="row row--grid mt-5">
                <div className="col-12 d-flex justify-content-end">
                    <ReactPaginate
                        nextLabel="next"
                        onPageChange={props.handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={props.pageCount}
                        previousLabel="previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />   
                </div>     
                </div>
            </div>
            {/* end content */}
        </div>	
    );
}