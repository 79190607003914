export default function Followers(props) {
    return (
        <div className="row row--grid">
            {props.allFollows.filter(follows => follows.address.toLowerCase() == props.address.toLowerCase()).map((follow, i) => ( 
                props.creators.filter(artists => artists.address.toLowerCase() == follow.follower.toLowerCase()).map((artist, i) => ( 
                    <div key={i} className="col-12 col-md-6 col-xl-4">
                    <div className="author">
                    <a href={"/profile?address=" + artist.address} className="author__cover author__cover--bg" style={{
                        backgroundImage: `url(${artist.cover})`,
                        backgroundPosition: `center center`,
                        backgroundRepeat: `no-repeat`,
                        backgroundSize: `cover`
                    }}>
                    </a>
                    <div className="author__meta">
                        <a href={"/profile?address=" + artist.address} className="author__avatar author__avatar--verified">
                        <img src={artist.picture} alt="" width="60" height="60" />
                        </a>
                        <h3 className="author__name"><a href={"/profile?address=" + artist.address}>{artist.name}</a></h3>
                        <h3 className="author__nickname"><a href={"/profile?address=" + artist.address}>@{artist.name.replace(/\s+/g, '').toLowerCase()}</a></h3>
                        <p className="author__text">{artist.description}</p>
                        <div className="author__wrap">
                        <div className="author__followers">
                            <p>{props.allFollows.filter(r => r.address == artist.address).length}</p>
                            <span>Followers</span>
                        </div>
                        
                        </div>
                    </div>
                    </div>
                </div>
                ))  
            ))}     
            </div>
    );
}