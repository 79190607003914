import React, {useState} from 'react'; 
import Discplaying from '../../main/assets/img/discplaying.gif';
export function NotFoundPage(props) {

  const [ togglesearch, setsearch ] = useState(false);
  const [ searchArts, setsearchArts ] = useState(false);
  const [ searchArtists, setsearchArtists ] = useState(false);
  const [ searchLoad, setsearchLoad ] = useState(false);

  const openSearch = (event) => {
    setsearch((togglesearch == false) ? true : false);
    setsearchLoad(false);
  }

  const searchAllSite = async (e) => {
    setsearchLoad(true);

    const searchValue = e.target.value.toLowerCase();

    if(searchValue == "") {
      setsearchLoad(false);
    }

    const artsResults = await props.arts.filter(function(el,i) { // All arts results
      return el.name.toLowerCase().indexOf(searchValue) > -1 || el.description.toLowerCase().indexOf(searchValue) > -1 
    });

    setsearchArts(artsResults == "" ? false : artsResults)

    const artistsResults = await props.creators.filter(function(el) { // All artists results
      return el.name.toLowerCase().indexOf(searchValue) > -1 || el.description.toLowerCase().indexOf(searchValue) > -1 
    });

    setsearchArtists(artistsResults == "" ? false : artistsResults)
  }

  return (
    <div class="d-flex align-items-center justify-content-center vh-100 py-5 bg-semilight">
        <div class="text-center py-5 mt-5">
            <h1 class=" fw-bold">404</h1>
            <p class="fs-3"> <span class="text-danger">Opps!</span> Page not found.</p>
            <p class="lead">
                The page you’re looking for doesn’t exist. You can try searching below.
              </p>

              <a href="/explore" class="home__btn home__btn--clr m-0 rainbow-animated">Explore NFTs</a>
              <br/>
              <br/>
              OR

              <form action="#" className={"m-auto header__search " + (togglesearch == true ? "header__search--active" : "")}>
              <input type="text" id="main-searchbar" placeholder="Search items, collections, and creators" onChange={searchAllSite} autoComplete='off' />
              <button type="button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg></button>
              <button type="button" className="close" onClick={openSearch}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg></button>

              <div className={searchLoad ? 'search-results-floating show' : 'search-results-floating'} >
                <h5>NFTs</h5>
                <hr />
                {!searchArts ? (
                  <React.Fragment>
                    No results found in NFT...
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {searchArts.map((val,i) => (
                      <div key={i}>
                        <a href={"/nft/" + val.tokenid + "/" + val.currency} className="d-flex text-dark">
                          <div className="img-container mr-3">
                            
                            {['mp3', 'wav', 'ogg'].includes(val.image.split('.').pop()) ? (
                                <React.Fragment>
                                  <img src={Discplaying} alt="" width="50" height="50"  />
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  {['mp4', 'ogv', 'webm', 'avi', 'mov'].includes(val.image.split('.').pop()) ? (
                                      <React.Fragment>
                                        <video width="50" height="50" className="search-videos" autoPlay loop muted playsInline>
                                          <source src={ val.image } type={"video/" + val.image.split('.').pop()} />
                                          Your browser does not support the video tag.
                                        </video>
                                      </React.Fragment>
                                  ) : (
                                    <img src={val.image} width="50" height="50" className="rounded-3" />
                                  )}
                                </React.Fragment>
                              )}
                          </div>
                          <div className="information">
                            <strong>{val.name}</strong>
                            <p><small>{val.description.substring(0,40) + "..."}</small></p>
                          </div>
                        </a>
                      </div>
                    ))}
                  </React.Fragment>
                )}
                <hr />
                <h5>Artists</h5>
                <hr />
                {!searchArtists ? (
                  <React.Fragment>
                    No results found in NFT...
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {searchArtists.map((val,i) => (
                      <div key={i}>
                        <a href={"/profile?address=" + val.address} className="d-flex text-dark">
                          <div className="img-container mr-3">
                            <img src={val.picture} width="50" height="50" className="rounded-3" />
                          </div>
                          <div className="information">
                            <strong>{val.name}</strong>
                            <p><small>{val.description.substring(0,40) + "..."}</small></p>
                          </div>
                        </a>
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </form>  
            
        </div>
    </div>
  );
}