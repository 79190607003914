import React, { useState, useEffect } from 'react'
import FontAwesome from 'react-fontawesome'

import { useParams } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { sort } from 'fast-sort';
import { Likes } from '../../actions'
import { emailFormatting, alertPopup, time2TimeAgo } from '../../utils'
import NFTView from './nftview'
import NFTSidebar from './nftsidebar'
import OtherNFTs from './othernfts'
import ModalActions from './modals'

import { changeTokenPrice } from './web3actions'

export function Artwork(props) {

  const isCollection = props.isCollection;

  const { id, currency } = useParams();

  var artwork = parseInt(id);
  const selectedCurrency = currency;

  const [newNFTprice, setnewNFTprice] = useState('');
  const [loading, setloading] = useState('');

  const [PriceModal, setPriceModal] = useState(false);
  const [auctiontime, setAuctionTime] = useState(5);
  const [AuctionModal, setAuctionModal] = useState(false);
  const [BidModal, setBidModal] = useState(false);
  const [startPrice, setstartPrice] = useState('');
  //const [ endDate, setendDate ] = useState('');
  //const [ endTime, setendTime ] = useState('');

  const [BidPrice, UpdateBidPrice] = useState('');
  const [allActivities, setActivities] = useState([]);
  const [allLikes, setLikes] = useState([]);
  const [allNFTS, setThisNFT] = useState([]);

  useEffect(() => {

    axios.get(props.APIUrl + "/nfts/").then(async (response) => {
      if (response.data) {
        if (response.data.filter(key => !key.removed && key.tokenid == id).length > 0) {
          setThisNFT(response.data.filter(key => !key.removed))
        } else {
          alertPopup('nftnotexist', "https://dijauxnfts.com");
        }

        const nftChecker = await props.contract.methods.allDijauxPieces(artwork).call(); // Method is from contracts public string
        const nftDatabase = response.data.find(key => key.tokenid == artwork);

        const contractForSale = nftChecker.forsale;
        const databaseForSale = nftDatabase.forsale;
        
        // check if same forsale
        if (contractForSale != databaseForSale) {
          axios.post(props.APIUrl + "/nftupdate/" + artwork + "/" + props.currency, { forsale: contractForSale }).then((res) => {
            console.log("nft refreshed")
            axios.get(props.APIUrl + "/nfts/").then((response) => {
              if (response.data) {
                setThisNFT(response.data)
              }
            });
          });
        }

      }
    });

    setLikes(props.likes)
    setActivities(sort(props.activities).desc(u => u.time))



  }, [props.arts, props.activities, props.likes])

  const copyLink = () => {
    var link = document.getElementById("copyLinkUrl");
    link.select();
    link.setSelectionRange(0, 99999); /* For mobile devices */
    navigator.clipboard.writeText(link.value);

    swal({
      title: "Copy to clipboard",
      icon: 'success',
    }).then((value) => {
      swal.close();
    })
  }

  function closeModalAuction() {
    setAuctionModal(false);
  }

  const updateAuctionTime = (event) => {
    setAuctionTime(parseInt(event.target.value));
  }

  function openModalAuction(price) {
    setAuctionModal(true);
    setstartPrice(price);
  }

  function openModalBid() {
    setBidModal(true);
  }

  function closeModalBid() {
    setBidModal(false);
  }

  function openModalPrice() {
    setPriceModal(true);
  }

  function closeModalPrice() {
    setPriceModal(false);
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return "Open Auction";
    } else {
      // Render a countdown
      return <span>{days}:{hours}:{minutes}:{seconds} Time remaining</span>;
    }
  };

  const activity_type = (type) => {
    var text = "";
    if (type == "minted") {
      text = "Minted";
    } else if (type == "listed") {
      text = "Listed in auction";
    } else if (type == "bid") {
      text = "Placed bid";
    } else if (type == "bought") {
      text = "Bought";
    } else if (type == "owned") {
      text = "Owned in auction";
    } else if (type == "sold") {
      text = "Sold by seller";
    } else if (type == "changeprice") {
      text = "Updated the price";
    } else if (type == "removesale") {
      text = "Removed in sale";
    } else if (type == "forsale") {
      text = "Put to sale";
    }
    return text;
  }

  const processTransactionConfirmation = async (transactionHash, tokenID, method) => {

    var popupTitle = "";
    var popupText = "";

    if (method == "toggleforsale") {
      popupTitle = "Updated status confirmed";
      popupText = "You already have updated your NFT sale status";
    } else if (method == "changeTokenPrice") {
      popupTitle = "Price Changed";
      popupText = "You're NFT has now updated price.";
    } else if (method == "buyNFT") {
      popupTitle = "You owned this NFT";
      popupText = "You now have privilege to do anything to your NFT. Contratulations";
    } else if (method == "burnNFT") {
      popupTitle = "You have successfully burn your NFT Token";
      popupText = "ou just burnt your NFT.";
    }

    if (method == "burnNFT") {
      console.log('NFT is now updated!')
      axios.get(props.APIUrl + "/nfts/").then((response) => {
        if (response.data) {
          setThisNFT(response.data)
          setloading(false);
          swal({
            title: popupTitle,
            text: popupText,
            icon: "success",
            buttons: false,
            closeOnClickOutside: false,
            timer: 2000,
          }).then((value) => {
            swal.close();
            window.location = "https://dijauxnfts.com";
          }).catch(err => {
            swal("Oops!", "Seems there's an issue. Please contact administrator", "error");
          });
        }
      });
    }

    // Start of syncing
    const txnHash = transactionHash;
    const status = await props.web3.eth.getTransactionReceipt(txnHash);

    if (status) {
      const dijauxnft = await props.contract.methods.allDijauxPieces(tokenID).call() // Method is from contracts public string
      const ownerNFt = await props.contract.methods.ownerOf(tokenID).call();
      const tokenURI = await props.contract.methods.tokenURI(tokenID).call();

      axios.get(props.BaseIPFSUrl + tokenURI).then(function (response) {

        // Sync and update the data from blockchain to this NFT in database
        response.data['creator'] = dijauxnft.creator;
        response.data['owner'] = ownerNFt;
        response.data['forsale'] = dijauxnft.forsale;
        response.data['price'] = dijauxnft.price;
        response.data['royalties'] = dijauxnft.royalties;
        response.data['tokenid'] = Number(tokenID);
        response.data['metadata'] = tokenURI;
        response.data['asset'] = response.data.image;
        response.data['image'] = props.BaseIPFSUrl + response.data.image.split("ipfs://")[1];
        response.data['modified'] = false;

        axios.post(props.APIUrl + "/nftupdate/" + tokenID + "/" + props.currency, response.data).then(response => {
          console.log('NFT is now updated!')
          axios.get(props.APIUrl + "/nfts/").then((response) => {
            if (response.data) {
              setThisNFT(response.data)

              if (status.status) {
                axios.post(props.APIUrl + "/activityupdate/" + txnHash, { active: true });
              }

              setloading(false);
              swal({
                title: popupTitle,
                text: popupText,
                icon: "success",
                buttons: false,
                closeOnClickOutside: false,
                timer: 2000,
              }).then((value) => {
                swal.close();
                window.location.href = "/";
              }).catch(err => {
                swal("Oops!", "Seems there's an issue. Please contact administrator", "error");
              });
            }
          });
        });
      })
    }
    // End of syncing
  }

  return (
    <main className="main">
      <div className="container-fluid px-md-5">
        {allNFTS.filter(cards => cards.tokenid == artwork && selectedCurrency == cards.currency).map((card, i) => (
          <React.Fragment key={i}>
            <div>
              <div className="row row--grid">

                {/* title */}
                <div className="col-12">
                  <div className="main__title main__title--page">
                    <div>
                      <h1>{card.name}</h1>
                      <span className="mt-3 activity__time">Minted {time2TimeAgo(Math.round(card.created_datetime / 1000))} ago</span>

                      <div className="mt-3">
                        {card.category.split(',').map((v) => [
                          <span className="category-balloon"><a href={"/discover?cat=" + v}>{v}</a></span>
                        ])}
                      </div>
                    </div>
                  </div>
                  {card.modified === true ? (
                    <div className="text-danger"><strong>Last transaction for this NFT is still pending and this will update after transaction is confirmed. Please wait. <FontAwesome spin name="circle-o-notch" /></strong></div>
                  ) : null}
                </div>
                {/* end title */}
              </div>

              <div className="row">
                {/* content */}
                <NFTView
                  {...props}
                  card={card}
                  copyLink={copyLink}
                  Likes={Likes}
                  allLikes={allLikes}
                  setLikes={setLikes}
                  isCollection={isCollection}
                />
                {/* end content */}

                {/* sidebar */}
                <NFTSidebar
                  {...props}
                  card={card}
                  isCollection={isCollection}
                  loading={loading}
                  artwork={artwork}
                  openModalPrice={openModalPrice}
                  allActivities={allActivities}
                  activity_type={activity_type}
                  openModalBid={openModalBid}
                  setloading={setloading}
                  id={id}
                  setThisNFT={setThisNFT}
                  processTransactionConfirmation={processTransactionConfirmation}
                />
                {/* end sidebar */}
              </div>
            </div>

            <section className="mt-5 row row--grid">
              <div className="col-md-4">
                {props.metamaskConnected ? (
                  props.currency == card.currency ? (
                    <a href={props.scanURL + "/" + props.checkContractAddress(card.currency) + "?a=" + card.tokenid} target="_blank" className="asset__btn asset__btn--full asset__btn--clr d-flex justify-content-between"><span><FontAwesome name="qrcode mr-2" /> View scan</span> <FontAwesome name="external-link mr-2" /></a>
                  ) : (
                    <a href="#/" className="asset__btn asset__btn--full asset__btn--clr d-flex justify-content-between disable">Switch network to view scan</a>
                  )

                ) : (
                  <a href="#/" target="_blank" className="asset__btn asset__btn--full disable asset__btn--clr d-flex justify-content-between"><span><FontAwesome name="qrcode mr-2" /> Connect to View Etherscan</span> <FontAwesome name="external-link mr-2" /></a>
                )}
              </div>
              <div className="col-md-4">
                <a href={props.BaseIPFSUrl + card.metadata} target="_blank" className="asset__btn asset__btn--full asset__btn--clr d-flex justify-content-between"><span><FontAwesome name="eye mr-2" /> View Metadata</span> <FontAwesome name="external-link mr-2" /></a>
              </div>
              <div className="col-md-4">
                <a href={card.asset.replace("ipfs://", props.BaseIPFSUrl)} target="_blank" className="asset__btn asset__btn--full asset__btn--clr d-flex justify-content-between"><span><FontAwesome name="cube mr-2" />View IPFS Asset</span> <FontAwesome name="external-link mr-2" /></a>
              </div>
            </section>
          </React.Fragment>
        ))}
        {!isCollection ?
          <section className="row row--grid">
            <div className="col-12">
              <div className="main__title main__title--border-top">
                <h2><a href="explore.html">Other author assets</a></h2>
              </div>
            </div>
            <div className="col-12">
              <div className="main__carousel-wrap">
                <div className="row row--grid">
                  <OtherNFTs
                    {...props}
                    allArts={props.arts.filter(cards => cards.owner.toLowerCase() == props.arts.find(c => c.tokenid == artwork && c.currency == currency).owner.toLowerCase() && cards.tokenid != artwork).slice(0, 8)}
                    allLikes={allLikes}
                    setLikes={setLikes}
                  />

                </div>
              </div>
            </div>

          </section>
          : null
        }
      </div>

      <ModalActions
        {...props}
        PriceModal={PriceModal}
        closeModalPrice={closeModalPrice}
        setnewNFTprice={setnewNFTprice}
        newNFTprice={newNFTprice}
        loading={loading}
        changeTokenPrice={changeTokenPrice}
        artwork={artwork}
        setThisNFT={setThisNFT}
        processTransactionConfirmation={processTransactionConfirmation}
      />

    </main>
  );
}