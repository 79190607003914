import LoadingIcon from '../main/assets/img/loading.gif';

export const METAMASK_POSSIBLE_ERRORS = 
{
    '-32700': 
    {
        standard: 'JSON RPC 2.0',
        message: 'Invalid JSON was received by the server. An error occurred on the server while parsing the JSON text.',
    },
    '-32600': 
    {
        standard: 'JSON RPC 2.0',
        message: 'The JSON sent is not a valid Request object.',
    },
    '-32601': 
    {
        standard: 'JSON RPC 2.0',
        message: 'The method does not exist / is not available.',
    },
    '-32602': 
    {
        standard: 'JSON RPC 2.0',
        message: 'Invalid method parameter(s).',
    },
    '-32603': 
    {
        standard: 'JSON RPC 2.0',
        message: 'Internal JSON-RPC error.',
    },
    '-32000': 
    {
        standard: 'EIP-1474',
        message: 'Invalid input.',
    },
    '-32001': 
    {
        standard: 'EIP-1474',
        message: 'Resource not found.',
    },
    '-32002': 
    {
        standard: 'EIP-1474',
        message: 'Resource unavailable.',
    },
    '-32003': 
    {
        standard: 'EIP-1474',
        message: 'Transaction rejected.',
    },
    '-32004': 
    {
        standard: 'EIP-1474',
        message: 'Method not supported.',
    },
    '-32005': 
    {
        standard: 'EIP-1474',
        message: 'Request limit exceeded.',
    },
    '4001': 
    {
        standard: 'EIP-1193',
        message: 'User rejected the request.',
    },
    '4100': 
    {
        standard: 'EIP-1193',
        message: 'The requested account and/or method has not been authorized by the user.',
    },
    '4200': 
    {
        standard: 'EIP-1193',
        message: 'The requested method is not supported by this Ethereum provider.',
    },
    '4900': 
    {
        standard: 'EIP-1193',
        message: 'The provider is disconnected from all chains.',
    },
    '4901': 
    {
        standard: 'EIP-1193',
        message: 'The provider is disconnected from the specified chain.',
    },
}  

export const ALERT_POPUP_CODES = {
    'mint': 
    {
        title: 'Yeah',
        text: 'ahihi',
        icon: LoadingIcon,
        error: "Seems there's an issue on your minting. Please contact administrator"
    },
    'wrongformat': 
    {
        title: "Wrong format or file is too big",
        text: "Make sure to have below 10MB file size for optimization. You can add the full size resolution on unlockable file. Please only upload accepted image types: jpg, jpeg, png, gif, mp3, wav, mp4, ogg, webm, mov",
        icon: "warning",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'wrongformatprofile': 
    {
        title: "Wrong format or file is too big",
        text: "Make sure to have below 10MB file size for optimization. You can add the full size resolution on unlockable file. Please only upload accepted image types: jpg, jpeg, png",
        icon: "warning",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'profilerequired': 
    {
        title: "Registration failed",
        text: "Profile picture, cover photo, email and nickname are all required! Please try again... reloading",
        icon: "warning",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'emailexist': 
    {
        title: "Email address is already in use!",
        text: "Your email is already registered in our platform. Try using another email.",
        icon: "warning",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'mintrequired': 
    {
        title: "Missing required fields",
        text: "NFT File, price, category, description and NFT title are all required! Please try again... reloading",
        icon: "warning",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'mintprocess': 
    {
        title: "Please wait",
        text: "We are currently uploading your NFT. PLEASE DO NOT CLOSE YOUR BROWSER YET until you see another popup. You're NFT will come up once your transaction is complete.",
        icon: LoadingIcon,
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'lazymintprocess': 
    {
        title: "Please wait processing...",
        text: "PLEASE DON'T CLOSE THIS BROWSER UNTIL NEXT TRANSACTION SHOWS UP TO AVOID DATA ISSUES.",
        icon: LoadingIcon,
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'mintprocessfree': 
    {
        title: "Please wait",
        text: "We are currently uploading your NFT. Please sign your account to allow us process future transactions.",
        icon: LoadingIcon,
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'gottransaction': 
    {
        title: "Got your transaction!",
        text: "Please wait while we're processing your transaction... You can now close your browser and wait for confirmation",
        icon: LoadingIcon,
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'transactionconfirmed': 
    {
        title: "Transaction Confirmed!",
        text: "Thank you for waiting! You can now reload this page.",
        icon: "success",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'mintsuccess': 
    {
        title: "Minted successful",
        text: "Waiting for smart contract confirmation..." ,
        icon: "info",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'profileupload': 
    {
        title: "Uploading your data",
        text: "Validating information . Please wait a moment...",
        icon: "info",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'confirmemail': 
    {
        title: "Please confirm your email",
        text: "Congratulations, You're now part of Dijaux!. Please complete your registration by clicking confirmation link on your emial",
        icon: "warning",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'approved': 
    {
        title: "Confimation done!",
        text: "Please accept the 3rd transaction for listing your auction" ,
        icon: "info",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'toauction': 
    {
        title: "Your NFT is now up for auction!",
        text: "Congrats, You just listed your NFT. Make sure to upload your work to Dijaux Showcase for more promotion.",
        icon: "success",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'error': 
    {
        title: "Transaction Error",
        text: "Unknown error please contact Administrator",
        icon: "warning",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'mintlisted': 
    {
        title: "You minted successfully",
        text: "Congrats, You just listed your NFT. Make sure to upload your work to Dijaux Showcase for more promotion.",
        icon: "success",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'waitingconfirmation': 
    {
        title: "Waiting for confirmation",
        text: "Please accept the transaction in your wallet",
        icon: LoadingIcon,
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'nftnotexist': 
    {
        title: "NFT doesn't exists",
        text: "This NFT is already burned by the owner. You cannot access this anymore",
        icon: 'warning',
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'nfthasbeentransferred': 
    {
        title: "NFT has successfully been transferred",
        text: "your NFT has been successfully transferred",
        icon: "success",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'missingprice': 
    {
        title: "Missing price",
        text: "Please double check your inputed price or else it will not go through",
        icon: "warning",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'youplacebid': 
    {
        title: "You've placed a bid!",
        text: "You've successfully placed bid",
        icon: "success",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'waitingapproval': 
    {
        title: "Waiting for approval",
        text: "Please accept the transaction approval from smart contract",
        icon: LoadingIcon,
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
    'approvalaccepted': 
    {
        title: "Approval accepted",
        text: "waiting to add to auction. Please wait...",
        icon: "info",
        error: "Seems there's an issue on your transaction. Please contact administrator"
    },
}