const CoinGecko = require('coingecko-api')

export const loadData = async (props, connected, account) => {
    
    var self = props;
    //const CoinGeckoClient = new CoinGecko();

    /*let data = await CoinGeckoClient.exchanges.fetchTickers('bitfinex', {
         coin_ids: ['ethereum','matic-network']
     });*/

    let data = {
        "success": true,
        "message": "",
        "code": 200,
        "data": {
            "name": "Bitfinex",
            "tickers": [
                {
                    "base": "ETH",
                    "target": "USD",
                    "market": {
                        "name": "Bitfinex",
                        "identifier": "bitfinex",
                        "has_trading_incentive": false
                    },
                    "last": 1627.1,
                    "volume": 5851.62656547,
                    "converted_last": {
                        "btc": 0.06946924,
                        "eth": 0.99919522,
                        "usd": 1627.1
                    },
                    "converted_volume": {
                        "btc": 406.508,
                        "eth": 5847,
                        "usd": 9521182
                    },
                    "trust_score": "green",
                    "bid_ask_spread_percentage": 0.061425,
                    "timestamp": "2023-02-28T05:37:39+00:00",
                    "last_traded_at": "2023-02-28T05:37:39+00:00",
                    "last_fetch_at": "2023-02-28T05:37:39+00:00",
                    "is_anomaly": false,
                    "is_stale": false,
                    "trade_url": "https://www.bitfinex.com/t/ETHUSD",
                    "token_info_url": null,
                    "coin_id": "ethereum"
                },
                {
                    "base": "ETH",
                    "target": "USDT",
                    "market": {
                        "name": "Bitfinex",
                        "identifier": "bitfinex",
                        "has_trading_incentive": false
                    },
                    "last": 1625.9,
                    "volume": 2162.73464352,
                    "converted_last": {
                        "btc": 0.06945874,
                        "eth": 0.99904792,
                        "usd": 1626.69
                    },
                    "converted_volume": {
                        "btc": 150.221,
                        "eth": 2161,
                        "usd": 3518104
                    },
                    "trust_score": "green",
                    "bid_ask_spread_percentage": 0.061463,
                    "timestamp": "2023-02-28T05:38:41+00:00",
                    "last_traded_at": "2023-02-28T05:38:41+00:00",
                    "last_fetch_at": "2023-02-28T05:38:41+00:00",
                    "is_anomaly": false,
                    "is_stale": false,
                    "trade_url": "https://www.bitfinex.com/t/ETHUST",
                    "token_info_url": null,
                    "coin_id": "ethereum",
                    "target_coin_id": "tether"
                },
                {
                    "base": "ETH",
                    "target": "BTC",
                    "market": {
                        "name": "Bitfinex",
                        "identifier": "bitfinex",
                        "has_trading_incentive": false
                    },
                    "last": 0.069581,
                    "volume": 697.15543716,
                    "converted_last": {
                        "btc": 0.069581,
                        "eth": 1.000803,
                        "usd": 1629.72
                    },
                    "converted_volume": {
                        "btc": 48.508772,
                        "eth": 697.715,
                        "usd": 1136167
                    },
                    "trust_score": "green",
                    "bid_ask_spread_percentage": 0.043103,
                    "timestamp": "2023-02-28T05:37:39+00:00",
                    "last_traded_at": "2023-02-28T05:37:39+00:00",
                    "last_fetch_at": "2023-02-28T05:37:39+00:00",
                    "is_anomaly": false,
                    "is_stale": false,
                    "trade_url": "https://www.bitfinex.com/t/ETHBTC",
                    "token_info_url": null,
                    "coin_id": "ethereum",
                    "target_coin_id": "bitcoin"
                },
                {
                    "base": "ETH",
                    "target": "EUR",
                    "market": {
                        "name": "Bitfinex",
                        "identifier": "bitfinex",
                        "has_trading_incentive": false
                    },
                    "last": 1537.8,
                    "volume": 148.5030568,
                    "converted_last": {
                        "btc": 0.06949696,
                        "eth": 0.99959404,
                        "usd": 1627.75
                    },
                    "converted_volume": {
                        "btc": 10.320512,
                        "eth": 148.443,
                        "usd": 241726
                    },
                    "trust_score": "green",
                    "bid_ask_spread_percentage": 0.06502,
                    "timestamp": "2023-02-28T05:37:40+00:00",
                    "last_traded_at": "2023-02-28T05:37:40+00:00",
                    "last_fetch_at": "2023-02-28T05:37:40+00:00",
                    "is_anomaly": false,
                    "is_stale": false,
                    "trade_url": "https://www.bitfinex.com/t/ETHEUR",
                    "token_info_url": null,
                    "coin_id": "ethereum"
                },
                {
                    "base": "MATIC",
                    "target": "USD",
                    "market": {
                        "name": "Bitfinex",
                        "identifier": "bitfinex",
                        "has_trading_incentive": false
                    },
                    "last": 1.2269,
                    "volume": 385146.65316482,
                    "converted_last": {
                        "btc": 0.00005239,
                        "eth": 0.00075351,
                        "usd": 1.23
                    },
                    "converted_volume": {
                        "btc": 20.17701,
                        "eth": 290.213,
                        "usd": 472536
                    },
                    "trust_score": "green",
                    "bid_ask_spread_percentage": 0.162999,
                    "timestamp": "2023-02-28T05:36:36+00:00",
                    "last_traded_at": "2023-02-28T05:36:36+00:00",
                    "last_fetch_at": "2023-02-28T05:38:40+00:00",
                    "is_anomaly": false,
                    "is_stale": false,
                    "trade_url": "https://www.bitfinex.com/t/MATIC:USD",
                    "token_info_url": null,
                    "coin_id": "matic-network"
                },
                {
                    "base": "MATIC",
                    "target": "USDT",
                    "market": {
                        "name": "Bitfinex",
                        "identifier": "bitfinex",
                        "has_trading_incentive": false
                    },
                    "last": 1.2266,
                    "volume": 97522.92902017,
                    "converted_last": {
                        "btc": 0.0000524,
                        "eth": 0.00075369,
                        "usd": 1.23
                    },
                    "converted_volume": {
                        "btc": 5.110258,
                        "eth": 73.502,
                        "usd": 119692
                    },
                    "trust_score": "green",
                    "bid_ask_spread_percentage": 0.162999,
                    "timestamp": "2023-02-28T05:37:51+00:00",
                    "last_traded_at": "2023-02-28T05:37:51+00:00",
                    "last_fetch_at": "2023-02-28T05:37:51+00:00",
                    "is_anomaly": false,
                    "is_stale": false,
                    "trade_url": "https://www.bitfinex.com/t/MATIC:UST",
                    "token_info_url": null,
                    "coin_id": "matic-network",
                    "target_coin_id": "tether"
                },
                {
                    "base": "ETH",
                    "target": "GBP",
                    "market": {
                        "name": "Bitfinex",
                        "identifier": "bitfinex",
                        "has_trading_incentive": false
                    },
                    "last": 1351.6,
                    "volume": 63.38716715,
                    "converted_last": {
                        "btc": 0.06949001,
                        "eth": 0.99949405,
                        "usd": 1627.59
                    },
                    "converted_volume": {
                        "btc": 4.404775,
                        "eth": 63.355,
                        "usd": 103168
                    },
                    "trust_score": "yellow",
                    "bid_ask_spread_percentage": 0.147929,
                    "timestamp": "2023-02-28T05:37:40+00:00",
                    "last_traded_at": "2023-02-28T05:37:40+00:00",
                    "last_fetch_at": "2023-02-28T05:37:40+00:00",
                    "is_anomaly": false,
                    "is_stale": false,
                    "trade_url": "https://www.bitfinex.com/t/ETHGBP",
                    "token_info_url": null,
                    "coin_id": "ethereum"
                },
                {
                    "base": "ETH",
                    "target": "JPY",
                    "market": {
                        "name": "Bitfinex",
                        "identifier": "bitfinex",
                        "has_trading_incentive": false
                    },
                    "last": 221960,
                    "volume": 12.20597894,
                    "converted_last": {
                        "btc": 0.0695105,
                        "eth": 0.99978873,
                        "usd": 1628.07
                    },
                    "converted_volume": {
                        "btc": 0.8484437,
                        "eth": 12.2034,
                        "usd": 19872.15
                    },
                    "trust_score": "yellow",
                    "bid_ask_spread_percentage": 0.135196,
                    "timestamp": "2023-02-28T05:37:41+00:00",
                    "last_traded_at": "2023-02-28T05:37:41+00:00",
                    "last_fetch_at": "2023-02-28T05:37:41+00:00",
                    "is_anomaly": false,
                    "is_stale": false,
                    "trade_url": "https://www.bitfinex.com/t/ETHJPY",
                    "token_info_url": null,
                    "coin_id": "ethereum"
                },
                {
                    "base": "ETH",
                    "target": "EUT",
                    "market": {
                        "name": "Bitfinex",
                        "identifier": "bitfinex",
                        "has_trading_incentive": false
                    },
                    "last": 1536.9,
                    "volume": 17.56856726,
                    "converted_last": {
                        "btc": 0.06951191,
                        "eth": 0.99981261,
                        "usd": 1627.94
                    },
                    "converted_volume": {
                        "btc": 1.221225,
                        "eth": 17.565275,
                        "usd": 28601
                    },
                    "trust_score": "yellow",
                    "bid_ask_spread_percentage": 0.130039,
                    "timestamp": "2023-02-28T05:38:40+00:00",
                    "last_traded_at": "2023-02-28T05:38:40+00:00",
                    "last_fetch_at": "2023-02-28T05:38:40+00:00",
                    "is_anomaly": false,
                    "is_stale": false,
                    "trade_url": "https://www.bitfinex.com/t/ETHEUT",
                    "token_info_url": null,
                    "coin_id": "ethereum",
                    "target_coin_id": "tether-eurt"
                },
                {
                    "base": "MATIC",
                    "target": "BTC",
                    "market": {
                        "name": "Bitfinex",
                        "identifier": "bitfinex",
                        "has_trading_incentive": false
                    },
                    "last": 0.00005246,
                    "volume": 13636.73816363,
                    "converted_last": {
                        "btc": 0.00005246,
                        "eth": 0.00075455,
                        "usd": 1.23
                    },
                    "converted_volume": {
                        "btc": 0.71538328,
                        "eth": 10.289555,
                        "usd": 16755.62
                    },
                    "trust_score": "yellow",
                    "bid_ask_spread_percentage": 0.114286,
                    "timestamp": "2023-02-28T05:35:42+00:00",
                    "last_traded_at": "2023-02-28T05:35:42+00:00",
                    "last_fetch_at": "2023-02-28T05:37:44+00:00",
                    "is_anomaly": false,
                    "is_stale": false,
                    "trade_url": "https://www.bitfinex.com/t/MATIC:BTC",
                    "token_info_url": null,
                    "coin_id": "matic-network",
                    "target_coin_id": "bitcoin"
                },
                {
                    "base": "ETH",
                    "target": "XAUT",
                    "market": {
                        "name": "Bitfinex",
                        "identifier": "bitfinex",
                        "has_trading_incentive": false
                    },
                    "last": 0.89674,
                    "volume": 0.33189571,
                    "converted_last": {
                        "btc": 0.06953,
                        "eth": 1.000069,
                        "usd": 1628.52
                    },
                    "converted_volume": {
                        "btc": 0.02307671,
                        "eth": 0.33191868,
                        "usd": 540.5
                    },
                    "trust_score": "yellow",
                    "bid_ask_spread_percentage": 0.111359,
                    "timestamp": "2023-02-28T04:20:33+00:00",
                    "last_traded_at": "2023-02-28T04:20:33+00:00",
                    "last_fetch_at": "2023-02-28T05:37:44+00:00",
                    "is_anomaly": false,
                    "is_stale": false,
                    "trade_url": "https://www.bitfinex.com/t/ETHXAUT",
                    "token_info_url": null,
                    "coin_id": "ethereum",
                    "target_coin_id": "tether-gold"
                },
                {
                    "base": "ETH",
                    "target": "MXNT",
                    "market": {
                        "name": "Bitfinex",
                        "identifier": "bitfinex",
                        "has_trading_incentive": false
                    },
                    "last": 30185,
                    "volume": 0.11591948,
                    "converted_last": {
                        "btc": 0.06953,
                        "eth": 1.000069,
                        "usd": 1628.52
                    },
                    "converted_volume": {
                        "btc": 0.00805988,
                        "eth": 0.1159275,
                        "usd": 188.78
                    },
                    "trust_score": "yellow",
                    "bid_ask_spread_percentage": 1.592568,
                    "timestamp": "2023-02-28T05:35:47+00:00",
                    "last_traded_at": "2023-02-28T05:35:47+00:00",
                    "last_fetch_at": "2023-02-28T05:37:50+00:00",
                    "is_anomaly": false,
                    "is_stale": false,
                    "trade_url": "https://www.bitfinex.com/t/ETHMXNT",
                    "token_info_url": null,
                    "coin_id": "ethereum",
                    "target_coin_id": "mexican-peso-tether"
                }
            ]
        }
    };

    var _coinList = {};
    var _datacc = data["data"].tickers.filter(t => t.target == 'USD');

    ['ETH','MATIC'].forEach((i) => {
        var _temp = _datacc.filter(t => t.base == i);
        var _res = _temp.length == 0 ? [] : _temp[0];
        _coinList[i] = _res.last;
    })
    
    
    if(connected) {
        props.setState({
            ETHValue: _coinList.ETH,
            MATICValue: _coinList.MATIC,
            account: account
        });
    }else {
        props.setState({
            ETHValue: _coinList.ETH,
            MATICValue: _coinList.MATIC,
            account: "not available"
        });
    }
    
    if(connected) {
        await Promise.all([
            fetch(self.state.APIUrl+"/nfts/"),
            fetch(self.state.APIUrl+"/collections/"),
            fetch(self.state.APIUrl+"/likes/"),
            fetch(self.state.APIUrl+"/follows/"),
            fetch(self.state.APIUrl+"/users/"),
            fetch(self.state.APIUrl+"/activities/"),
            fetch(self.state.APIUrl+"/user/" + account.toLowerCase()),
        ])
        .then(async([nftsData, collectionsData, likesData, followsData, usersData, activitiesData, myaccountData]) => {
    
            const nfts = await nftsData.json();
            const collections = await collectionsData.json();
            const likes = await likesData.json();
            var set = new Set()
    
            var LikesUnique = likes.reduce((a, c)=>{
                var str = JSON.stringify(c)
                if(!set.has(str)){
                    set.add(str)
                    return a.concat(c);
                }   
                return a
            },[])
    
            const follows = await followsData.json();
    
            var FollowsUnique = follows.reduce((a, c)=>{
                var str = JSON.stringify(c)
                if(!set.has(str)){
                    set.add(str)
                    return a.concat(c);
                }   
                return a
            },[])
    
            const users = await usersData.json();
            const activities = await activitiesData.json();

            var ActivitiesUnique = activities.reduce((a, c)=>{
                var str = JSON.stringify(c)
                if(!set.has(str)){
                    set.add(str)
                    return a.concat(c);
                }   
                return a
            },[])

            const arrayUniqueByNFT = [...new Map(nfts.map(item =>
                [item['tokenid'], item])).values()];

            props.setState({ 
                arts: arrayUniqueByNFT.filter(key => !key.removed),
                collections: [...new Map(collections.map(item =>
                [item['tokenid'], item])).values()].filter(key => !key.removed),
                likes: LikesUnique, 
                follows: FollowsUnique, 
                creators: users.filter(key => key.confirmed !== false && key.address != ""), 
                activities: ActivitiesUnique.filter(key => key.active !== false), 
                noProvider: connected ? null : true,
                NFTcount: Math.floor(Math.random() * arrayUniqueByNFT.filter(key => !key.removed).length) + 1,
                finishedLoading: true
            })
    
            if(connected) {
                const myaccount = await myaccountData.json();
                props.setState({ 
                    myaccount: myaccount,
                })
            }
    
        }).catch(error => {
            console.log(error);
        });
    }else {
        
        await Promise.all([
            fetch(self.state.APIUrl+"/nfts/"),
            fetch(self.state.APIUrl+"/collections/"),
            fetch(self.state.APIUrl+"/likes/"),
            fetch(self.state.APIUrl+"/follows/"),
            fetch(self.state.APIUrl+"/users/"),
            fetch(self.state.APIUrl+"/activities/")
        ])
        .then(async([nftsData, collectionsData, likesData, followsData, usersData, activitiesData]) => {
    
            const nfts = await nftsData.json();
            const collections = await collectionsData.json();
            const likes = await likesData.json();
            var set = new Set()
            console.log(likes)
            var LikesUnique = likes.reduce((a, c)=>{
                var str = JSON.stringify(c)
                if(!set.has(str)){
                    set.add(str)
                    return a.concat(c);
                }   
                return a
            },[])
    
            const follows = await followsData.json();
    
            var FollowsUnique = follows.reduce((a, c)=>{
                var str = JSON.stringify(c)
                if(!set.has(str)){
                    set.add(str)
                    return a.concat(c);
                }   
                return a
            },[])
    
            const users = await usersData.json();
            const activities = await activitiesData.json();

            var ActivitiesUnique = activities.reduce((a, c)=>{
                var str = JSON.stringify(c)
                if(!set.has(str)){
                    set.add(str)
                    return a.concat(c);
                }   
                return a
            },[])

            const arrayUniqueByNFT = [...new Map(nfts.map(item =>
                [item['tokenid'], item])).values()];

            props.setState({ 
                arts: arrayUniqueByNFT.filter(key => !key.removed),
                collections: [...new Map(collections.map(item =>
                    [item['tokenid'], item])).values()].filter(key => !key.removed),
                likes: LikesUnique, 
                follows: FollowsUnique, 
                creators: users.filter(key => key.confirmed !== false && key.address != ""), 
                activities: ActivitiesUnique.filter(key => key.active !== false), 
                noProvider: connected ? null : true,
                NFTcount: Math.floor(Math.random() * arrayUniqueByNFT.filter(key => !key.removed).length) + 1,
                finishedLoading: true
            })
    
        }).catch(error => {
            console.log(error);
        });
    }
    
}