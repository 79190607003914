import { useState } from 'react'
import { Range, getTrackBackground } from "react-range";



export default function Filter(props) {

    const STEP = 0.001;
    const MIN = 0;
    const [max, setmax] = useState(10000);
    const [values, setValues] = useState([0, 10000]);

    return (
        <div className="filter-wrap">
            <button className="filter-wrap__btn" onClick={props.toggleFilter}>Open filter</button>

            <div className={"collapse filter-wrap__content p-3 " + (props.filterToggle === true ? 'show' : '' )} id="collapseFilter">
                {/* filter */}
                <div className="filter filter--sticky">
                    <h4 className="filter__title">Price Range</h4>
                    <div className="price-slider">
                    <output style={{ marginBottom: '30px' }} id="output">
                    <strong>{values[0]} - {values[1]} {props.currency}</strong>
                    </output>
                    <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap'
                    }}
                    >
                    <Range
                        draggableTrack
                        values={values}
                        step={STEP}
                        min={MIN}
                        max={max}
                        onChange={(val) => {
                            props.setValues(val);
                            setValues(val);
                        }}
                        renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                            ...props.style,
                            height: '36px',
                            display: 'flex',
                            width: '100%'
                            }}
                        >
                            <div
                            ref={props.ref}
                            style={{
                                height: '5px',
                                width: '100%',
                                borderRadius: '4px',
                                background: getTrackBackground({
                                    values,
                                    colors: ['#ccc', '#222222', '#ccc'],
                                    min: MIN,
                                    max: max
                                }),
                                alignSelf: 'center'
                            }}
                            >
                            {children}
                            </div>
                        </div>
                        )}
                        renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            style={{
                            ...props.style,
                            height: '42px',
                            width: '42px',
                            borderRadius: '4px',
                            backgroundColor: '#FFF',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '0px 2px 6px #AAA'
                            }}
                        >
                            <div
                            style={{
                                height: '16px',
                                width: '5px',
                                backgroundColor: isDragged ? '#222222' : '#CCC'
                            }}
                            />
                        </div>
                        )}
                    />
                    </div>
                    
                    </div>
                    <br/>
                    <label><small>Set max {props.currency} price</small></label>
                    <input 
                        type="text" 
                        className="sign__input" 
                        placeholder={max}
                        onChange={(e) => {
                            setmax(e.target.value);
                        }}
                    />
                    <hr />
                    <h4 className="filter__title">Sort by</h4>
                    <div className="filter__group">
                    <div className="sign__group">
                        <select id="sort-by" name="sort-by" className="sign__select" onChange={(e) => {
                        props.sortArts(e.target.value);
                        }}>
                            <option value="latest">Latest</option>
                            <option value="oldest">Oldest</option>
                            <option value="highest">Highest price</option>
                            <option value="lowest">Lowest price</option>
                        </select>
                    </div>
                    </div>
                    <h4 className="filter__title">Filters <button type="button" onClick={props.clearFilter}>Clear all</button></h4>
                    <div className="filter__group">
                        <ul className="filter__checkboxes">
                            <li>
                                <input id="type5" type="checkbox" name="art-filter" value="3darts" onClick={props.applyFilter} />
                                <label htmlFor="type5">3D Arts</label>
                            </li>
                            <li>
                                <input id="type6" type="checkbox" name="art-filter" value="cards" onClick={props.applyFilter} />
                                <label htmlFor="type6">Cards</label>
                            </li>
                            <li>
                                <input id="type7" type="checkbox" name="art-filter" value="collectibles" onClick={props.applyFilter} />
                                <label htmlFor="type7">Collectibles</label>
                            </li>
                            <li>
                                <input id="type8" type="checkbox" name="art-filter" value="digitalart" onClick={props.applyFilter} />
                                <label htmlFor="type8">Digital Art</label>
                            </li>
                            <li>
                                <input id="type9" type="checkbox" name="art-filter" value="nsfw" onClick={props.applyFilter} />
                                <label htmlFor="type9">NSFW</label>
                            </li>
                            <li>
                                <input id="type10" type="checkbox" name="art-filter" value="originalart" onClick={props.applyFilter} />
                                <label htmlFor="type10">Original Art</label>
                            </li>
                            <li>
                                <input id="type11" type="checkbox" name="art-filter" value="sports" onClick={props.applyFilter} />
                                <label htmlFor="type11">Sports</label>
                            </li>
                            <li>
                                <input id="type12" type="checkbox" name="art-filter" value="virtualworlds" onClick={props.applyFilter} />
                                <label htmlFor="type12">Virtual Worlds</label>
                            </li>
                            <li>
                                <input id="type13" type="checkbox" name="art-filter" value="lands" onClick={props.applyFilter}/>
                                <label htmlFor="type13">Lands</label>
                            </li>
                            <li>
                                <input id="type14" type="checkbox" name="art-filter" value="audio" onClick={props.applyFilter}/>
                                <label htmlFor="type14">Audio</label>
                            </li>
                            <li>
                                <input id="type15" type="checkbox" name="art-filter" value="video" onClick={props.applyFilter}/>
                                <label htmlFor="type15">Video</label>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* end filter */}
            </div>
        </div>
    );
}