import React from 'react'
import Discplaying from '../../../main/assets/img/discplaying.gif';

export default function ActivityProfile(props) {

    console.log(props.allActivities.filter(activities => activities.from.toLowerCase() == props.address.toLowerCase()))
    return (
        <div className="row">
            {/* sidebar */}
            <div className="col-12 col-xl-3 order-xl-2">
                <div className="filter-wrap">
                <button className="filter-wrap__btn" onClick={props.toggleFilter}>Open filter</button>

                <div className={"collapse filter-wrap__content p-3 " + (props.filterToggle === true ? 'show' : '' )} id="collapseFilter">
                    {/* filter */}
                    <div className="filter filter--sticky">
                    <h4 className="filter__title">Filters <button type="button">Clear all</button></h4>

                    <div className="filter__group">
                        <ul className="filter__checkboxes">
                            <li>
                                <input id="type5" type="checkbox" name="activities" value="listed" onClick={props.applyFilter} />
                                <label htmlFor="type5">New Listings</label>
                            </li>
                            <li>
                                <input id="type6" type="checkbox" name="activities" value="bid" onClick={props.applyFilter} />
                                <label htmlFor="type6">Bids</label>
                            </li>
                            <li>
                                <input id="type7" type="checkbox" name="activities" value="bought" onClick={props.applyFilter} />
                                <label htmlFor="type7">Collected</label>
                            </li>
                            <li>
                                <input id="type8" type="checkbox" name="activities" value="minted" onClick={props.applyFilter}/>
                                <label htmlFor="type8">Minted</label>
                            </li>
                            <li>
                                <input id="type10" type="checkbox" name="activities" value="owned" onClick={props.applyFilter} />
                                <label htmlFor="type10">Won Auctions</label>
                            </li>
                        </ul>
                    </div>
                    </div>
                    {/* end filter */}
                </div>
                </div>
            </div>
            {/* end sidebar */}

            {/* content */}
            <div className="col-12 col-xl-9 order-xl-1">
                <div className="row row--grid">
                {props.allActivities.filter(activities => activities.from.toLowerCase() == props.address.toLowerCase()).map((activity, index) => (
                    props.arts.filter(cards => cards.tokenid == activity.tokenid && cards.currency == activity.currency).map((card, index) => (
                        props.creators.filter(users => users.address.toLowerCase() == activity.from.toLowerCase()).map((user, index) => (
                        <div key={index} data-cat={activity.type} data-time={parseInt(activity.time)} className={"col-12 col-lg-6 sorted-items " + (!props.filter.includes(activity.type) && props.filter != "" ? 'hide' : '')}>
                        <div className="activity">
                            <a href={"/nft/"+ card.tokenid + "/" + card.currency} className="activity__cover">
                            {['mp3', 'wav'].includes(card.image.split('.').pop()) ? (
                                <React.Fragment>
                                <div className="floating-text-loop">Audio</div>
                                <img src={Discplaying} alt="" />
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                {['mp4', 'ogg', 'webm', 'mov'].includes(card.image.split('.').pop()) ? (
                                    <React.Fragment>
                                        <div className="floating-text-loop">Video</div>
                                        <video autoPlay loop muted playsInline>
                                        <source src={ card.image } type={"video/" + card.image.split('.').pop()} />
                                        Your browser does not support the video tag.
                                        </video>
                                    </React.Fragment>
                                ) : (
                                    <img src={card.image } alt="" />
                                )}
                                </React.Fragment>
                            )}
                            
                            </a>
                            <div className="activity__content">
                            <h3 className="activity__title"><a href={"/nft/"+ card.tokenid + "/" + card.currency}>{card.name}</a></h3>
                            <p className="activity__text">{props.activity_type(activity.type)} for <b>{props.convertETH(activity.amount,'wei','ether')} {card.currency}</b> <small className="text-secondary fw-bold">(${props.convertUSD(props.convertETH(activity.amount,'wei','ether'),card.currency)})</small><br/> by <a href={"/profile"+user.address}><img src={user.picture} className="img-fluid rounded-3 mr-1" width="35" height="35" /> {user.name}</a></p>
                            <span className="activity__time">{props.time2TimeAgo(Math.round(activity.time / 1000))}</span>
                            </div>
                        </div>
                        </div>
                        ))
                    ))
                ))}
                </div>
            </div>
            {/* end content */}
            </div>
    );
}